import TenantInfoSectionTitle from "./TenantInfoSectionTitle";
import LabelWithContent from "components/common/LabelWithContent";
import TenantInfoLabelTextContent from "./TenantInfoLabelTextContent";
import TenantFieldSection from "./TenantFieldSection";
import TenantNameFieldViewOrEdit from "./TenantNameFieldViewOrEdit";
import CompanyRegistrationFieldViewOrEdit from "./CompanyRegistrationFieldViewOrEdit";
import { useAppDispatch } from "store/hooks";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { makeRequestAskingOwnerStatusAsync } from "store/actions/TenantAdminActions";
import OwnTenantResponse from "interfaces/response/tenant/OwnTenantResponse";

interface Props {
  tenantDetail: OwnTenantResponse;
  isEdit: boolean;
  onChange: (updatedInfo: OwnTenantResponse) => void;
}

export default function TenantInfoViewEdit(props: Props) {
  const dispatch = useAppDispatch();

  const handleRequestOwnerStatusClick = () => {
    displayLoadingPanel();
    dispatch(makeRequestAskingOwnerStatusAsync()).finally(hideLoadingPanel);
  };

  return (
    <div className="flex space-x-2">
      <TenantInfoSectionTitle title="Tenant Info" />
      <div className="space-y-5">
        <div className="flex justify-between">
          <TenantFieldSection>
            <LabelWithContent
              label="Tenant Name"
              addAsterisksymbol={props.isEdit}
              content={
                <TenantNameFieldViewOrEdit
                  isEdit={props.isEdit}
                  onChange={props.onChange}
                  tenantDetail={props.tenantDetail}
                />
              }
            />
          </TenantFieldSection>
          <TenantFieldSection>
            <LabelWithContent
              label="Company registration number"
              addAsterisksymbol={props.isEdit}
              content={
                <CompanyRegistrationFieldViewOrEdit
                  isEdit={props.isEdit}
                  tenantDetail={props.tenantDetail}
                  onChange={props.onChange}
                />
              }
            />
          </TenantFieldSection>
          <TenantFieldSection>
            <LabelWithContent
              label="Can be owner"
              content={
                <TenantInfoLabelTextContent
                  text={props.tenantDetail.canBeOwner ? "Yes" : "No"}
                />
              }
            />
          </TenantFieldSection>
        </div>
        <div className="flex">
          <TenantFieldSection>
            <LabelWithContent
              label="Tenant ID"
              content={
                <TenantInfoLabelTextContent
                  text={props.tenantDetail.id?.toString()}
                />
              }
            />
          </TenantFieldSection>
          <TenantFieldSection>
            <LabelWithContent
              label="Tenant registrator"
              content={
                <TenantInfoLabelTextContent
                  text={props.tenantDetail.registrator}
                />
              }
            />
          </TenantFieldSection>
          {/* <TenantFieldSection>
            {props.isEdit || props.tenantDetail.canBeOwner ? (
              <></>
            ) : (
              <PromineoButton
                text="Request owner status"
                onClick={handleRequestOwnerStatusClick}
              />
            )}
          </TenantFieldSection> */}
        </div>
      </div>
    </div>
  );
}
