import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import LabelWithContent from "components/common/LabelWithContent";
import { NewExchangeAgreementActionRequired } from "shared/enums/feature/NewExchangeAgreementActionRequired";
import {
  getDisplayExpressionForScheduleSelectorDropdown,
  getScheduleLabel,
} from "shared/utilities/IEAUtility";
import ScheduleSelectionDropdownItemRenderComponent from "./ScheduleSelectionDropdownItemRenderComponent";

interface Props {
  isPartner: boolean;
  requiredAction?: NewExchangeAgreementActionRequired;
  selectedPartnerConnectorId: number | undefined;
  scheduleDataSource: any[];
  labelClassName: string;
  exchangeAgreement: {
    partnerSchedule: any;
  };
  handlePartnerScheduleChange?: (selectedItem: any) => void;
}

export default function ScheduleIdentifierField({
  isPartner,
  requiredAction,
  selectedPartnerConnectorId,
  scheduleDataSource,
  labelClassName,
  exchangeAgreement,
  handlePartnerScheduleChange,
}: Props) {
  return (
    <>
      {isPartner &&
      requiredAction === NewExchangeAgreementActionRequired.Complete ? (
        <LabelWithContent
          label="Schedule identifier"
          addAsterisksymbol={true}
          content={
            <PromineoSelectBox
              placeholder="Select"
              disabled={
                selectedPartnerConnectorId
                  ? selectedPartnerConnectorId === 0
                  : true
              }
              items={scheduleDataSource}
              itemRender={ScheduleSelectionDropdownItemRenderComponent}
              valueExpr="id"
              displayExpr={getDisplayExpressionForScheduleSelectorDropdown}
              onSelectionChanged={(selection: any) =>
                handlePartnerScheduleChange?.(selection.selectedItem)
              }
            />
          }
        />
      ) : (
        <LabelWithContent
          label="Schedule identifier"
          content={
            <div className={labelClassName}>
              {getScheduleLabel(exchangeAgreement.partnerSchedule)}
            </div>
          }
        />
      )}
    </>
  );
}
