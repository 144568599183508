import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import ExchangeAgreementHeaderData from "interfaces/response/ExchangeAgreementHeaderData";
import LabelResponse from "interfaces/response/LabelResponse";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  loadExchangeAgreementDetailsAsync,
  updateExistingExchangeAgreementForPartnerAsync,
  signExchangeAgreementOnBehalfOfPartnerAsync,
  signExchangeAgreementOnBehalfOfOwnerAsync,
  removeInactiveExchangeAgreementAsync,
  updateExistingExchangeAgreementAsync,
} from "store/actions/ExchangeAgreementActions";
import { AppDispatch, RootState } from "store/store";
import IEAHeader from "../IEAHeader";
import {
  ActivePageHeaderLabel,
  PendingStartDatePageHeaderLabel,
  UnderConstructionPageHeaderLabel,
} from "components/common/page-header/PageHeaderLabel";
import PromineoTabPanel from "components/common/controls/PromineoTabPanel";
import { Item } from "devextreme-react/tab-panel";
import InactiveIEAInfo from "./InactiveIEAInfo";
import ExchangeAgreementUpdateRequest from "interfaces/request/ExchangeAgreementUpdateRequest";
import { getHeaderInformationText } from "shared/utilities/ExchangeAgreementUtility";
import InactiveIEAFooter from "./InactiveIEAFooter";
import { ACTIVE_IEA, NEW_IEAS } from "shared/constants/RoutePathConstants";
import { ExchangeAgreementSignResponse } from "interfaces/response/ExchangeAgreementSignResponse";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";
import { toastError, toastSuccess } from "shared/utilities/ToastUtility";
import IEAScrollView from "../IEAScrollView";
import * as DivId from "shared/constants/DivIdConstants";
import ConnectorBaseResponse from "interfaces/response/connector/ConnectorBaseResponse";
import { DirectionEnum } from "shared/enums/feature/DirectionEnum";
import { isValidJsonString } from "shared/utilities/JsonUtility";
import { NewExchangeAgreementActionRequired } from "shared/enums/feature/NewExchangeAgreementActionRequired";
import { HostSystem } from "shared/enums/feature/HostSystem";
import { loadConfigForExchangeAgreementAsync } from "store/actions/ConfigActions";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { FrequencyType } from "shared/enums/feature/FrequencyType";
import { activityFilterFieldName } from "shared/constants/IEAFieldNameConstants";
import { resetSelectedExchangeAgreement } from "store/slices/ExchangeAgreementSlice";
import PromineoCancelEditingConfirmationDialog from "components/common/controls/PromineoCancelEditingConfirmationDialog";
import HostParametersOverride from "../common/HostParametersOverride";
import { SupportedHostSystemConfigurationType } from "shared/types/HostSystemConfigurationTypes";
import {
  getDeserializedHostSystemConfiguration,
  getHostSystemConfigurationValue,
} from "shared/utilities/HostSystemConfigurationUtility";
import ConnectorScheduleSimplifiedResponse from "interfaces/response/ConnectorScheduleSimplifiedResponse";
import SafranStructureImportMappingConfig from "interfaces/host-system-config/safran/SafranStructureImportMappingConfig";
import { checkIfHasDuplicates } from "shared/utilities/CommonUtility";
import SafranImportConfig from "interfaces/host-system-config/safran/SafranImportConfig";
import InformationBar from "components/common/InformationBar";
import SapExportConfig from "interfaces/host-system-config/sap/SapExportConfig";
import { SapImportConfig } from "interfaces/host-system-config/sap/SapImportConfig";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";

const divIdsToExclude: string[] = [
  DivId.NavBarId,
  DivId.InactiveIEAHeaderId,
  DivId.InactiveIEAFooterId,
];

interface Props {
  isActiveOrArchiveIeaEdit?: boolean;
  onSaved?: (iea: ExchangeAgreementDetailedResponse) => void;
}

export default function InactiveIEAEditor(props: Props) {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [isModified, setIsModified] = useState<boolean>(false);
  const [isPartnerRepresentativeModified, setIsPartnerRepresentativeModified] =
    useState<boolean>(false);
  const [isOwnerRepresentativeModified, setIsOwnerRepresentativeModified] =
    useState<boolean>(false);
  const [
    isUnsavedChangeConfirmationVisible,
    setIsUnsavedChangeConfirmationVisible,
  ] = useState(false);
  const [selectedInactiveIEAId, setSelectedInactiveIEAId] = useState<number>(0);
  const [headerData, setHeaderData] =
    useState<ExchangeAgreementHeaderData | null>(null);
  const [isCurrentUserOwner, setIsCurrentUserOwner] = useState<boolean>(false);
  const [isCurrentUserPartner, setIsCurrentUserPartner] =
    useState<boolean>(false);
  const [senderHostSystem, setSenderHostSystem] = useState<HostSystem>();

  const [partnerRepresentativeIds, setPartnerRepresentativeIds] = useState<
    number[]
  >([]);
  const [ownerRepresentativeIds, setOwnerRepresentativeIds] = useState<
    number[]
  >([]);
  const [ownerHostSystemParameters, setOwnerHostSystmeParameters] =
    useState<SupportedHostSystemConfigurationType>();
  const [partnerHostSystemParameters, setPartnerHostSystemParameters] =
    useState<SupportedHostSystemConfigurationType>();
  const [partnerConnector, setPartnerConnector] =
    useState<ConnectorBaseResponse>();
  const [partnerSchedule, setPartnerSchedule] =
    useState<ConnectorScheduleSimplifiedResponse>();

  const [isActiveIeaUpdateRequestValid, setIsActiveIeaUpdateRequestValid] =
    useState(false);

  const [isIeaEditable, setIsIeaEditable] = useState<boolean | undefined>(
    props.isActiveOrArchiveIeaEdit
  );

  const [ieaLabels, setIeaLabels] = useState<LabelResponse[]>([]);
  const [ieaTitle, setIeaTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [cronExpression, setCronExpression] = useState("");
  const [comments, setComments] = useState("");
  const [lagInMinutes, setLagInMinutes] = useState<number>();
  const [predecessorAgreementId, setPredecessorAgreementId] =
    useState<number>();
  const [frequencyType, setFrequencyType] = useState<FrequencyType>();

  const [isSignForPartnerDialogVisible, setIsSignForPartnerDialogVisible] =
    useState(false);
  const [isSignForOwnerDialogVisible, setIsSignForOwnerDialogVisible] =
    useState(false);
  const [
    isDeleteConfirmationDialogVisible,
    setIsDeleteConfirmationDialogVisible,
  ] = useState(false);

  const [disablePartnerSubmitButton, setDisablePartnerSubmitButton] =
    useState(true);

  const [isCurrentUserSender, setIsCurrentUserSender] =
    useState<boolean>(false);

  const [isCurrentUserReceiver, setIsCurrentUserReceiver] =
    useState<boolean>(false);

  const hostParameterGridRef = useRef<any>();

  const loggedInUser = useSelector(
    (state: RootState) => state.userData.mySelfResponse
  );

  const selectedExchangeAgreement = useSelector(
    (state: RootState) => state.exchangeAgreementData.selectedExchangeAgreement
  );

  const partnerConfigSetting = useSelector(
    (state: RootState) => state.configData.configForExchangeAgreement
  );

  useEffect(() => {
    if (
      selectedExchangeAgreement &&
      !selectedExchangeAgreement.partnerConfigSettings &&
      !selectedExchangeAgreement.partnerConfigId
    ) {
      // If there is no partnerConfigSettings and partnerConfigId in the selected exchange agreement, then we will make an api call
      // to get the partner config settings. This will only happen when the owner has created the Exchange Agreement but
      // the partner did not fill up the information yet. Once the partner fills up the information and clicks the "Submit" button
      // then both ownerConfigSettings and partnerConfigSettings will be present in the exchange agreement.
      if (
        partnerConnector?.id &&
        partnerSchedule &&
        selectedExchangeAgreement?.template?.id
      ) {
        displayLoadingPanel();
        dispatch(
          loadConfigForExchangeAgreementAsync({
            templateId: selectedExchangeAgreement.template.id,
            connectorId: partnerConnector.id,
            scheduleId: partnerSchedule.id,
            direction:
              selectedExchangeAgreement.direction ===
              DirectionEnum.OwnerToPartner
                ? ConfigDirection.Receiving
                : ConfigDirection.Sending,
          })
        ).finally(hideLoadingPanel);
      }
    }
  }, [selectedExchangeAgreement, dispatch, partnerConnector, partnerSchedule]);

  useEffect(() => {
    let isValid = isIeaEditable ?? false;
    if (
      !ieaTitle ||
      !startDate ||
      !expireDate ||
      (frequencyType === FrequencyType.CronExpression && !cronExpression) ||
      (frequencyType === FrequencyType.Predecessor && !predecessorAgreementId)
    ) {
      isValid = false;
    }
    setIsActiveIeaUpdateRequestValid(isValid);
  }, [
    ieaTitle,
    startDate,
    expireDate,
    cronExpression,
    predecessorAgreementId,
    frequencyType,
    isIeaEditable,
  ]);

  const handlePartnerRepresentativeChange = (representativeIds: number[]) => {
    setPartnerRepresentativeIds(representativeIds);
    if (
      selectedExchangeAgreement?.actionRequiredIfInactive ===
        NewExchangeAgreementActionRequired.Complete ||
      isIeaEditable
    ) {
      setIsModified(true);
    } else {
      setIsPartnerRepresentativeModified(true);
    }
  };

  const handleOwnerRepresentativeChange = (representativeIds: number[]) => {
    setOwnerRepresentativeIds(representativeIds);
    setIsOwnerRepresentativeModified(true);
    if (isIeaEditable) {
      setIsModified(true);
    }
  };

  const handlePartnerConnectorChange = (
    connector: ConnectorBaseResponse | undefined
  ) => {
    setPartnerSchedule(undefined);
    setPartnerConnector(connector);
    setIsModified(true);
  };

  const handlePartnerScheduleIdChange = (
    value: ConnectorScheduleSimplifiedResponse
  ) => {
    setPartnerSchedule(value);
    setIsModified(true);
  };

  useEffect(() => {
    let isInputValid = true;

    if (!partnerConnector || !partnerConnector.id || !partnerSchedule) {
      isInputValid = false;
    }

    setDisablePartnerSubmitButton(!isInputValid);
  }, [partnerHostSystemParameters, partnerConnector, partnerSchedule]);

  useEffect(() => {
    if (selectedExchangeAgreement) {
      let ownerConfigFromSelectedIEA = isValidJsonString(
        selectedExchangeAgreement.ownerConfigSettings
      )
        ? selectedExchangeAgreement.ownerConfigSettings
        : "";

      if (
        selectedExchangeAgreement &&
        selectedExchangeAgreement.ownerConnector
      ) {
        const ownerHostSystemConfiguration = getHostSystemConfigurationValue(
          selectedExchangeAgreement.ownerConnector.hostSystem,
          selectedExchangeAgreement.ownerRole === ExchangeRoleEnum.Sender
            ? ConfigDirection.Sending
            : ConfigDirection.Receiving,
          ownerConfigFromSelectedIEA
        );
        setOwnerHostSystmeParameters(ownerHostSystemConfiguration ?? undefined);
      }

      let partnerConfigFromSelectedIEA = isValidJsonString(
        selectedExchangeAgreement.partnerConfigSettings
      )
        ? selectedExchangeAgreement.partnerConfigSettings
        : "";

      if (
        selectedExchangeAgreement &&
        selectedExchangeAgreement.partnerConnector
      ) {
        const partnerHostSystemConfiguration =
          getHostSystemConfigurationValue(
            selectedExchangeAgreement.partnerConnector.hostSystem,
            selectedExchangeAgreement.partnerRole === ExchangeRoleEnum.Sender
              ? ConfigDirection.Sending
              : ConfigDirection.Receiving,
            partnerConfigFromSelectedIEA
          ) ?? undefined;
        setPartnerHostSystemParameters(partnerHostSystemConfiguration);
      }

      setPartnerSchedule(selectedExchangeAgreement.partnerSchedule);
      if (selectedExchangeAgreement.isPendingStart) {
        setIsIeaEditable(true);
      }
    }
  }, [selectedExchangeAgreement]);

  useEffect(() => {
    var hostSystem: HostSystem | undefined = undefined;
    if (selectedExchangeAgreement) {
      if (
        selectedExchangeAgreement.direction === DirectionEnum.OwnerToPartner
      ) {
        hostSystem = selectedExchangeAgreement.ownerConnector.hostSystem;
      } else {
        hostSystem = partnerConnector?.hostSystem;
      }
      setSenderHostSystem(hostSystem);
    }
  }, [partnerConnector, selectedExchangeAgreement]);

  const setPartnerConfiguration = useCallback(() => {
    if (selectedExchangeAgreement && partnerConnector) {
      if (!selectedExchangeAgreement.partnerConfigSettings) {
        let partnerConfigHostParameters:
          | SupportedHostSystemConfigurationType
          | undefined;

        if (partnerConfigSetting) {
          partnerConfigHostParameters = isValidJsonString(
            partnerConfigSetting.hostSystemParameters
          )
            ? getHostSystemConfigurationValue(
                partnerConnector.hostSystem,
                selectedExchangeAgreement.ownerRole === ExchangeRoleEnum.Sender
                  ? ConfigDirection.Receiving
                  : ConfigDirection.Sending,
                partnerConfigSetting.hostSystemParameters
              ) ?? undefined
            : undefined;

          setPartnerHostSystemParameters(partnerConfigHostParameters);
        }
      } else {
        let partnerConfigFromIEA = isValidJsonString(
          selectedExchangeAgreement.partnerConfigSettings
        )
          ? getHostSystemConfigurationValue(
              partnerConnector.hostSystem,
              selectedExchangeAgreement.ownerRole === ExchangeRoleEnum.Sender
                ? ConfigDirection.Receiving
                : ConfigDirection.Sending,
              selectedExchangeAgreement.partnerConfigSettings
            ) ?? undefined
          : undefined;
        setPartnerHostSystemParameters(partnerConfigFromIEA);
      }
    }
  }, [selectedExchangeAgreement, partnerConnector, partnerConfigSetting]);

  useEffect(() => {
    setPartnerConfiguration();
  }, [setPartnerConfiguration]);

  useEffect(() => {
    if (params.id && !isNaN(Number(params.id))) {
      setSelectedInactiveIEAId(Number(params.id));
    }
  }, []);

  useEffect(() => {
    if (selectedInactiveIEAId !== 0) {
      displayLoadingPanel();
      dispatch(
        loadExchangeAgreementDetailsAsync(selectedInactiveIEAId)
      ).finally(hideLoadingPanel);
    }

    return () => {
      // Need to reset while unmounting because the same store state is used by InactiveIEA editors.
      dispatch(resetSelectedExchangeAgreement());
    };
  }, [dispatch, selectedInactiveIEAId]);

  useEffect(() => {
    if (loggedInUser && selectedExchangeAgreement) {
      let hasOwnerRole = selectedExchangeAgreement.ownerRepresentatives.some(
        (ownerRep) => ownerRep.id === loggedInUser.id
      );

      let hasPartnerRole =
        selectedExchangeAgreement.partnerRepresentatives.some(
          (partnerRep) => partnerRep.id === loggedInUser.id
        );

      var isSender =
        (hasOwnerRole &&
          selectedExchangeAgreement.direction ===
            DirectionEnum.OwnerToPartner) ||
        (hasPartnerRole &&
          selectedExchangeAgreement.direction === DirectionEnum.PartnerToOwner);
      setIsCurrentUserSender(isSender);
      setIsCurrentUserOwner(hasOwnerRole);
      setIsCurrentUserPartner(hasPartnerRole);

      var isReceiver =
        (hasOwnerRole &&
          selectedExchangeAgreement.direction ===
            DirectionEnum.PartnerToOwner) ||
        (hasPartnerRole &&
          selectedExchangeAgreement.direction === DirectionEnum.OwnerToPartner);
      setIsCurrentUserReceiver(isReceiver);

      var partnerConnector = selectedExchangeAgreement.partnerConnector
        ? selectedExchangeAgreement.partnerConnector
        : undefined;
      var partnerSchedule = selectedExchangeAgreement.partnerSchedule
        ? selectedExchangeAgreement.partnerSchedule
        : undefined;
      setPartnerSchedule(partnerSchedule);
      setPartnerConnector(partnerConnector);

      // Fields required for active iea edit.
      setIeaTitle(selectedExchangeAgreement.title);
      setComments(selectedExchangeAgreement.comments);
      setStartDate(selectedExchangeAgreement.startDate);
      setExpireDate(selectedExchangeAgreement.expirationDate);
      setCronExpression(selectedExchangeAgreement.executionCronExpression);
      setIeaLabels(selectedExchangeAgreement.labels);
      setFrequencyType(selectedExchangeAgreement.frequencyType);
      setPredecessorAgreementId(
        selectedExchangeAgreement.predecessorAgreement?.id
      );
      setLagInMinutes(selectedExchangeAgreement.lagInMinutes);
      setPartnerRepresentativeIds(
        (selectedExchangeAgreement.partnerRepresentatives || []).map(
          (r) => r.id
        )
      );
      setOwnerRepresentativeIds(
        (selectedExchangeAgreement.ownerRepresentatives || []).map((r) => r.id)
      );
    } else {
      setIsCurrentUserSender(false);
      setIsCurrentUserOwner(false);
      setIsCurrentUserPartner(false);
      setPartnerSchedule(undefined);
      setPartnerConnector(undefined);
    }
  }, [loggedInUser, selectedExchangeAgreement]);

  useEffect(() => {
    if (selectedExchangeAgreement) {
      const ieaHeaderData: ExchangeAgreementHeaderData = {
        id: selectedExchangeAgreement.id,
        title: selectedExchangeAgreement.title,
        templateId: selectedExchangeAgreement.template.id,
        templateTitle: selectedExchangeAgreement.template.title,
        sender: selectedExchangeAgreement.sender,
        receiver: selectedExchangeAgreement.receiver,
        labels: ieaLabels,
        senderConfigSettings:
          selectedExchangeAgreement.direction === DirectionEnum.OwnerToPartner
            ? JSON.stringify(ownerHostSystemParameters)
            : JSON.stringify(partnerHostSystemParameters),
      };

      setHeaderData(ieaHeaderData);
    }
  }, [
    ownerHostSystemParameters,
    partnerHostSystemParameters,
    selectedExchangeAgreement,
    ieaLabels,
  ]);

  const handleDeleteLabel = (label: LabelResponse) => {
    setIeaLabels((prev) => {
      let filteredLabels = prev.filter(
        (l) => l.id !== label.id || l._key_ !== label._key_
      );
      return filteredLabels;
    });
    setIsModified(true);
  };

  const handleAddLabel = (label: LabelResponse) => {
    setIeaLabels((labels) => [...labels, label]);
    setIsModified(true);
  };

  const handleSaveActivityFilterClick = (newFilter: string) => {
    if (selectedExchangeAgreement) {
      var partnerConfigSetting = getPartnerHostSystemParametersTyped();
      var ownerConfigSetting = getOwnerHostSystemParametersTyped();
      var updateFieldName = "Activity filter";
      var isUpdateRequestForPartnerConfig = false;

      var partnerConfigSettingString = partnerConfigSetting
        ? JSON.stringify(partnerConfigSetting)
        : null;
      var ownerConfigSettingString = ownerConfigSetting
        ? JSON.stringify(ownerConfigSetting)
        : null;

      if (
        selectedExchangeAgreement.direction === DirectionEnum.OwnerToPartner
      ) {
        let updatedOwnerParameters:
          | SupportedHostSystemConfigurationType
          | undefined;
        setOwnerHostSystmeParameters((prev) => {
          updatedOwnerParameters = prev
            ? selectedExchangeAgreement.ownerConnector.hostSystem !==
              HostSystem.SAP
              ? { ...ownerConfigSetting, [activityFilterFieldName]: newFilter }
              : getDeserializedHostSystemConfiguration<SapExportConfig>(
                  newFilter
                ) ?? undefined
            : undefined;

          return updatedOwnerParameters;
        });
        ownerConfigSettingString = JSON.stringify(updatedOwnerParameters);
      } else if (
        selectedExchangeAgreement.direction === DirectionEnum.PartnerToOwner
      ) {
        let updatedPartnerParameters:
          | SupportedHostSystemConfigurationType
          | undefined;

        setPartnerHostSystemParameters((prev) => {
          updatedPartnerParameters = prev
            ? partnerConnector?.hostSystem !== HostSystem.SAP
              ? {
                  ...partnerConfigSetting,
                  [activityFilterFieldName]: newFilter,
                }
              : getDeserializedHostSystemConfiguration<SapImportConfig>(
                  newFilter
                ) ?? undefined
            : undefined;
          return updatedPartnerParameters;
        });
        partnerConfigSettingString = JSON.stringify(updatedPartnerParameters);
        isUpdateRequestForPartnerConfig = true;
      }
      if (isIeaEditable) {
        setIsModified(true);
        return;
      }
      if (
        !isUpdateRequestForPartnerConfig ||
        (isUpdateRequestForPartnerConfig &&
          selectedExchangeAgreement.actionRequiredIfInactive !==
            NewExchangeAgreementActionRequired.Complete)
      ) {
        const labelIdentifiers = selectedExchangeAgreement.labels.map(
          (l) => l.id
        );
        const ownerRepresentativeIds =
          selectedExchangeAgreement.ownerRepresentatives.map(
            (ownerRep) => ownerRep.id
          );
        const partnerRepresentativeIds =
          selectedExchangeAgreement.partnerRepresentatives.map(
            (partnerRep) => partnerRep.id
          );

        const updateRequest: ExchangeAgreementUpdateRequest = {
          title: selectedExchangeAgreement?.title ?? "",
          comments: selectedExchangeAgreement?.comments ?? "",
          startDate: selectedExchangeAgreement?.startDate ?? "",
          expirationDate: selectedExchangeAgreement?.expirationDate ?? "",
          executionCronExpression:
            selectedExchangeAgreement?.executionCronExpression ?? "",
          lagInMinutes: selectedExchangeAgreement?.lagInMinutes,
          predecessorAgreementId:
            selectedExchangeAgreement?.predecessorAgreement?.id,
          labelIdentifiers: labelIdentifiers,
          ownerRepresentativeIdentifiers: ownerRepresentativeIds,
          ownerConfigSettings: ownerConfigSettingString ?? null, // Only one of these two fields. Other fields should remain same.
          partnerConnectorId: partnerConnector?.id,
          partnerScheduleId: partnerSchedule?.id,
          partnerConfigSettings: partnerConfigSettingString ?? null, // Only one of these two fields. Other fields should remain same.
          partnerRepresentativeIdentifiers: partnerRepresentativeIds,
          frequencyType: selectedExchangeAgreement.frequencyType,
        };

        saveSpecificValues(updateRequest, updateFieldName);
      }
    }
  };

  const handleSavePartnerRepresentativesClick = () => {
    if (selectedExchangeAgreement) {
      const labelIdentifiers = selectedExchangeAgreement.labels.map(
        (l) => l.id
      );
      const ownerRepresentativeIds =
        selectedExchangeAgreement.ownerRepresentatives.map(
          (ownerRep) => ownerRep.id
        );

      let temporaryPartnerRepresentatives = [...partnerRepresentativeIds];
      let filteredPartnerRepresentatives =
        temporaryPartnerRepresentatives.filter(
          (partnerRep: number) => partnerRep !== -1
        );

      const updateRequest: ExchangeAgreementUpdateRequest = {
        title: selectedExchangeAgreement?.title ?? "",
        comments: selectedExchangeAgreement?.comments ?? "",
        startDate: selectedExchangeAgreement?.startDate ?? "",
        expirationDate: selectedExchangeAgreement?.expirationDate ?? "",
        executionCronExpression:
          selectedExchangeAgreement?.executionCronExpression ?? "",
        lagInMinutes: selectedExchangeAgreement?.lagInMinutes,
        predecessorAgreementId:
          selectedExchangeAgreement?.predecessorAgreement?.id,
        labelIdentifiers: labelIdentifiers,
        ownerRepresentativeIdentifiers: ownerRepresentativeIds,
        ownerConfigSettings: getOwnerHostSystemParameters() || null,
        partnerConnectorId: selectedExchangeAgreement.partnerConnector?.id,
        partnerScheduleId: selectedExchangeAgreement.partnerSchedule?.id,
        partnerConfigSettings: getPartnerHostSystemParameters() || null,
        frequencyType: selectedExchangeAgreement.frequencyType,
        partnerRepresentativeIdentifiers: filteredPartnerRepresentatives, // Only updating this field. Other fields should remain same.
      };

      saveSpecificValues(updateRequest, "Partner representatives", () => {
        setIsPartnerRepresentativeModified(false);
      });
    }
  };

  const handleSaveOwnerRepresentativesClick = () => {
    if (selectedExchangeAgreement) {
      const labelIdentifiers = selectedExchangeAgreement.labels.map(
        (l) => l.id
      );
      let temporaryOwnerRepresentatives = [...ownerRepresentativeIds];
      let filteredOwnerRepresentatives = temporaryOwnerRepresentatives.filter(
        (ownerRep: number) => ownerRep !== -1
      );
      const partnerRepresentativeIds =
        selectedExchangeAgreement.partnerRepresentatives.map(
          (partnerRep) => partnerRep.id
        );

      const updateRequest: ExchangeAgreementUpdateRequest = {
        title: selectedExchangeAgreement?.title ?? "",
        comments: selectedExchangeAgreement?.comments ?? "",
        startDate: selectedExchangeAgreement?.startDate ?? "",
        expirationDate: selectedExchangeAgreement?.expirationDate ?? "",
        executionCronExpression:
          selectedExchangeAgreement?.executionCronExpression ?? "",
        lagInMinutes: selectedExchangeAgreement?.lagInMinutes,
        predecessorAgreementId:
          selectedExchangeAgreement?.predecessorAgreement?.id,
        labelIdentifiers: labelIdentifiers,
        ownerRepresentativeIdentifiers: filteredOwnerRepresentatives, // Only updating this field. Other fields should remain same.
        ownerConfigSettings: getOwnerHostSystemParameters() || null,
        partnerConnectorId:
          selectedExchangeAgreement.partnerConnector?.id ??
          partnerConnector?.id,
        partnerScheduleId:
          selectedExchangeAgreement.partnerSchedule?.id ?? partnerSchedule?.id,
        partnerConfigSettings: getPartnerHostSystemParameters() || null,
        partnerRepresentativeIdentifiers: partnerRepresentativeIds,
        frequencyType: selectedExchangeAgreement.frequencyType,
      };

      saveSpecificValues(updateRequest, "Owner representatives", () => {
        setIsOwnerRepresentativeModified(false);
      });
    }
  };

  const saveSpecificValues = (
    updateRequest: ExchangeAgreementUpdateRequest,
    updateFieldName: string,
    onSuccess?: () => void
  ) => {
    displayLoadingPanel();
    dispatch(
      updateExistingExchangeAgreementForPartnerAsync({
        exchangeAgreementId: selectedExchangeAgreement!.id,
        updateRequest: updateRequest,
      })
    )
      .unwrap()
      .then(() => {
        toastSuccess(`${updateFieldName} updated successfully.`);
        onSuccess?.();
      })
      .finally(hideLoadingPanel);
  };

  const handleOwnerIEASign = () => {
    setIsSignForOwnerDialogVisible(true);
  };

  const handleConfirmSignAgreementForOwner = () => {
    if (selectedExchangeAgreement) {
      displayLoadingPanel();
      dispatch(
        signExchangeAgreementOnBehalfOfOwnerAsync(selectedExchangeAgreement.id)
      )
        .unwrap()
        .then(
          (exchangeAgreementSignResponse: ExchangeAgreementSignResponse) => {
            // Once the signature is done, we are navigating to the same page again so that
            // we can get the details response. We need the details response to decide the required actions.
            // Another option could be we can modify the api and send the details response from api instead of signResponse.
            toastSuccess("Owner signed successfully.");
            navigate(`${ACTIVE_IEA}`);
          }
        )
        .finally(hideLoadingPanel);
    }
  };

  const handlePartnerIEASign = () => {
    setIsSignForPartnerDialogVisible(true);
  };

  const handleConfirmSignAgreementForPartner = () => {
    if (selectedExchangeAgreement) {
      displayLoadingPanel();
      dispatch(
        signExchangeAgreementOnBehalfOfPartnerAsync(
          selectedExchangeAgreement.id
        )
      )
        .unwrap()
        .then(
          (exchangeAgreementSignResponse: ExchangeAgreementSignResponse) => {
            // Once the signature is done, we are navigating to the inactive ieas grid so that
            // because we do not get the required actions enum to update the information. We need the details response to decide the required actions.
            // Another option could be we can modify the api and send the details response from api instead of signResponse.
            toastSuccess("Partner signed successfully.");
            navigate(`${NEW_IEAS}`);
          }
        )
        .finally(hideLoadingPanel);
    }
  };

  const getPartnerHostSystemParametersTyped = () => {
    var hostParameterAsString = getPartnerHostSystemParameters();
    if (
      partnerConnector &&
      selectedExchangeAgreement &&
      hostParameterAsString
    ) {
      var hostParameters = getHostSystemConfigurationValue(
        partnerConnector.hostSystem,
        selectedExchangeAgreement.ownerRole === ExchangeRoleEnum.Sender
          ? ConfigDirection.Receiving
          : ConfigDirection.Sending,
        hostParameterAsString
      );
      return hostParameters;
    }
    return null;
  };

  const getPartnerHostSystemParameters = () => {
    if (
      hostParameterGridRef &&
      hostParameterGridRef.current &&
      selectedExchangeAgreement?.direction === DirectionEnum.OwnerToPartner
    ) {
      if (partnerConnector?.hostSystem === HostSystem.SAP) {
        if (hostParameterGridRef.current.getUpdateSapHostParameters) {
          var hostParameters =
            hostParameterGridRef.current.getUpdateSapHostParameters();
          return JSON.stringify(hostParameters);
        }
      } else if (partnerConnector?.hostSystem === HostSystem.Safran) {
        if (hostParameterGridRef.current.getStructureMappings) {
          const structureImportMappings =
            hostParameterGridRef.current.getStructureMappings();
          var safranHostParameter: SafranImportConfig = {
            ...(partnerHostSystemParameters as SafranImportConfig),
            structureImportMappings,
          };
          return JSON.stringify(safranHostParameter);
        }
      }
    }

    return partnerHostSystemParameters
      ? JSON.stringify(partnerHostSystemParameters)
      : "";
  };

  const getOwnerHostSystemParametersTyped = () => {
    var hostParameterAsString = getOwnerHostSystemParameters();
    if (selectedExchangeAgreement && hostParameterAsString) {
      var hostParameters = getHostSystemConfigurationValue(
        selectedExchangeAgreement.ownerConnector.hostSystem,
        selectedExchangeAgreement.ownerRole === ExchangeRoleEnum.Sender
          ? ConfigDirection.Sending
          : ConfigDirection.Receiving,
        hostParameterAsString
      );
      return hostParameters;
    }
    return null;
  };

  const getOwnerHostSystemParameters = () => {
    if (
      hostParameterGridRef &&
      hostParameterGridRef.current &&
      selectedExchangeAgreement?.direction === DirectionEnum.PartnerToOwner
    ) {
      if (
        selectedExchangeAgreement?.ownerConnector?.hostSystem === HostSystem.SAP
      ) {
        if (hostParameterGridRef.current.getUpdateSapHostParameters) {
          var hostParameters =
            hostParameterGridRef.current.getUpdateSapHostParameters();
          return JSON.stringify(hostParameters);
        }
      } else if (
        selectedExchangeAgreement?.ownerConnector?.hostSystem ===
        HostSystem.Safran
      ) {
        if (hostParameterGridRef.current.getStructureMappings) {
          const structureImportMappings =
            hostParameterGridRef.current.getStructureMappings();
          var safranHostParameter: SafranImportConfig = {
            ...(ownerHostSystemParameters as SafranImportConfig),
            structureImportMappings,
          };
          return JSON.stringify(safranHostParameter);
        }
      }
    }

    return ownerHostSystemParameters
      ? JSON.stringify(ownerHostSystemParameters)
      : "";
  };

  const getUpdateExchangeAgreementRequest =
    useCallback((): ExchangeAgreementUpdateRequest => {
      let ownerRepresentatives = [...ownerRepresentativeIds];

      let temporaryPartnerRepresentatives = [...partnerRepresentativeIds];
      let filteredPartnerRepresentatives =
        temporaryPartnerRepresentatives.filter(
          (partnerRep: number) => partnerRep !== -1
        );

      const labelIdentifiers = headerData?.labels
        ? headerData.labels.map((l) => l.id)
        : [];

      const updateRequest: ExchangeAgreementUpdateRequest = {
        title: ieaTitle,
        comments: comments,
        startDate: startDate,
        expirationDate: expireDate,
        executionCronExpression: cronExpression,
        lagInMinutes: lagInMinutes,
        predecessorAgreementId: predecessorAgreementId,
        labelIdentifiers: labelIdentifiers,
        ownerRepresentativeIdentifiers: ownerRepresentatives,
        ownerConfigSettings: getOwnerHostSystemParameters() || null,
        partnerConnectorId: partnerConnector?.id,
        partnerScheduleId: partnerSchedule?.id,
        partnerConfigSettings: getPartnerHostSystemParameters() || null,
        partnerRepresentativeIdentifiers: filteredPartnerRepresentatives,
        frequencyType: frequencyType!,
      };

      return updateRequest;
    }, [
      ieaTitle,
      comments,
      startDate,
      expireDate,
      cronExpression,
      lagInMinutes,
      predecessorAgreementId,
      selectedExchangeAgreement,
      partnerRepresentativeIds,
      ownerRepresentativeIds,
      frequencyType,
      headerData?.labels,
      ownerHostSystemParameters,
      partnerConnector,
      partnerSchedule,
      partnerHostSystemParameters,
    ]);

  const handleDeleteIEAClick = () => {
    setIsDeleteConfirmationDialogVisible(true);
  };

  const handleDeleteConfirm = () => {
    if (selectedExchangeAgreement) {
      displayLoadingPanel();
      dispatch(
        removeInactiveExchangeAgreementAsync(selectedExchangeAgreement.id)
      )
        .unwrap()
        .then(() => {
          toastSuccess("IEA deleted successfully.");
          navigate(`${NEW_IEAS}`);
        })
        .finally(hideLoadingPanel);
    }
  };

  const checkIfOwnerExchangeAgreementUpdateRequestIsValid = useCallback(() => {
    if (partnerConnector && partnerConnector.hostSystem === HostSystem.Safran) {
      if (
        hostParameterGridRef &&
        hostParameterGridRef.current &&
        hostParameterGridRef.current.getStructureMappings
      ) {
        const structureMappings =
          hostParameterGridRef.current.getStructureMappings() as SafranStructureImportMappingConfig[];
        if (structureMappings && structureMappings.length) {
          const errorMessages = [];
          const isValid = structureMappings.every(
            (mapping) =>
              mapping.rFieldName.trim() && mapping.structureName.trim()
          );
          if (!isValid) {
            errorMessages.push(
              "Mapping must have Structure name and R-Field name."
            );
          }

          if (
            checkIfHasDuplicates(
              structureMappings,
              (obj) => `${obj.structureName}-${obj.rFieldName}`
            )
          ) {
            errorMessages.push(
              "Duplicate mapping is not allowed (Same structure mapped to same R field multiple times)."
            );
          }

          if (errorMessages.length) {
            toastError(errorMessages.join("\n"));
            return false;
          }
        }
      }
    }

    return true;
  }, [partnerConnector, partnerConnector?.hostSystem, hostParameterGridRef]);

  const checkIfUpdateRequestIsValid = useCallback(() => {
    if (
      selectedExchangeAgreement &&
      selectedExchangeAgreement.ownerConnector.hostSystem === HostSystem.Safran
    ) {
      if (
        hostParameterGridRef &&
        hostParameterGridRef.current &&
        hostParameterGridRef.current.getStructureMappings
      ) {
        const structureMappings =
          hostParameterGridRef.current.getStructureMappings() as SafranStructureImportMappingConfig[];
        if (structureMappings && structureMappings.length) {
          const errorMessages = [];
          const isValid = structureMappings.every(
            (mapping) =>
              mapping.rFieldName.trim() && mapping.structureName.trim()
          );
          if (!isValid) {
            errorMessages.push(
              "Mapping must have Structure name and R-Field name."
            );
          }

          if (
            checkIfHasDuplicates(
              structureMappings,
              (obj) => `${obj.structureName}-${obj.rFieldName}`
            )
          ) {
            errorMessages.push(
              "Duplicate mapping is not allowed (Same structure mapped to same R field multiple times)."
            );
          }

          if (errorMessages.length) {
            toastError(errorMessages.join("\n"));
            return false;
          }
        }
      }
    }

    return true;
  }, [partnerConnector, partnerConnector?.hostSystem, hostParameterGridRef]);

  const getUpdateExchangeAgreeementRequestWithPartnerReprsentatives =
    useCallback(() => {
      if (selectedExchangeAgreement) {
        const exchangeAgreementUpdateRequest =
          getUpdateExchangeAgreementRequest();

        return exchangeAgreementUpdateRequest;
      }
      return null;
    }, [selectedExchangeAgreement, getUpdateExchangeAgreementRequest]);

  const handleSubmitClick = useCallback(() => {
    if (!checkIfOwnerExchangeAgreementUpdateRequestIsValid()) {
      return;
    }

    const exchangeAgreementUpdateRequest =
      getUpdateExchangeAgreeementRequestWithPartnerReprsentatives();

    if (exchangeAgreementUpdateRequest) {
      displayLoadingPanel();
      dispatch(
        updateExistingExchangeAgreementForPartnerAsync({
          exchangeAgreementId: selectedExchangeAgreement!.id,
          updateRequest: exchangeAgreementUpdateRequest,
        })
      )
        .unwrap()
        .then(() => {
          toastSuccess("IEA updated successfully.");
          setIsModified(false);
          setIsPartnerRepresentativeModified(false);
          setIsOwnerRepresentativeModified(false);
        })
        .finally(hideLoadingPanel);
    }
  }, [
    dispatch,
    getUpdateExchangeAgreementRequest,
    selectedExchangeAgreement,
    checkIfOwnerExchangeAgreementUpdateRequestIsValid,
  ]);

  const navigateForCancellation = () => {
    navigate(-1);
  };

  const handleCancelClick = () => {
    if (
      isModified ||
      isPartnerRepresentativeModified ||
      isOwnerRepresentativeModified
    ) {
      setIsUnsavedChangeConfirmationVisible(true);
    } else {
      navigateForCancellation();
    }
  };

  const handleConfirmCancel = () => {
    setIsUnsavedChangeConfirmationVisible(false);
    navigateForCancellation();
  };

  const handleCancelConfirmationDialog = () => {
    setIsUnsavedChangeConfirmationVisible(false);
  };

  const handleSaveActiveIEA = useCallback(() => {
    if (!checkIfUpdateRequestIsValid()) {
      return;
    }

    const exchangeAgreementUpdateRequest =
      getUpdateExchangeAgreeementRequestWithPartnerReprsentatives();

    if (exchangeAgreementUpdateRequest) {
      exchangeAgreementUpdateRequest.comments = comments;
      exchangeAgreementUpdateRequest.title = ieaTitle;
      exchangeAgreementUpdateRequest.startDate = startDate;
      exchangeAgreementUpdateRequest.expirationDate = expireDate;
      exchangeAgreementUpdateRequest.executionCronExpression =
        frequencyType === FrequencyType.CronExpression && cronExpression
          ? cronExpression
          : "";
      exchangeAgreementUpdateRequest.predecessorAgreementId =
        frequencyType === FrequencyType.Predecessor
          ? predecessorAgreementId
          : undefined;
      exchangeAgreementUpdateRequest.lagInMinutes = lagInMinutes;
      exchangeAgreementUpdateRequest.frequencyType =
        frequencyType ?? FrequencyType.CronExpression;

      displayLoadingPanel();
      dispatch(
        updateExistingExchangeAgreementAsync({
          exchangeAgreementId: selectedExchangeAgreement!.id,
          updateRequest: exchangeAgreementUpdateRequest,
        })
      )
        .unwrap()
        .then((updatedIea: ExchangeAgreementDetailedResponse) => {
          toastSuccess("IEA updated successfully.");
          setIsModified(false);
          setIsOwnerRepresentativeModified(false);
          props.onSaved?.(updatedIea);
        })
        .finally(hideLoadingPanel);
    }
  }, [
    selectedExchangeAgreement,
    comments,
    startDate,
    expireDate,
    ownerRepresentativeIds,
    partnerRepresentativeIds,
    getUpdateExchangeAgreeementRequestWithPartnerReprsentatives,
    cronExpression,
    predecessorAgreementId,
    lagInMinutes,
  ]);

  const updateOwnerHostSystemConfigurationValue = (
    fieldName: string,
    value: any
  ) => {
    if (ownerHostSystemParameters) {
      setOwnerHostSystmeParameters((previousConfiguration) => ({
        ...previousConfiguration!,
        [fieldName]: value,
      }));
    }
  };

  const updatePartnerHostSystemConfigurationValue = (
    fieldName: string,
    value: any
  ) => {
    if (partnerHostSystemParameters) {
      setPartnerHostSystemParameters((previousConfiguration) => ({
        ...previousConfiguration!,
        [fieldName]: value,
      }));
    }
  };

  const handleSenderHostConfigurationValueChange = (
    fieldName: string,
    value: any
  ) => {
    if (selectedExchangeAgreement) {
      if (
        selectedExchangeAgreement.direction === DirectionEnum.PartnerToOwner
      ) {
        updatePartnerHostSystemConfigurationValue(fieldName, value);
      } else {
        updateOwnerHostSystemConfigurationValue(fieldName, value);
      }
      setIsModified(true);
    }
  };

  const handleReceiverHostConfigurationValueChange = (
    fieldName: string,
    value: any
  ) => {
    if (selectedExchangeAgreement) {
      if (
        selectedExchangeAgreement.direction === DirectionEnum.OwnerToPartner
      ) {
        updatePartnerHostSystemConfigurationValue(fieldName, value);
      } else {
        updateOwnerHostSystemConfigurationValue(fieldName, value);
      }
      setIsModified(true);
    }
  };

  const headerInformationText = selectedExchangeAgreement
    ? getHeaderInformationText(
        selectedExchangeAgreement,
        isCurrentUserOwner,
        isCurrentUserPartner
      )
    : "";

  const handleTitleChange = (data: any) => {
    setIeaTitle(data.value);
    setIsModified(true);
  };

  const handleStartDateChange = (value: string) => {
    setStartDate(value);
    setIsModified(true);
  };

  const handleExpirationDateChange = (value: string) => {
    setExpireDate(value);
    setIsModified(true);
  };

  const handleCommentChange = (value: string) => {
    setComments(value);
    setIsModified(true);
  };

  const handleCronExpressionChange = (value: string) => {
    setCronExpression(value);
    setIsModified(true);
  };

  const handleLagMinutesChange = (value: number) => {
    setLagInMinutes(value);
    setIsModified(true);
  };

  const handlePredecessorAgreementIdChange = (value: number) => {
    setPredecessorAgreementId(value);
    setIsModified(true);
  };

  const handleFrequencyTypeChange = (value: FrequencyType) => {
    setFrequencyType(value);
    setLagInMinutes(0);
    setIsModified(true);
  };

  if (!headerData) {
    return <></>;
  }

  return (
    <>
      <div id={DivId.InactiveIEAHeaderId}>
        <IEAHeader
          senderHostSystem={senderHostSystem}
          isOwner={isCurrentUserOwner}
          isPartner={isCurrentUserPartner}
          headerData={headerData}
          isActivityFilterEditable={isCurrentUserSender}
          titleWidget={
            isIeaEditable ? (
              selectedExchangeAgreement?.isPendingStart ? (
                <PendingStartDatePageHeaderLabel />
              ) : (
                <ActivePageHeaderLabel />
              )
            ) : (
              <UnderConstructionPageHeaderLabel />
            )
          }
          ieaFrequencyType={
            selectedExchangeAgreement
              ? selectedExchangeAgreement.frequencyType
              : FrequencyType.CronExpression
          }
          onAddNewLabel={handleAddLabel}
          onDeleteLabel={handleDeleteLabel}
          onSaveActivityFilterClick={handleSaveActivityFilterClick}
          allowEdit={isIeaEditable && isCurrentUserOwner}
          onTitleChange={handleTitleChange}
        />
        {selectedExchangeAgreement && headerInformationText && (
          <InformationBar text={headerInformationText} />
        )}
      </div>
      {selectedExchangeAgreement && (
        <PromineoTabPanel>
          <Item title={"IEA Info"}>
            <IEAScrollView
              divIdsToExclude={divIdsToExclude}
              childElement={
                <div className="p-4">
                  <InactiveIEAInfo
                    partnerConnectorId={partnerConnector?.id}
                    partnerScheduleId={partnerSchedule?.id}
                    startDate={startDate}
                    expirationDate={expireDate}
                    exchangeAgreement={selectedExchangeAgreement}
                    partnerConfigSettings={partnerConfigSetting}
                    isOwner={isCurrentUserOwner}
                    isCurrentUserPartner={isCurrentUserPartner}
                    handlePartnerRepresentativeChange={
                      handlePartnerRepresentativeChange
                    }
                    handleOwnerRepresentativeChange={
                      handleOwnerRepresentativeChange
                    }
                    handlePartnerConnectorChange={handlePartnerConnectorChange}
                    handlePartnerScheduleChange={handlePartnerScheduleIdChange}
                    handleSaveOwnerRepresentativeClick={
                      handleSaveOwnerRepresentativesClick
                    }
                    handleSavePartnerRepresentativeClick={
                      handleSavePartnerRepresentativesClick
                    }
                    isActiveIeaEdit={isIeaEditable}
                    onCommentChange={handleCommentChange}
                    onCronExpressionChange={handleCronExpressionChange}
                    onStartDateChange={handleStartDateChange}
                    onExpirationDateChange={handleExpirationDateChange}
                    onLagMinutesChange={handleLagMinutesChange}
                    onPredecessorAgreementIdChange={
                      handlePredecessorAgreementIdChange
                    }
                    onFrequencyTypeChange={handleFrequencyTypeChange}
                  />
                </div>
              }
            />
          </Item>
          <Item title={"Host system parameters"}>
            <IEAScrollView
              divIdsToExclude={divIdsToExclude}
              childElement={
                <div className="p-4">
                  <HostParametersOverride
                    ref={hostParameterGridRef}
                    exportConfig={
                      selectedExchangeAgreement.direction ===
                      DirectionEnum.OwnerToPartner
                        ? ownerHostSystemParameters
                        : partnerHostSystemParameters
                    }
                    importConfig={
                      selectedExchangeAgreement.direction ===
                      DirectionEnum.OwnerToPartner
                        ? partnerHostSystemParameters
                        : ownerHostSystemParameters
                    }
                    isCurrentUserReceiver={isCurrentUserReceiver}
                    isCurrentUserSender={isCurrentUserSender}
                    receiverCodeSet={
                      selectedExchangeAgreement.direction ===
                      DirectionEnum.PartnerToOwner
                        ? selectedExchangeAgreement.ownerSchedule.userFieldSetId
                        : partnerSchedule?.userFieldSetId
                    }
                    receiverConnectorId={
                      selectedExchangeAgreement.direction ===
                      DirectionEnum.OwnerToPartner
                        ? selectedExchangeAgreement.partnerConnector?.id ??
                          partnerConnector?.id
                        : selectedExchangeAgreement.ownerConnector.id
                    }
                    senderCodeSet={
                      selectedExchangeAgreement.direction ===
                      DirectionEnum.OwnerToPartner
                        ? selectedExchangeAgreement.ownerSchedule.userFieldSetId
                        : partnerSchedule?.userFieldSetId
                    }
                    receiverHostSystem={
                      selectedExchangeAgreement.ownerRole ===
                      ExchangeRoleEnum.Receiver
                        ? selectedExchangeAgreement.ownerConnector.hostSystem
                        : selectedExchangeAgreement.partnerConnector
                            ?.hostSystem ?? partnerConnector?.hostSystem
                    }
                    senderHostSystem={senderHostSystem}
                    senderConnectorId={
                      selectedExchangeAgreement.direction ===
                      DirectionEnum.OwnerToPartner
                        ? selectedExchangeAgreement.ownerConnector.id
                        : selectedExchangeAgreement.partnerConnector?.id ??
                          partnerConnector?.id
                    }
                    onSenderConfigurationValueChanged={
                      handleSenderHostConfigurationValueChange
                    }
                    onReceiverConfigurationValueChanged={
                      handleReceiverHostConfigurationValueChange
                    }
                    gridHeight={300}
                    isEditable={
                      // TODO: Decide when should be editable.
                      selectedExchangeAgreement.actionRequiredIfInactive ===
                        NewExchangeAgreementActionRequired.None ||
                      selectedExchangeAgreement.actionRequiredIfInactive ===
                        NewExchangeAgreementActionRequired.WaitingForPartner ||
                      selectedExchangeAgreement.actionRequiredIfInactive ===
                        NewExchangeAgreementActionRequired.Complete ||
                      isIeaEditable === true
                    }
                    onStructureGridModified={() => {
                      setIsModified(true);
                    }}
                  />
                </div>
              }
            />
          </Item>
        </PromineoTabPanel>
      )}

      <div id={DivId.InactiveIEAFooterId}>
        <div className="border-b border-lightGray"></div>
        <div>
          {selectedExchangeAgreement && (
            <InactiveIEAFooter
              isPartnerSubmitButtonDisabled={disablePartnerSubmitButton}
              isCurrentUserOwner={isCurrentUserOwner}
              isCurrentUserPartner={isCurrentUserPartner}
              selectedExchangeAgreement={selectedExchangeAgreement}
              handleDeleteIEAClick={handleDeleteIEAClick}
              handleSubmitClick={handleSubmitClick}
              handleOwerIEASign={handleOwnerIEASign}
              handlePartnerIEASign={handlePartnerIEASign}
              handleCancelClick={handleCancelClick}
              isActiveIeaEdit={isIeaEditable}
              handleSaveActiveIEA={handleSaveActiveIEA}
              isSaveChangesButtonDisabled={!isModified}
              isActiveIeaSaveButtonDisabled={
                !isModified || !isActiveIeaUpdateRequestValid
              }
            />
          )}

          {isSignForPartnerDialogVisible && (
            <PromineoConfirmationDialog
              onConfirm={handleConfirmSignAgreementForPartner}
              onCancel={() => {
                setIsSignForPartnerDialogVisible(false);
              }}
              content={`Are you sure you want to sign the agreement on behalf of partner?`}
              cancelButtonText="No"
              confirmButtonText="Yes"
            />
          )}

          {isSignForOwnerDialogVisible && (
            <PromineoConfirmationDialog
              onConfirm={handleConfirmSignAgreementForOwner}
              onCancel={() => {
                setIsSignForOwnerDialogVisible(false);
              }}
              content={`Are you sure you want to sign the agreement on behalf of owner?`}
              cancelButtonText="No"
              confirmButtonText="Yes"
            />
          )}

          {isDeleteConfirmationDialogVisible && (
            <PromineoConfirmationDialog
              onConfirm={handleDeleteConfirm}
              onCancel={() => {
                setIsDeleteConfirmationDialogVisible(false);
              }}
              content={`Are you sure you want to delete this agreement?`}
              cancelButtonText="No"
              confirmButtonText="Yes"
            />
          )}
        </div>
      </div>

      {isUnsavedChangeConfirmationVisible ? (
        <PromineoCancelEditingConfirmationDialog
          onConfirm={handleConfirmCancel}
          onCancel={handleCancelConfirmationDialog}
        ></PromineoCancelEditingConfirmationDialog>
      ) : null}
    </>
  );
}
