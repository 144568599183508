import NumberBox, { INumberBoxOptions } from "devextreme-react/number-box";
import "./styles/PromineoNumberBox.css";

interface Props {}

export default function PromineoNumberBox(props: Props & INumberBoxOptions) {
  const { className, ...rest } = props;

  const getClassNames = () => {
    const defaultClassNames = `promineo-numberbox`;

    return className ? `${defaultClassNames} ${className}` : defaultClassNames;
  };

  return <NumberBox className={getClassNames()} {...rest} />;
}
