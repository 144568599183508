import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import LabelWithContent from "components/common/LabelWithContent";
import { NewExchangeAgreementActionRequired } from "shared/enums/feature/NewExchangeAgreementActionRequired";
interface Props {
  isPartner: boolean;
  requiredAction: NewExchangeAgreementActionRequired | undefined;
  selectedPartnerConnectorId: number | null | undefined;
  connectorDataSource: any[];
  labelClassName: string;
  exchangeAgreement: {
    partnerConnector: { name: string } | null;
  };
  handleConnectorSelectionChange: (value: any) => void;
}

export default function ConnectorField({
  isPartner,
  requiredAction,
  selectedPartnerConnectorId,
  connectorDataSource,
  labelClassName,
  exchangeAgreement,
  handleConnectorSelectionChange,
}: Props) {
  return (
    <>
      {isPartner &&
      requiredAction === NewExchangeAgreementActionRequired.Complete ? (
        <LabelWithContent
          label="Connector name"
          addAsterisksymbol={true}
          content={
            <PromineoSelectBox
              placeholder="Select"
              value={selectedPartnerConnectorId}
              valueExpr="id"
              items={connectorDataSource}
              displayExpr="name"
              onValueChange={handleConnectorSelectionChange}
            />
          }
        />
      ) : (
        <LabelWithContent
          label="Connector name"
          content={
            <div className={labelClassName}>
              {exchangeAgreement.partnerConnector
                ? exchangeAgreement.partnerConnector.name
                : "-"}
            </div>
          }
        />
      )}
    </>
  );
}
