import DropDownBox, { IDropDownBoxOptions } from "devextreme-react/drop-down-box";
import "./styles/PromineoDropdownBox.css";

export interface DropdownOptions {
  roundedDirection?: "left" | "right" | "both" | "none";
  borderRound?: "large" | "small";
}

export default function PromineoDropdownBox(
  props: DropdownOptions & IDropDownBoxOptions
) {
  const { className, ...rest } = props;
  return (
    <>
      <DropDownBox
        {...rest}
        className={`promineo-dropdown-box ${className ?? ""} ${
          props.borderRound ? `round-${props.borderRound}` : "round-large"
        }  rounded-${props.roundedDirection ?? "both"}`}
      />
    </>
  );
}
