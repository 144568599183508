import {
  createConnectorAsync,
  deleteConnectorAsync,
  generateConnectorEventAsync,
  getConnectorDetailsAsync,
  getConnectorEventStatusAsync,
  getConnectorHostFieldsAsync,
  getConnectorAsync,
  getConnectorHostSchdulesAsync,
  getConnectorsForCurrentUserAsync,
  updateConnectorAsync,
  getSimplifiedConnectorsAsync,
} from "apis/ConnectorApi";
import ConnectorEventCreateRequest from "interfaces/request/ConnectorEventCreateRequest";
import ConnectorModifyRequest from "interfaces/request/ConnectorModifyRequest";
import ConnectorWriteBaseRequest from "interfaces/request/ConnectorWriteBaseRequest";
import HostFieldResponse from "interfaces/response/HostFieldResponse";
import ConnectorResponse from "interfaces/response/connector/ConnectorResponse";
import { RootState } from "store/store";
import { createAppAsyncThunk } from "store/ThunkUtility";

export const loadConnectorsForCurrentUserAsync = createAppAsyncThunk(
  "Connecotrs/loadConnectorsForCurrentUser",
  async () => {
    return await getConnectorsForCurrentUserAsync();
  }
);

export const loadConnectorAsync = createAppAsyncThunk(
  "Connecotrs/loadConnectorAsync",
  async (connectorId: number) => {
    return await getConnectorAsync(connectorId);
  }
);

export const loadConnectorDetailsAsync = createAppAsyncThunk(
  "Connecotrs/loadConnectorDetails",
  async (connectorId: number) => {
    return await getConnectorDetailsAsync(connectorId);
  }
);

export const createNewConnectorAsync = createAppAsyncThunk(
  "Connecotrs/createNewConnectorAsync",
  async (connectorRequest: ConnectorWriteBaseRequest) => {
    return await createConnectorAsync(connectorRequest);
  }
);

export const modifyConnectorAsync = createAppAsyncThunk<
  ConnectorResponse,
  { connectorId: number; connectorRequest: ConnectorModifyRequest }
>("Connecotrs/modifyConnectorAsync", async (request) => {
  return await updateConnectorAsync(
    request.connectorId,
    request.connectorRequest
  );
});

export const loadSimplifiedConnectorsAsync = createAppAsyncThunk(
  "Connecotrs/loadSimplifiedConnectors",
  async () => {
    return await getSimplifiedConnectorsAsync();
  }
);

export const removeConnectorAsync = createAppAsyncThunk(
  "Connecotrs/removeConnector",
  async (connectorId: number, { rejectWithValue }) => {
    try {
      return await deleteConnectorAsync(connectorId);
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const generateConnectorPersistEventAsync = createAppAsyncThunk(
  "Connecotrs/generateConnectorEventAsync",
  async (connectorEventCreateRequest: ConnectorEventCreateRequest) => {
    return await generateConnectorEventAsync(connectorEventCreateRequest);
  }
);

export const loadConnectorEventStatusAsync = createAppAsyncThunk(
  "Connecotrs/getConnectorEventStatusAsync",
  async (connectorId: number) => {
    return await getConnectorEventStatusAsync(connectorId);
  }
);

export const loadConnectorHostFieldAsync = createAppAsyncThunk<
  HostFieldResponse[],
  {
    connectorId: number;
    includeAll: boolean;
    userFieldSetId: number;
    includeAllWithNullUserFieldId: boolean;
  },
  { state: RootState }
>("Connecotrs/loadConnectorHostFieldAsync", async (request, thunkApi) => {
    const state = thunkApi.getState();
    let loadHostFieldsWithUserFieldSetIdFromCache = false;
    let loadHostFieldsWithNullUserFieldSetIdFromCache = false;

    if(state.connectorData.selectedDetailedConnector?.id === request.connectorId && !request.includeAll){
        loadHostFieldsWithUserFieldSetIdFromCache = !!request.userFieldSetId && (state.connectorData.selectedDetailedConnector?.hostFields.some(
          (hf) => hf.userFieldSetId === request.userFieldSetId) ?? false);
        loadHostFieldsWithNullUserFieldSetIdFromCache = request.includeAllWithNullUserFieldId && (state.connectorData.selectedDetailedConnector?.hostFields.some(
          (hf) => !hf.userFieldSetId) ?? false);
    }

    let fetchdedHostFields: HostFieldResponse[] = []; 
    let hostFieldsFromCache: HostFieldResponse[] = [];

    if (
      request.includeAll ||
      (!loadHostFieldsWithUserFieldSetIdFromCache &&
        !!request.userFieldSetId) ||
      (!loadHostFieldsWithNullUserFieldSetIdFromCache &&
        request.includeAllWithNullUserFieldId)
    ) {
      fetchdedHostFields = await getConnectorHostFieldsAsync(
        request.connectorId,
        request.includeAll,
        loadHostFieldsWithUserFieldSetIdFromCache ? 0 : request.userFieldSetId,
        loadHostFieldsWithNullUserFieldSetIdFromCache
          ? false
          : request.includeAllWithNullUserFieldId
      );
    }

    hostFieldsFromCache = state.connectorData.selectedDetailedConnector?.hostFields.filter(
      (hf) => (loadHostFieldsWithUserFieldSetIdFromCache && hf.userFieldSetId === request.userFieldSetId) 
    || (loadHostFieldsWithNullUserFieldSetIdFromCache && !hf.userFieldSetId)) ?? [];

    return [...fetchdedHostFields, ...hostFieldsFromCache];
  }
);

export const loadConnectorHostSchdulesAsync = createAppAsyncThunk(
  "Connecotrs/loadConnectorHostSchdulesAsync",
  async (connectorId: number) => {
    return await getConnectorHostSchdulesAsync(connectorId);
  }
);
