import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import "components/common/grid/styles/PromineoUIGrid.css";
import { useEffect, useState } from "react";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { useAppDispatch } from "store/hooks";
import { loadConnectorHostFieldAsync } from "store/actions/ConnectorActions";
import { unwrapResult } from "@reduxjs/toolkit";
import HostFieldResponse from "interfaces/response/HostFieldResponse";
import { toastError } from "shared/utilities/ToastUtility";

interface Props {
  connectorId: number;
  height: number;
}

export default function HostFieldGrid(props: Props) {
  const { connectorId, height } = props;

  const dispatch = useAppDispatch();
  const [hostFields, setHostFields] = useState<HostFieldResponse[]>([]);

  useEffect(() => {
    displayLoadingPanel();
    dispatch(
      loadConnectorHostFieldAsync({
        connectorId: connectorId,
        includeAll: true,
        userFieldSetId: 0,
        includeAllWithNullUserFieldId: true,
      })
    )
      .then(unwrapResult)
      .then((hostFieldResponse: HostFieldResponse[]) => {
        setHostFields(hostFieldResponse);
      })
      .catch(() => {
        toastError(
          `Failed to load host fields for connector with id ${connectorId}.`
        );
      })
      .finally(hideLoadingPanel);
  }, [connectorId, dispatch]);

  return (
    <>
      <PromineoViewGrid
        dataSource={hostFields}
        className="connectors-hostField-grid disable-hover promineo-ui-grid"
        toolbarConfig={{
          dislplaySearchPanel: true,
        }}
        height={height}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <Column
          caption={"Code set"}
          dataField="userFieldSetId"
          alignment="left"
          width={200}
        />
        <Column
          caption={"Planning Object Type"}
          dataField="planningObjectTypeText"
          alignment="left"
        />
        <Column caption={"Host field Name"} dataField="name" alignment="left" />
        <Column caption={"Alias"} dataField="alias" alignment="left" />
        <Column
          caption={"Data type"}
          dataField="dataTypeText"
          alignment="left"
        />
      </PromineoViewGrid>
    </>
  );
}
