import { IEACoreInfoProps } from "features/ieas/active-ieas/IEACoreInfo";
import { StepOneNewIeaProps } from "features/ieas/active-ieas/new-iea/StepOneNewIEA";
import ConfigForExchangeAgreementResponse from "interfaces/response/ConfigForExchangeAgreementResponse";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import isEqual from "lodash/isEqual";
import { useEffect, useMemo, useState } from "react";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";

interface Props {
  configListForExchangeAgreement: ConfigForExchangeAgreementResponse[];
  exchangeAgreementToDuplicate?: ExchangeAgreementDetailedResponse | null;
}

interface IeaCoreInfoRequest {
  title: string;
  exchangeRole: ConfigDirection;
  templateId: number;
  partnerTenantId: number;
  partnerRepresentativeId: number;
}

interface HookResponseType {
  stepOneProps: StepOneNewIeaProps;
  coreInfoRequest: IeaCoreInfoRequest;
  isCoreInfoRequestValid: boolean;
  isModified: boolean;
}

const defaultCoreInfoRequest: IeaCoreInfoRequest = {
  title: "",
  exchangeRole: ConfigDirection.Receiving,
  partnerRepresentativeId: 0,
  partnerTenantId: 0,
  templateId: 0,
};

export default function useStepOneNewIeaDataHook(
  props: Props
): HookResponseType {
  const { exchangeAgreementToDuplicate, configListForExchangeAgreement } =
    props;
  const [isValidRequest, setIsValidRequest] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [coreInfoRequest, setCoreInfoRequest] = useState<IeaCoreInfoRequest>(
    defaultCoreInfoRequest
  );

  useEffect(() => {
    let isValid = true;
    if (
      !coreInfoRequest.title ||
      !coreInfoRequest.templateId ||
      coreInfoRequest.exchangeRole === undefined ||
      coreInfoRequest.partnerRepresentativeId <= 0 ||
      coreInfoRequest.partnerTenantId <= 0
    ) {
      isValid = false;
    }
    setIsValidRequest(isValid);
    setIsModified(!isEqual(coreInfoRequest, defaultCoreInfoRequest));
  }, [coreInfoRequest]);

  useEffect(() => {
    if (exchangeAgreementToDuplicate) {
      let copiedIEATitle = `Copy of ${exchangeAgreementToDuplicate.title}`;
      let copiedIEAOwnerConfigDirection =
        exchangeAgreementToDuplicate.ownerRole === ExchangeRoleEnum.Sender
          ? ConfigDirection.Sending
          : ConfigDirection.Receiving;
      let copiedIEASelectedTemplateId =
        exchangeAgreementToDuplicate.template.id;
      let copiedIEASelectedPartnerTenantId =
        exchangeAgreementToDuplicate.partnerTenant.id;
      let copiedIEASelectedPartnerRepresentativeId =
        exchangeAgreementToDuplicate.partnerRepresentatives.length > 0
          ? exchangeAgreementToDuplicate.partnerRepresentatives[0].id
          : 0;
      let updatedCoreInfoRequest: IeaCoreInfoRequest = {
        title: copiedIEATitle,
        exchangeRole: copiedIEAOwnerConfigDirection,
        templateId: copiedIEASelectedTemplateId,
        partnerTenantId: copiedIEASelectedPartnerTenantId,
        partnerRepresentativeId: copiedIEASelectedPartnerRepresentativeId,
      };
      setCoreInfoRequest(updatedCoreInfoRequest);
    }
  }, [exchangeAgreementToDuplicate]);

  const onTitleChange = (value: string) => {
    setCoreInfoRequest((prev) => {
      return { ...prev, title: value };
    });
  };

  const onDirectionChange = (direction: ConfigDirection) => {
    setCoreInfoRequest((prev) => {
      return { ...prev, exchangeRole: direction };
    });
  };

  const onTemplateIdChange = (value: number) => {
    setCoreInfoRequest((prev) => {
      return { ...prev, templateId: value };
    });
  };

  const onPartnerTenantIdChange = (value: number) => {
    setCoreInfoRequest((prev) => {
      return { ...prev, partnerTenantId: value };
    });
  };

  const onPartnerRepresentativeIdChange = (value: number) => {
    setCoreInfoRequest((prev) => {
      return { ...prev, partnerRepresentativeId: value };
    });
  };

  const response = useMemo(() => {
    return {
      stepOneProps: {
        coreInfoProps: {
          ownerConfigList: configListForExchangeAgreement,
          title: coreInfoRequest.title,
          direction: coreInfoRequest.exchangeRole,
          templateId: coreInfoRequest.templateId,
          partnerTenantId: coreInfoRequest.partnerTenantId,
          partnerRepresentativeId: coreInfoRequest.partnerRepresentativeId,
          isDuplicatingIEA: exchangeAgreementToDuplicate ? true : false,
          onTitleChange,
          onDirectionChange,
          onTemplateIdChange,
          onPartnerTenantIdChange,
          onPartnerRepresentativeIdChange,
        } as IEACoreInfoProps,
      } as StepOneNewIeaProps,
      isModified,
      coreInfoRequest: coreInfoRequest,
      isCoreInfoRequestValid: isValidRequest,
    };
  }, [
    coreInfoRequest,
    configListForExchangeAgreement,
    isValidRequest,
    exchangeAgreementToDuplicate,
    isModified,
  ]);

  return response;
}
