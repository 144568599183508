import {
  changeFieldsContentControlForTenantAdminAsync,
  loadContentControlFieldsForTenantAdminAsync,
  loadFieldDetailForTenantAdminAsync,
  loadTemplatesUsingFieldForTenantAdminAsync,
  modifyTemplateFieldWithValuesAsync,
  modifyTenantFieldWithValuesAsync,
  loadAdminTenantAsync,
  requestOwnerStatusAsync,
  saveAdminTenantAsync,
  getTenantUsersAsync,
  modifyUserAsync,
  modifyUserStatusAsync,
  generateKeyPairForOwnTenantAsync,
  getOwnTenantPublicKeysAsync,
} from "apis/TenantAdminApi";
import OwnTenantWriteRequest from "interfaces/request/OwnTenantWriteRequest";
import OwnTenantResponse from "interfaces/response/tenant/OwnTenantResponse";
import FieldManageRequest from "interfaces/request/FieldManageRequest";
import TenantLevelContentControlChangeRequest from "interfaces/request/TenantLevelContentControlChangeRequest";
import TenantUserUpdateRequest from "interfaces/common/TenantUserUpdateRequest";
import UserStatusRequestResponse from "interfaces/common/UserStatusRequestResponse";
import { createAppAsyncThunk } from "store/ThunkUtility";

export const getTenantForTenantAdminAsync = createAppAsyncThunk(
  "tenant-admin/getTenantForTenantAdmin",
  async () => {
    return await loadAdminTenantAsync();
  }
);

export const generateKeyPairForOwnTenantRequestAsync = createAppAsyncThunk(
  "tenant-admin/generateKeyPairForOwnTenantRequest",
  async () => {
    return await generateKeyPairForOwnTenantAsync();
  }
);

export const getOwnTenantPublicKeysRequestAsync = createAppAsyncThunk(
  "tenant-admin/getOwnTenantPublicKeysRequest",
  async () => {
    return await getOwnTenantPublicKeysAsync();
  }
);

export const updateAdminTenantAsync = createAppAsyncThunk<
  OwnTenantResponse,
  { tenantId: number; tenantUpdateRequest: OwnTenantWriteRequest }
>("tenant-admin/tenant/updateAdminTenant", async (request) => {
  return await saveAdminTenantAsync(
    request.tenantId,
    request.tenantUpdateRequest
  );
});

export const makeRequestAskingOwnerStatusAsync = createAppAsyncThunk(
  "tenant-admin/tenant/makeRequestAskingOwnerStatus",
  async () => {
    return await requestOwnerStatusAsync();
  }
);

export const getContentControlFieldsForTenantAdminAsync = createAppAsyncThunk(
  "tenant-admin/getContentControlFieldsForTenantAdmin",
  async () => {
    return await loadContentControlFieldsForTenantAdminAsync();
  }
);

export const getTemplatesUsingFieldForTenantAdminAsync = createAppAsyncThunk(
  "tenant-admin/getTemplatesUsingFieldForTenantAdmin",
  async (fieldId: number) => {
    return await loadTemplatesUsingFieldForTenantAdminAsync(fieldId);
  }
);

export const getFieldForTenantAdminAsync = createAppAsyncThunk(
  "tenant-admin/getFieldForTenantAdmin",
  async (fieldId: number) => {
    return await loadFieldDetailForTenantAdminAsync(fieldId);
  }
);

export const updateFieldsContentControlForTenantAdminAsync =
  createAppAsyncThunk(
    "tenant-admin/updateFieldsContentControlForTenantAdmin",
    async (data: {
      fieldId: number;
      request: TenantLevelContentControlChangeRequest;
    }) => {
      return await changeFieldsContentControlForTenantAdminAsync(
        data.fieldId,
        data.request
      );
    }
  );

export const updateTenantFieldWithValuesAsync = createAppAsyncThunk(
  "tenant-admin/updateTenantFieldWithValues",
  async (data: { tenantFieldId: number; request: FieldManageRequest }) => {
    return await modifyTenantFieldWithValuesAsync(
      data.tenantFieldId,
      data.request
    );
  }
);

export const updateTemplateFieldWithValuesAsync = createAppAsyncThunk(
  "tenant-admin/updateTemplateFieldWithValues",
  async (data: { templateFieldId: number; request: FieldManageRequest }) => {
    return await modifyTemplateFieldWithValuesAsync(
      data.templateFieldId,
      data.request
    );
  }
);

export const getTenantUsersForTenantAdminAsync = createAppAsyncThunk(
  "tenant-admin/getTenantUsersForTenantAdmin",
  async () => {
    return await getTenantUsersAsync();
  }
);

export const updateUserAsync = createAppAsyncThunk(
  "tenant-admin/updateUser",
  async (data: { userId: number; request: TenantUserUpdateRequest }) => {
    return await modifyUserAsync(data.userId, data.request);
  }
);

export const updateUserStatusAsync = createAppAsyncThunk(
  "tenant-admin/updateUserStatus",
  async (data: { userId: number; request: UserStatusRequestResponse }) => {
    return await modifyUserStatusAsync(data.userId, data.request);
  }
);
