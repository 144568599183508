import { unwrapResult } from "@reduxjs/toolkit";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import ErrorDisplayModal from "features/common/error-display-modal/ErrorDisplayModal";
import useImportConfigFromClipboard from "hooks/ImportConfigFromClipboardHook";
import ConfigDetailResponse from "interfaces/response/ConfigDetailResponse";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { loadConfigByIdAsync } from "store/actions/ConfigActions";
import { loadConnectorAsync, loadConnectorHostFieldAsync, loadConnectorHostSchdulesAsync } from "store/actions/ConnectorActions";
import { loadCoreFieldsAsync } from "store/actions/CoreFieldActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { resetCurrentConfig } from "store/slices/ConfigSlice";
import { resetSelectedDetailedConnector } from "store/slices/ConnectorSlice";
import ConfigEditor from "./config-edit/ConfigEditor";

export default function EditConfig() {
  const dispatch = useAppDispatch();
  const param = useParams();
  const location = useLocation();
  const [isConfigDataLoaded, setIsConfigDataLoaded] = useState(false);
  const [isConnectorNullFieldsLoaded, setIsConnectorNullFieldsLoaded] =useState<boolean>(false);
  const [isErrorModalVisible, setIsErrorModalVisible] =
    useState<boolean>(false);

  const selectedConnectorDetail = useAppSelector(
    (state) => state.connectorData.selectedDetailedConnector
  );

  const selectedConfig = useAppSelector(
    (state) => state.configData.currentConfig
  );

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadCoreFieldsAsync()).finally(hideLoadingPanel);
  }, [dispatch]);

  useEffect(() => {
    const loadConnectorForConfig = (config: ConfigDetailResponse)=>{
      displayLoadingPanel();
      dispatch(loadConnectorAsync(config.connectorId))
        .then(unwrapResult)
        .then(() => {
          return Promise.all([
            dispatch(loadConnectorHostSchdulesAsync(config.connectorId)),
            dispatch(loadConnectorHostFieldAsync({
              connectorId: config.connectorId,
              includeAll: false,
              userFieldSetId: 0,
              includeAllWithNullUserFieldId: true,
            })),
          ])
          .then(() => {
            setIsConnectorNullFieldsLoaded(true);
          })
        })
        .finally(hideLoadingPanel);
    }

    displayLoadingPanel();
    dispatch(loadConfigByIdAsync(Number(param.configId)))
    .then(unwrapResult)
    .then((res: ConfigDetailResponse)=>{
      loadConnectorForConfig(res);
    })
    .finally(()=>{
      hideLoadingPanel();

      // if we are not getting config from clipboard, we can set isConfigDataLoaded to true
      if (!location.state?.getConfigFromClipboard){
        setIsConfigDataLoaded(true);
      }
    });
    
    return () => {
      dispatch(resetCurrentConfig());
      dispatch(resetSelectedDetailedConnector());
    };
  }, [dispatch, param.configId, location.state?.getConfigFromClipboard]);


  const coreFields = useAppSelector((store) => store.coreFieldData.coreFields);

  const showErrorModal = () => setIsErrorModalVisible(true);
  const hideErrorModal = () => setIsErrorModalVisible(false);

  const { validationErrors } =
    useImportConfigFromClipboard({
      destinationConfig: selectedConfig,
      selectedConnectorDetail,
      isConnectorNullFieldsLoaded,
      coreFields,
      showErrorModal,
      isConfigDataLoaded,
      setIsConfigDataLoaded // we send callbacks to show error modal and update isConfigDataLoaded state
    });

  return (
    <div>
      {selectedConfig && isConfigDataLoaded && (
        <ConfigEditor config={selectedConfig} selectedTab={location.state?.selectedTab ?? 0}/>
      )}
      {isErrorModalVisible && (
        <ErrorDisplayModal errors={validationErrors.errors} count={validationErrors.count} onHideDialog={hideErrorModal} asWarnings={true}/>
      )}
    </div>
  );
}
