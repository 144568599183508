import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import { PromineoTextValidationOptions } from "components/common/controls/PromineoTextBox";
import PageHeader from "components/common/page-header/PageHeader";
import EditIcon from "components/icons/EditIcon";
import IlapTermHeaderData from "interfaces/component-data/IlapTermHeaderData";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SYS_ADMIN_ILAP_TERMS } from "shared/constants/RoutePathConstants";
import { FieldContentControlLevel } from "shared/enums/feature/FieldContentControlLevel";
import AllowBlanksLabel from "./AllowBlanksLabel";
import ContentControlLabel from "./ContentControlLabel";
import DataTypeLabel from "./DataTypeLabel";
import IlapIdLabel from "./IlapIdlabel";
import PlaningObjectTypeLabel from "./PlanningObjectTypeLabel";
import UriLabel from "./UriLabel";

interface Props {
  isHeaderEditable?: boolean;
  isNew?: boolean;
  headerData: IlapTermHeaderData;
  setHeaderData?: (data: any) => void;
  onContentControlChange?: (value: number) => void;
  titleValidationOptions?: PromineoTextValidationOptions;
}

export default function IlapTermHeader(props: Props) {
  const { isHeaderEditable, isNew, headerData, setHeaderData, onContentControlChange, titleValidationOptions } = props;
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false);
  const updatedContentControlValue = useRef<FieldContentControlLevel>(headerData.contentControl);

  const navigate = useNavigate();

  const saveContentControl = useCallback(() => {
    if (onContentControlChange) {
      onContentControlChange(updatedContentControlValue.current);
    }
    setHeaderData?.((prev: any) => {
      return { ...prev!, contentControl: updatedContentControlValue.current };
    });
  }, []);

  const handleEditClick = () => {
    if (props.headerData?.id) {
      navigate(`${SYS_ADMIN_ILAP_TERMS}/${props.headerData.id}/edit`);
    }
  };

  const onDescriptionValueChange = useCallback((event: any) => {
    setHeaderData?.((prev: any) => {
      return { ...prev!, description: event?.event?.currentTarget?.value };
    });
  }, []);

  const onUriValuChange = useCallback((event: any) => {
    setHeaderData?.((prev: any) => {
      return { ...prev!, uri: event?.event?.currentTarget?.value };
    });
  }, []);

  const onPlanningObjectTypeChange = useCallback((data: any) => {
    setHeaderData?.((prev: any) => {
      return { ...prev!, plannigObjectType: data };
    });
  }, []);

  const onContentControlValueSelection = useCallback(
    (value: number) => {
      updatedContentControlValue.current = value;

      if (value === FieldContentControlLevel.TenantOrTemplate) {
        setIsConfirmationDialogVisible(true);
      } else {
        saveContentControl();
      }
    },
    [saveContentControl]
  );

  const onDataTypeChange = useCallback((data: any) => {
    setHeaderData?.((prev: any) => {
      return { ...prev!, dataType: data };
    });
  }, []);

  const OnIlapIdValueChange = useCallback((event: any) => {
    setHeaderData?.((prev: any) => {
      return { ...prev!, ilapId: event?.event?.currentTarget?.value };
    });
  }, []);

  const initialWidgets = useMemo(() => {
    return [
      <PlaningObjectTypeLabel
        isEdit={isHeaderEditable}
        isNew={isNew}
        headerData={headerData}
        onChange={onPlanningObjectTypeChange}
      />,
      <ContentControlLabel
        isEdit={isHeaderEditable}
        isNew={isNew}
        headerData={headerData}
        onChange={onContentControlValueSelection}
      />,
      <DataTypeLabel
        isEdit={isHeaderEditable}
        isNew={isNew}
        headerData={headerData}
        onChange={onDataTypeChange}
      />,
      <>
        {(!!headerData.ilapId || isHeaderEditable) && (
          <IlapIdLabel
            isEdit={isHeaderEditable}
            isNew={isNew}
            headerData={headerData}
            onChange={OnIlapIdValueChange}
          />
        )}
      </>,
      <>
        {headerData.contentControl !== FieldContentControlLevel.TenantOrTemplate &&
          !isHeaderEditable && <AllowBlanksLabel headerData={headerData} />}
      </>,
      <UriLabel
        isEdit={isHeaderEditable}
        isNew={isNew}
        headerData={headerData}
        onChange={onUriValuChange}
      />,
    ];
  }, [
    headerData,
    isHeaderEditable,
    isNew,
    onContentControlChange,
    onDescriptionValueChange,
    onPlanningObjectTypeChange,
    onUriValuChange,
    onDataTypeChange,
    OnIlapIdValueChange,
  ]);

  const onTitleChange = (data: any) => {
    setHeaderData?.((prev: any) => {
      return { ...prev!, name: data.value };
    });
  };

  const [widgets, setWidgets] = useState<any>(initialWidgets);

  useEffect(() => {
    // filter to remove the allow blank label when it is null
    var newWidgets = [...initialWidgets].filter((el) => el.props.children !== false);
    setWidgets(newWidgets);
  }, [initialWidgets, isHeaderEditable, isNew, headerData]);

  const handleConfirmClicked = () => {
    setIsConfirmationDialogVisible(false);
    saveContentControl();
  };

  const handleCancelClicked = () => {
    setIsConfirmationDialogVisible(false);
  };

  return (
    <>
      <PageHeader
        title={headerData.name}
        placeholder={"Type ILAP term name..."}
        titleWidget={
          isHeaderEditable ? (
            <></>
          ) : (
            <div className="flex gap-2 cursor-pointer">
              <div onClick={handleEditClick}>
                <EditIcon />
              </div>
            </div>
          )
        }
        headerWidgets={widgets}
        isEdit={isHeaderEditable}
        titleFieldName="ILAP term name"
        isTitleRequired={true}
        titleValidationOptions={titleValidationOptions}
        onTitleChanged={onTitleChange}
        description={headerData.description}
        displayDescriptionField={true}
        onDescriptionChanged={onDescriptionValueChange}
      />
      {isConfirmationDialogVisible && (
        <PromineoConfirmationDialog
          onConfirm={handleConfirmClicked}
          onCancel={handleCancelClicked}
          content={"Are you sure?"}
          subContent={"By changing to this you can not control content."}
          cancelButtonText="Cancel"
          confirmButtonText="Confirm"
        />
      )}
    </>
  );
}
