import EditButton from "components/common/controls/buttons/EditButton";
import SaveButton from "components/common/controls/buttons/SaveButton";
import LabelWithContent from "components/common/LabelWithContent";
import { useLoadTenantUsers } from "hooks/LoadTenantUserHook";
import UserSimplifiedResponse from "interfaces/response/UserSimplifiedResponse";
import { useState } from "react";
import { getRepresentativeSelectionTooltipText } from "shared/utilities/IEATooltipUtility";
import RepresentativeComponent from "../RepresentativeComponent";

interface RepresentativeCreateConfig {
  linesForSelectedRepresentatives?: number;
}

interface RepresentativeEditConfig {
  allowEdit?: boolean;
  onSavePartnerRepresentative: () => void;
}

interface Props {
  editStatus: "create" | "edit";
  partnerRepresentativeIds: number[];
  createConfig?: RepresentativeCreateConfig;
  editConfig?: RepresentativeEditConfig;
  partnerTenantId: number;
  width?: number;
  onPartnerRepresentativeChange: (representativeIds: number[]) => void;
}

const AddRepresentativeField = (
  props: RepresentativeCreateConfig & {
    tenantUsers: UserSimplifiedResponse[];
    width?: number;
    selectedRepresentativeIds: number[];
    onPartnerRepresentativeChange: (representativeIds: number[]) => void;
  }
) => {
  return (
    <LabelWithContent
      label="Partner representatives"
      content={
        <div className="space-y-2">
          <RepresentativeComponent
            width={300}
            showAddButton={true}
            canRemoveChip={true}
            totalSupportedLines={props.linesForSelectedRepresentatives ?? 2}
            componentId="partner-representatives-dropdown"
            selectedUserIds={props.selectedRepresentativeIds}
            representativeList={props.tenantUsers}
            onSelectedItemsChange={props.onPartnerRepresentativeChange}
          />
        </div>
      }
      tooltipText={getRepresentativeSelectionTooltipText()}
    />
  );
};

const EditRepresentativeField = (
  props: RepresentativeEditConfig & {
    tenantUsers: UserSimplifiedResponse[];
    width?: number;
    selectedRepresentativeIds: number[];
    onPartnerRepresentativeChange: (representativeIds: number[]) => void;
  }
) => {
  const [isPartnerRepresentativeEditable, setIsPartnerRepresentativeEditable] =
    useState(false);

  const handleSavePartnerRepresentativeClick = () => {
    setIsPartnerRepresentativeEditable(false);
    props.onSavePartnerRepresentative();
  };

  const handleEditPartnerRepresentativeClick = () => {
    setIsPartnerRepresentativeEditable(true);
  };

  return (
    <LabelWithContent
      label={
        <div className="flex space-x-2">
          <div>Partner representatives</div>
          <div className="-mt-1">
            {props.allowEdit ? (
              isPartnerRepresentativeEditable ? (
                <SaveButton
                  classNames="w-6 h-6"
                  onSaveClick={handleSavePartnerRepresentativeClick}
                />
              ) : (
                <EditButton
                  classNames="w-6 h-6"
                  onEditClick={handleEditPartnerRepresentativeClick}
                />
              )
            ) : (
              ""
            )}
          </div>
        </div>
      }
      content={
        <RepresentativeComponent
          width={300}
          showAddButton={isPartnerRepresentativeEditable}
          canRemoveChip={isPartnerRepresentativeEditable}
          totalSupportedLines={2}
          componentId="partner-representatives-dropdown"
          selectedUserIds={props.selectedRepresentativeIds}
          representativeList={props.tenantUsers}
          onSelectedItemsChange={props.onPartnerRepresentativeChange}
        />
      }
    />
  );
};

export default function PartnerRepresentativeField(props: Props) {
  const tenantUsers = useLoadTenantUsers(props.partnerTenantId);

  return props.editStatus === "create" && props.editConfig?.allowEdit ? (
    <AddRepresentativeField
      linesForSelectedRepresentatives={
        props.createConfig?.linesForSelectedRepresentatives
      }
      width={props.width}
      tenantUsers={tenantUsers}
      selectedRepresentativeIds={props.partnerRepresentativeIds}
      onPartnerRepresentativeChange={props.onPartnerRepresentativeChange}
    />
  ) : props.editConfig ? (
    <EditRepresentativeField
      {...props.editConfig}
      tenantUsers={tenantUsers}
      width={props.width}
      selectedRepresentativeIds={props.partnerRepresentativeIds}
      onPartnerRepresentativeChange={props.onPartnerRepresentativeChange}
    />
  ) : (
    <></>
  );
}
