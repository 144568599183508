import { EventLogSeverity } from "shared/enums/feature/EventLogSeverity";
import { getFormattedDateTime } from "shared/utilities/DateTimeUtility";

interface Props {
  logTime: string;
  logLevel: EventLogSeverity;
  logMessage: string;
}

export default function DetailsLog(props: Props) {
  const { logTime, logLevel, logMessage } = props;
  const fontClasses = "font-medium text-10px text-textGray";

  let lightBackgroundColor = "bg-lightOrange";
  let lighterBackgroundColor = "bg-lighterOrange";

  if (logLevel === EventLogSeverity.Error) {
    lightBackgroundColor = "bg-lightRed";
    lighterBackgroundColor = "bg-lighterRed";
  } else if (logLevel === EventLogSeverity.Information) {
    lightBackgroundColor = "bg-ilapLightBlue";
    lighterBackgroundColor = "bg-lighterBlue";
  }

  return (
    <div className="flex ml-4 mt-2.5">
      <div className={`w-2/12 mt-0.5 font-inter leading-12px ${fontClasses}`}>
        {getFormattedDateTime(logTime)}
      </div>
      <div className="ml-3 flex w-10/12">
        <div
          className={`w-1 flex-shrink-0 rounded-l-sm ${lightBackgroundColor} `}
        ></div>
        <div className={`${lighterBackgroundColor} rounded-r-sm`}>
          <div
            className={`pl-2 mt-0.5 font-poppins leading-15px pr-2 break-all ${fontClasses}`}
          >
            {logMessage}
          </div>
        </div>
      </div>
    </div>
  );
}
