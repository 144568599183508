import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PromineoViewGrid, {
  RowOperationConfig,
} from "components/common/grid/PromineoViewGrid";
import CrossIcon from "components/icons/CrossIcon";
import PromineoModal from "components/modal/PromineoModal";
import { Column, Scrolling } from "devextreme-react/data-grid";
import TenantAdminFieldResponse from "interfaces/response/tenant/TenantAdminFieldResponse";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TEMPLATES,
  TENANT_ADMIN_FIELDS,
} from "shared/constants/RoutePathConstants";
import { openInNewTab } from "shared/utilities/CommonUtility";
import { getTemplatesUsingFieldForTenantAdminAsync } from "store/actions/TenantAdminActions";
import { useAppDispatch, useAppSelector } from "store/hooks";

export default function NumberOfTemplates(props: {
  data: TenantAdminFieldResponse;
}) {
  const field = props.data;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);

  const templatesUsingField = useAppSelector(
    (store) => store.tenantAdminData.templatesUsingField
  );

  const handleClick = () => {
    if (templatesUsingField && templatesUsingField.fieldId === field.id) {
      setIsDialogVisible(true);
    } else {
      displayLoadingPanel();
      dispatch(getTemplatesUsingFieldForTenantAdminAsync(field.id))
        .then(() => {
          setIsDialogVisible(true);
        })
        .finally(hideLoadingPanel);
    }
  };

  const handleOnClickOpenTemplate = (data: any) => {
    navigate(`${TEMPLATES}/${data.data.templateId}`);
  };

  const handleOnClickOpenTemplateInNewTab = (data: any) => {
    var path = `${TEMPLATES}/${data.data.templateId}`;
    openInNewTab(path);
  };

  const handleOnClickOpenField = () => {
    navigate(`${TENANT_ADMIN_FIELDS}/${props.data.id}`);
  };

  const handleOnClickOpenFieldInNewTab = () => {
    var path = `${TENANT_ADMIN_FIELDS}/${props.data.id}`;
    openInNewTab(path);
  };

  const rowOperationConfig = useMemo<RowOperationConfig>(() => {
    return {
      visible: true,
      items: [
        {
          text: "Open template",
          onClick: handleOnClickOpenTemplate,
          visible: true,
        },
        {
          text: "Open template in new tab",
          onClick: handleOnClickOpenTemplateInNewTab,
          visible: true,
        },
        {
          text: "Open field",
          onClick: handleOnClickOpenField,
          visible: true,
        },
        {
          text: "Open field in new tab",
          onClick: handleOnClickOpenFieldInNewTab,
          visible: true,
        },
      ],
    };
  }, []);

  return (
    <>
      <div className="underline cursor-pointer" onClick={handleClick}>
        {field.templateUsageCount}
      </div>
      <PromineoModal
        width={720}
        height={640}
        isVisible={isDialogVisible}
        onHiding={() => setIsDialogVisible(false)}
      >
        <div>
          <div className="mb-2 flex justify-between">
            <div className="font-bold font-[12px]">{field.name}</div>
            <div
              onClick={() => setIsDialogVisible(false)}
              className="cursor-pointer"
            >
              <CrossIcon />
            </div>
          </div>
          <div className="text-[12px] font-medium font-inter">
            Content control: {field.contentControlText}
          </div>

          <div className="mt-2">
            <PromineoViewGrid
              height={540}
              dataSource={templatesUsingField.templates}
              rowOperationConfig={rowOperationConfig}
            >
              <Scrolling rowRenderingMode={"virtual"} mode={"virtual"} />
              <Column caption={"Template"} dataField="templateName" />
            </PromineoViewGrid>
          </div>
        </div>
      </PromineoModal>
    </>
  );
}
