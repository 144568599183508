import TenantInfoSectionTitle from "./TenantInfoSectionTitle";
import LabelWithContent from "components/common/LabelWithContent";
import TenantFieldSection from "./TenantFieldSection";
import TenantContactFieldViewOrEdit from "./TenantContactFieldViewOrEdit";
import TenantContactInformationBase from "interfaces/common/TenantContactInformationBase";
import OwnTenantResponse from "interfaces/response/tenant/OwnTenantResponse";

interface Props {
  tenantDetail: OwnTenantResponse;
  isEdit: boolean;
  onChange: (tenantInfo: OwnTenantResponse) => void;
}

export default function TenantContactViewEdit(props: Props) {
  const handleValueChanged = (
    index: number,
    data: TenantContactInformationBase
  ) => {
    const updatedContacts = props.tenantDetail.contactInformation.map(
      (item: TenantContactInformationBase, ind: number) => {
        if (index == ind) {
          return data;
        }
        return item;
      }
    );
    props.onChange({
      ...props.tenantDetail,
      contactInformation: updatedContacts,
    });
  };

  const checkIfShouldDisplayContactField = (index: number) => {
    if (props.isEdit) {
      return true;
    }

    return (
      props.tenantDetail.contactInformation &&
      props.tenantDetail.contactInformation.length &&
      props.tenantDetail.contactInformation[index] &&
      props.tenantDetail.contactInformation[index].id
    );
  };

  return (
    <div className="flex space-x-2">
      <TenantInfoSectionTitle title="Contact Info" />
      <div className="space-y-5">
        <div className="flex justify-between">
          <TenantFieldSection>
            <LabelWithContent
              label="Main contact"
              addAsterisksymbol={props.isEdit}
              content={
                <TenantContactFieldViewOrEdit
                  contactDetail={props.tenantDetail.contactInformation[0]}
                  isEdit={props.isEdit}
                  onChange={(contactInfo: TenantContactInformationBase) =>
                    handleValueChanged(0, contactInfo)
                  }
                />
              }
            />
          </TenantFieldSection>
          {checkIfShouldDisplayContactField(1) ? (
            <TenantFieldSection>
              <LabelWithContent
                label="Additional contact 1"
                content={
                  <TenantContactFieldViewOrEdit
                    contactDetail={props.tenantDetail.contactInformation[1]}
                    isEdit={props.isEdit}
                    onChange={(contactInfo: TenantContactInformationBase) =>
                      handleValueChanged(1, contactInfo)
                    }
                  />
                }
              />
            </TenantFieldSection>
          ) : null}
          {checkIfShouldDisplayContactField(2) ? (
            <TenantFieldSection>
              <LabelWithContent
                label="Additional contact 2"
                content={
                  <TenantContactFieldViewOrEdit
                    contactDetail={props.tenantDetail.contactInformation[2]}
                    isEdit={props.isEdit}
                    onChange={(contactInfo: TenantContactInformationBase) =>
                      handleValueChanged(2, contactInfo)
                    }
                  />
                }
              />
            </TenantFieldSection>
          ) : null}
        </div>
        <div className="flex justify-between">
          {checkIfShouldDisplayContactField(3) ? (
            <TenantFieldSection>
              <LabelWithContent
                label="Additional contact 3"
                content={
                  <TenantContactFieldViewOrEdit
                    contactDetail={props.tenantDetail.contactInformation[3]}
                    isEdit={props.isEdit}
                    onChange={(contactInfo: TenantContactInformationBase) =>
                      handleValueChanged(3, contactInfo)
                    }
                  />
                }
              />
            </TenantFieldSection>
          ) : null}
        </div>
      </div>
    </div>
  );
}
