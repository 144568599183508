import LabelHolder from "components/common/LabelHolder";
import Breadcrumb, {
  BreadcrumbItem,
} from "components/common/breadcrumb/Breadcrumb";
import PageHeader from "components/common/page-header/PageHeader";
import TemplateHeaderData from "interfaces/component-data/TemplateHeaderData";
import LabelResponse from "interfaces/response/LabelResponse";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TEMPLATES } from "shared/constants/RoutePathConstants";
import { SupportedLabelEntity } from "shared/enums/feature/SupportedLabelEntity";
import { getFormattedDateTimeFromUtcDateObject } from "shared/utilities/DateTimeUtility";

interface Props {
  data: TemplateHeaderData;
  onDeleteLabel: (label: LabelResponse) => void;
  onTitleChanged: (value: any) => void;
  onDescriptionChanged: (value: any) => void;
  onAddNewLabel: (label: LabelResponse) => void;
  copyOrNew?: boolean;
}

export default function EditTemplateHeader(props: Props) {
  let navigate = useNavigate();

  const initialWidgets = [
    <>ID: {props.data.id}</>,
    <LabelHolder
      labels={props.data.labels}
      labelType={SupportedLabelEntity.Template}
      allowDelete={true}
      allowAdd={true}
      onDelete={props.onDeleteLabel}
      onAddLabel={props.onAddNewLabel}
    />,
    <># of fields: {props.data.fieldCount}</>,
  ];

  const [widgets, setWidgets] = useState<any>(initialWidgets);

  useEffect(() => {
    if (!props.copyOrNew) {
      setWidgets(() => {
        return [
          ...initialWidgets,
          <># tenant configs using template: {props.data.ownConfigsCount}</>,
          <>Shared with: {props.data.shareCount}</>,
          <>Created by: {props.data?.createdBy}</>,
          <>Created on: {getFormattedDateTimeFromUtcDateObject(props.data?.createdOn)}</>,
        ];
      });
    } else {
      setWidgets(initialWidgets);
    }
  }, [props.copyOrNew, props.data.labels]);

  const breadCrumbsItems: BreadcrumbItem[] = [
    {
      key: "Templates",
      text: "Templates",
    },
    {
      key: "metadata-create-view-update",
      text: `${props.data ? props.data.title : " "}`,
    },
  ];

  return (
    <div>
      <Breadcrumb
        items={breadCrumbsItems}
        onSelectionChange={(index, selectedItem) => {
          if (selectedItem.key === "Templates") navigate(TEMPLATES);
        }}
      />
      <div className="mt-1">
        <PageHeader
          title={props.data.title}
          description={props.data.description}
          placeholder={"Type template title..."}
          onTitleChanged={props.onTitleChanged}
          headerWidgets={widgets}
          isEdit={true}
          displayDescriptionField={true}
          onDescriptionChanged={props.onDescriptionChanged}
        />
      </div>
    </div>
  );
}
