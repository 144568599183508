import { unwrapResult } from "@reduxjs/toolkit";
import PromineoCancelEditingConfirmationDialog from "components/common/controls/PromineoCancelEditingConfirmationDialog";
import { PromineoTextValidationOptions } from "components/common/controls/PromineoTextBox";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import EditFieldValueGrid from "features/common/edit-field-value-grid/EditFieldValueGrid";
import ErrorDisplayModal from "features/common/error-display-modal/ErrorDisplayModal";
import SaveOrCancelFooter from "features/common/SaveOrCancelFooter";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";
import FieldValue from "interfaces/common/FieldValue";
import IlapTermHeaderData from "interfaces/component-data/IlapTermHeaderData";
import FieldCreateRequest from "interfaces/request/FieldCreateRequest";
import FieldManageRequest from "interfaces/request/FieldManageRequest";
import FieldUpdateRequest from "interfaces/request/FieldUpdateRequest";
import FieldValueWriteRequest from "interfaces/request/FieldValueWriteRequest";
import FieldDetailsResponse from "interfaces/response/FieldDetailsResponse";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SYS_ADMIN_ILAP_TERMS } from "shared/constants/RoutePathConstants";
import { FieldContentControlLevel } from "shared/enums/feature/FieldContentControlLevel";
import { PlanningObjectTypes } from "shared/enums/feature/PlanningObjectTypes";
import { TypeCode } from "shared/enums/TypeCode";
import { GetNewIlapId } from "shared/utilities/CommonUtility";
import { validateFieldValues } from "shared/utilities/FieldValueUtility";
import { validateIlapTermName } from "shared/utilities/IlapTermUtility";
import { toastError, toastSuccess } from "shared/utilities/ToastUtility";
import { loadFieldContentControlLevelAsync } from "store/actions/DropdownValueActions";
import {
  createFieldsForSystemAdmin,
  getFieldForSystemAdminAsync,
  updateIlapTermBySystemAdminAsync,
} from "store/actions/SystemAdminActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import IlapTermHeader from "./ilap-term-header/IlapTermHeader";

export default function IlapTermEditor() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isEdit = params.fieldId ? true : false;

  const ilapTermNameSurroundingSpacesValidationPattern = "^\\S.*\\S$|^\\S?$";
  const ilapTermNameSurroundingSpaceErrorMessage = "An ILAP Term cannot start or end with a space. It must start with a letter and only contain \"a-Z\", \"0-9\", and \"_\"";

  const ilapTermNameValidationPattern = "^[a-zA-Z][a-zA-Z0-9_]*$";
  const ilapTermValidationErrorMessage = "ILAP Term names must start with a letter and only contain \"a-Z\", \"0-9\", and \"_\"";
  
  const ilapTermNameValidationOptions: PromineoTextValidationOptions = {
    patternRules: [
      {
        pattern: ilapTermNameSurroundingSpacesValidationPattern,
        message: ilapTermNameSurroundingSpaceErrorMessage,
      },
      {
        pattern: ilapTermNameValidationPattern,
        message: ilapTermValidationErrorMessage,
      }
    ],
  };

  const [isModified, setIsModified] = useState<boolean>(false);
  const [
    isUnsavedChangeConfirmationVisible,
    setIsUnsavedChangeConfirmationVisible,
  ] = useState(false);

  const [allowContentControl, setAllowContentControl] = useState(false);
  const [allowBlanks, setAllowBlanks] = useState(true);
  const [fieldValueDataSource, setFieldValueDataSource] = useState<
    FieldValue[]
  >([]);

  const [headerData, setHeaderData] = useState<IlapTermHeaderData>({
    name: "",
    description: "",
    plannigObjectType: PlanningObjectTypes.Activity,
    plannigObjectTypeText: "Activity",
    uri: "",
    allowBlank: true,
    contentControl: FieldContentControlLevel.TenantOrTemplate,
    contentControlText: "Tenant/Template",
    dataType: TypeCode.String,
    dataTypeText: "String",
    ilapId: GetNewIlapId(),
  });

  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (isEdit) {
      const id = Number(params.fieldId);
      displayLoadingPanel();
      dispatch(getFieldForSystemAdminAsync(id)).finally(hideLoadingPanel);
    }
  }, []);

  useEffect(() => {
    // content control field options are required in dropdowns inside header.
    displayLoadingPanel();
    dispatch(loadFieldContentControlLevelAsync()).finally(hideLoadingPanel);
  }, []);

  const ilapTermToEdit = useAppSelector((state) => state.systemAdminData.field);

  useEffect(() => {
    const setHeader = () => {
      if (ilapTermToEdit) {
        setHeaderData({
          id: ilapTermToEdit.id,
          name: ilapTermToEdit.name?.trim(),
          description: ilapTermToEdit.description?.trim(),
          contentControl: ilapTermToEdit.controlledOn,
          contentControlText: ilapTermToEdit.controlledOnText,
          plannigObjectType: ilapTermToEdit.planningObjectType,
          plannigObjectTypeText: ilapTermToEdit.planningObjectTypeText,
          uri: ilapTermToEdit.uri?.trim(),
          allowBlank: ilapTermToEdit.allowBlank ?? undefined,
          hasValues: ilapTermToEdit.valueCount > 0,
          dataType: ilapTermToEdit.dataType,
          dataTypeText: ilapTermToEdit.dataTypeText,
          ilapId: ilapTermToEdit.ilapId?.trim(),
        });
      }
    };

    const setIlapTermDataToEdit = () => {
      if (isEdit && ilapTermToEdit) {
        const fetchedIlapTermValue: FieldValue[] = ilapTermToEdit.values.map(
          (fd) => {
            return {
              valueCode: fd.code,
              description: fd.description,
              id: fd.id,
              __KEY__: fd._key_,
            } as FieldValue;
          }
        );
        setAllowBlanks(ilapTermToEdit.allowBlank ?? false);
        setAllowContentControl(ilapTermToEdit.allowContentControl ?? false);
        setFieldValueDataSource(fetchedIlapTermValue);
        setHeader();
      }
    };

    setIlapTermDataToEdit();
  }, [ilapTermToEdit]);

  const validateHeaderData = (headerData: IlapTermHeaderData) => {
    const errors = [];

    if (headerData.name.trim().length === 0) {
      errors.push("ILAP Term name is required.");
    }
    else if (headerData.name.trim() !== headerData.name) { // validate leading and trailing spaces
      errors.push(`${ilapTermNameSurroundingSpaceErrorMessage}.`);
    }
    else if (!validateIlapTermName(headerData.name)) { // validate a-z, A-Z, 0-9, _
      errors.push(`${ilapTermValidationErrorMessage}.`);
    }

    if (headerData.uri.trim().length === 0) {
      errors.push("URI field is required.");
    }

    if (errors.length > 0) {
      toastError(errors.join("\n\n"));
      return false;
    }

    return true;
  };

  const handleCreateNewIlapTerm = (fieldValues: FieldValueWriteRequest[]) => {
    if (!validateHeaderData(headerData)){
      return;
    }

    const fieldManageRequest: FieldManageRequest = {
      allowContentControl:
        headerData.contentControl === FieldContentControlLevel.System
          ? allowContentControl
          : false,
      allowBlank:
        headerData.contentControl === FieldContentControlLevel.System &&
        allowContentControl
          ? allowBlanks
          : false,
      values:
        headerData.contentControl === FieldContentControlLevel.System &&
        allowContentControl
          ? fieldValues
          : [],
    };

    const fieldToCreate: FieldCreateRequest = {
      name: headerData.name,
      code: headerData.name,
      dataType: headerData.dataType,
      description: headerData.description,
      planningObjectTypes: headerData.plannigObjectType,
      controlledOn: headerData.contentControl,
      uri: headerData.uri,
      systemFieldManageDto:
        headerData.contentControl === FieldContentControlLevel.System
          ? fieldManageRequest
          : null,
      ilapId: headerData.ilapId,
    };

    displayLoadingPanel();
    dispatch(createFieldsForSystemAdmin(fieldToCreate))
      .then(unwrapResult)
      .then((createdFieldId: number) => {
        toastSuccess("Created successfully.");
        navigate(`${SYS_ADMIN_ILAP_TERMS}/${createdFieldId}`);
      })
      .finally(hideLoadingPanel);
  };

  const handleUpdateIlapTerm = (fieldValues: FieldValueWriteRequest[]) => {
    if (!validateHeaderData(headerData)){
      return;
    }

    if (ilapTermToEdit) {
      const fieldManageRequest: FieldManageRequest = {
        allowContentControl:
          headerData.contentControl === FieldContentControlLevel.System
            ? allowContentControl
            : false,
        allowBlank:
          headerData.contentControl === FieldContentControlLevel.System &&
          allowContentControl
            ? allowBlanks
            : false,
        values:
          headerData.contentControl === FieldContentControlLevel.System &&
          allowContentControl
            ? fieldValues
            : [],
      };

      const fieldUpdateRequest: FieldUpdateRequest = {
        name: headerData.name,
        code: headerData.name,
        description: headerData.description,
        planningObjectTypes: headerData.plannigObjectType,
        controlledOn: headerData.contentControl,
        dataType: headerData.dataType,
        ilapId: headerData.ilapId,
        uri: headerData.uri,
        systemFieldManageDto:
          headerData.contentControl === FieldContentControlLevel.System
            ? fieldManageRequest
            : null,
      };

      displayLoadingPanel();
      dispatch(
        updateIlapTermBySystemAdminAsync({
          fieldId: ilapTermToEdit.id,
          request: fieldUpdateRequest,
        })
      )
        .then(unwrapResult)
        .then((response: FieldDetailsResponse) => {
          toastSuccess("Updated successfully.");
          navigate(`${SYS_ADMIN_ILAP_TERMS}/${response.id}`);
        })
        .finally(hideLoadingPanel);
    }
  };

  const handleSaveChangesClick = () => {
    // Content control is not allowed for FieldContentControlLevel.TenantOrTemplate
    if (
      headerData.contentControl === FieldContentControlLevel.System &&
      allowContentControl &&
      fieldValueDataSource.length < 2
    ) {
      toastError("Atleast 2 values are required.");
      return;
    }

    const result = validateFieldValues(fieldValueDataSource);

    if (!result.isValidationSuccessful) {
      setValidationErrors(result.errors);
      showErrorModal();
      return;
    }

    var fieldValues: FieldValueWriteRequest[] = [];
    fieldValueDataSource.forEach((f) => {
      fieldValues.push({
        id: f.id ? f.id : 0,
        code: f.valueCode,
        description: f.description ? f.description : "",
      });
    });

    if (isEdit) {
      handleUpdateIlapTerm(fieldValues);
    } else {
      handleCreateNewIlapTerm(fieldValues);
    }
  };

  const handleContentControlChange = (value: number) => {
    if (value === FieldContentControlLevel.TenantOrTemplate) {
      setFieldValueDataSource([]);
      setIsModified(true);
    }
  };

  const headerDivId: string = "ilap-term-editor-header";
  const footerDivId: string = "ilap-term-editor-footer";
  const excludedContainerIds: string[] = [headerDivId, footerDivId];

  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 140,
  });

  const handleHeaderDataChange = (value: any) => {
    setHeaderData(value);
    setIsModified(true);
  };

  const handleFieldValueDatasourceChange = (value: any) => {
    setFieldValueDataSource(value);
    setIsModified(true);
  };

  const handleAllowContentControlChange = (value: boolean) => {
    setAllowContentControl(value);
    setIsModified(true);
  };

  const handleAllowBlankChange = (value: boolean) => {
    setAllowBlanks(value);
    setIsModified(true);
  };

  const handleRowUpdated = () => {
    setIsModified(true);
  };

  const navigateForCancellation = () => {
    navigate(-1);
  };

  const handleCancelClick = () => {
    if (isModified) {
      setIsUnsavedChangeConfirmationVisible(true);
    } else {
      navigateForCancellation();
    }
  };

  const handleConfirmCancel = () => {
    setIsUnsavedChangeConfirmationVisible(false);
    navigateForCancellation();
  };

  const handleCancelConfirmationDialog = () => {
    setIsUnsavedChangeConfirmationVisible(false);
  };

  const showErrorModal = () => setIsErrorModalVisible(true);
  const hideErrorModal = () => setIsErrorModalVisible(false);

  // In edit screen, show data only when header data is set
  if (isEdit && !headerData.id) {
    return <></>;
  }

  return (
    <>
      <div id={headerDivId}>
        <IlapTermHeader
          isHeaderEditable={true}
          isNew={!isEdit}
          headerData={headerData}
          setHeaderData={handleHeaderDataChange}
          onContentControlChange={handleContentControlChange}
          titleValidationOptions={ilapTermNameValidationOptions}
        />
      </div>

      <div className="w-560px h-fit ml-auto mr-auto">
        <EditFieldValueGrid
          height={gridHeight}
          allowContentControl={allowContentControl}
          setAllowContentControl={handleAllowContentControlChange}
          allowBlanks={allowBlanks}
          setAllowBlanks={handleAllowBlankChange}
          fieldValueDataSource={fieldValueDataSource}
          setFieldValueDataSource={handleFieldValueDatasourceChange}
          disableContentControl={
            headerData.contentControl !== FieldContentControlLevel.System
          }
          onRowUpdated={handleRowUpdated}
        />
      </div>
      <div id={footerDivId}>
        <SaveOrCancelFooter
          saveButtonText={isEdit ? "Save Changes" : "Create"}
          onCancel={handleCancelClick}
          onSave={handleSaveChangesClick}
        />
      </div>
      {isUnsavedChangeConfirmationVisible ? (
        <PromineoCancelEditingConfirmationDialog
          onConfirm={handleConfirmCancel}
          onCancel={handleCancelConfirmationDialog}
        ></PromineoCancelEditingConfirmationDialog>
      ) : null}
      {isErrorModalVisible && <ErrorDisplayModal errors={validationErrors} onHideDialog={hideErrorModal} />}
    </>
  );
}
