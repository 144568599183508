import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "react-toastify/dist/ReactToastify.css";
import Main from "Main";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "./styles/Constants.css";
import "./styles/DevextremeOverrides.css";
import LoadingPanel from "components/common/LoadingPanel";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import GlobalErrorHandlerDialogs from "components/GlobalErrorHandlerDialogs";

interface Props {
  msalInstance: IPublicClientApplication;
}

function App(props: Props) {
  return (    
    <MsalProvider instance={props.msalInstance}>
      <div className="App">        
        <div className="m-auto">
            <BrowserRouter>
            <GlobalErrorHandlerDialogs />
            <Main />                  
            </BrowserRouter>    
          <ToastContainer position="bottom-left" autoClose={6000} />
          <LoadingPanel />          
        </div>
      </div>
    </MsalProvider>
  );
}

export default App;
