import PromineoTextBox from "components/common/controls/PromineoTextBox";
import { useCallback } from "react";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import ConnectorLabelWithContent from "../../ConnectorLabelWithContent";

interface Props {
  containerEndpoint?: string;
  mode: PromineoModalMode;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  validateOnFocusOut?: (event: any) => void;
}

export default function FileStorageManagedIdentityAuthenticationComponent(
  props: Props
) {
  const {
    containerEndpoint,
    mode,
    handleConfigurationValueChanged,
    validateOnFocusOut,
  } = props;

  const RenderContainerEndpointComponent = useCallback(() => {
    return (
      <>
        {(mode === PromineoModalMode.Create ||
          mode === PromineoModalMode.Modify) && (
          <PromineoTextBox
            width={mode === PromineoModalMode.Create ? 330 : 188}
            name="containerEndpoint"
            fieldName="Container Endpoint"
            required={true}
            defaultValue={containerEndpoint}
            placeholder="Type..."
            onChange={({ event }: any) => {
              if (handleConfigurationValueChanged) {
                handleConfigurationValueChanged(
                  event.currentTarget.name,
                  event.currentTarget.value
                );
              }
            }}
            onFocusOut={(event: any) => {
              if (validateOnFocusOut) {
                validateOnFocusOut(event);
              }
            }}
          />
        )}

        {mode === PromineoModalMode.View && containerEndpoint}
      </>
    );
  }, [mode, containerEndpoint]);

  return (
    <div
      className={
        props.mode === PromineoModalMode.View ||
        props.mode === PromineoModalMode.Modify
          ? "grid grid-cols-2 gap-x-60"
          : ""
      }
    >
      <ConnectorLabelWithContent
        mode={mode}
        addAsterisksymbol={true}
        label="Container Endpoint"
        content={<RenderContainerEndpointComponent />}
      />
    </div>
  );
}
