import ConfigResponse from "interfaces/response/ConfigResponse";
import ConnectorScheduleSimplifiedResponse from "interfaces/response/ConnectorScheduleSimplifiedResponse";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import ConnectorBaseResponse from "interfaces/response/connector/ConnectorBaseResponse";
import { NewExchangeAgreementActionRequired } from "shared/enums/feature/NewExchangeAgreementActionRequired";
import IEAContentBodyWithTitle from "./IEAContentBodyWithTitle";
import PartnerConfigurationDetail from "./PartnerConfigurationDetail";
import ConfigurePartnerSettingsPlaceholder from "./partner-information/ConfigurePartnerSettingsPlaceholder";

export interface PartnerConfigurationDetailProps {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  isCurrentUserPartner: boolean;
  partnerConfigSettings?: ConfigResponse | null;
  selectedPartnerConnectorId?: number;
  selectedPartnerScheduleId?: number;
  handlePartnerRepresentativeChange: (representativeIds: number[]) => void;
  handlePartnerConnectorChange?: (connector: ConnectorBaseResponse) => void;
  handlePartnerScheduleChange?: (
    selectedItem: ConnectorScheduleSimplifiedResponse
  ) => void;
  handleSavePartnerRepresentativeClick?: () => void;
  isActiveIeaEdit?: boolean;
  allowEdit?: boolean;
  isPartnerRoleSender: boolean;
}

export default function PartnerConfiguration(
  props: PartnerConfigurationDetailProps
) {
  const isPartnerAndNeedToCompleteIea =
    props.exchangeAgreement.actionRequiredIfInactive ===
    NewExchangeAgreementActionRequired.Complete;

  return (
    <IEAContentBodyWithTitle
      title="Partner configuration"
      bodyHeight="h-auto"
      content={
        isPartnerAndNeedToCompleteIea ? (
          <div className="py-[30px]">
            <ConfigurePartnerSettingsPlaceholder
              isPartnerRoleSender={props.isPartnerRoleSender}
              exchangeAgreement={props.exchangeAgreement}
            />
          </div>
        ) : (
          <PartnerConfigurationDetail {...props} />
        )
      }
    />
  );
}
