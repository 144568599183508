export const getContentControlText = (
  allowContentControl?: boolean | null,
  allowBlank?: boolean | null
) => {
  if (allowContentControl === null || allowContentControl === undefined) {
    return "-";
  }

  let displayText = allowContentControl ? "Yes" : "No";
  if (allowBlank) {
    const alowBlankDisplay = "allow blanks";
    displayText = `${displayText}, ${alowBlankDisplay}`;
  }

  return displayText;
};

export const getContentConrolInformation = (contentControlInfo: {
  contentControlText?: string;
  controlledOnText?: string;
  allowBlank: boolean;
  allowContentControl: boolean;
}) => {
  const contentControlText =
    contentControlInfo.contentControlText ??
    contentControlInfo.controlledOnText ?? "";
  const allowContentControlText = contentControlInfo.allowContentControl
    ? "active"
    : "not active";
  const allowBlankText = contentControlInfo.allowBlank
    ? "allow blanks"
    : "no blanks";
  const displayText = `${contentControlText} (${allowContentControlText}${contentControlInfo.allowContentControl ? `, ${allowBlankText}`: ""})`;
  return displayText;
};
