import { unwrapResult } from "@reduxjs/toolkit";
import { toastError } from "shared/utilities/ToastUtility";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import {
  getAnalyticsConnectorDefaultConfiguration,
  getConnectorConfigurationBasedOnHostSystem,
  getFileStorageConnectorDefaultConfiguration,
  getPrimaveraConnectorDefaultConfiguration,
  getSAPConnectorDefaultConfiguration,
  getSafranConnectorDefaultConfiguration,
  isConnectorInputValid,
} from "shared/utilities/ConnectorUtility";
import ConnectorModifyRequest from "interfaces/request/ConnectorModifyRequest";
import AnalyticsConnectorConfiguration from "interfaces/response/connector/AnalyticsConnectorConfiguration";
import ConnectorResponse from "interfaces/response/connector/ConnectorResponse";
import MicrosoftProjectConnectorConfiguration from "interfaces/response/connector/MicrosoftProjectConnectorConfiguration";
import PrimaveraConnectorConfiguration from "interfaces/response/connector/PrimaveraConnectorConfiguration";
import SafranApiConnectorConfigurationResponse from "interfaces/response/connector/SafranApiConnectorConfigurationResponse";
import SAPConnectorConfiguration from "interfaces/response/connector/SAPConnectorConfiguration";
import LabelResponse from "interfaces/response/LabelResponse";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CONNECTORS } from "shared/constants/RoutePathConstants";
import { AnalyticsConnectorAuthenticationMode } from "shared/enums/feature/AnalyticsConnectorAuthenticationMode";
import { HostSystem } from "shared/enums/feature/HostSystem";
import { PrimaveraConnectorAuthenticationMode } from "shared/enums/feature/PrimaveraConnectorAuthenticationMode";
import { SafranApiConnectorAuthenticationMode } from "shared/enums/feature/safran-host-system/SafranApiConnectorAuthenticationMode";
import { SapConnectorAuthenticationMode } from "shared/enums/feature/sap-host-system/SapConnectorAuthenticationMode";
import { ExecutionComponent } from "shared/enums/feature/ExecutionComponent";
import {
  resetConnectorHostFields,
  resetConnectorHostSchedules,
  resetSelectedDetailedConnector,
} from "store/slices/ConnectorSlice";
import FileStorageConnectorConfiguration from "interfaces/response/connector/FileStorageConnectorConfiguration";
import { FileStorageConnectorAuthenticationMode } from "shared/enums/feature/file-storge-host-system/FileStorageConnectorAuthenticationMode";
import { AppDispatch } from "store/store";
import {
  loadConnectorAsync,
  modifyConnectorAsync,
  removeConnectorAsync,
} from "store/actions/ConnectorActions";
import { ValidationErrorType } from "shared/enums/ValidationErrorType";
import ConnectorViewerOrEditor from "./ConnectorViewerOrEditor";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import ConnectorDeleteValidationDialog from "./ConnectorDeleteValidationDialog";
import PromineoCancelEditingConfirmationDialog from "components/common/controls/PromineoCancelEditingConfirmationDialog";

export default function ConnectorEditor() {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [isModified, setIsModified] = useState<boolean>(false);
  const [
    isUnsavedChangeConfirmationVisible,
    setIsUnsavedChangeConfirmationVisible,
  ] = useState(false);
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] =
    useState<boolean>(false);
  const [selectedConnector, setSelectedConnector] =
    useState<ConnectorResponse>();
  const [connectorConfiguration, setConnectorConfiguration] = useState<
    | SafranApiConnectorConfigurationResponse
    | AnalyticsConnectorConfiguration
    | SAPConnectorConfiguration
    | PrimaveraConnectorConfiguration
    | MicrosoftProjectConnectorConfiguration
    | FileStorageConnectorConfiguration
  >();

  const shouldResetAuthenticationDependentValues = useRef(false);
  const shouldResetHostSystemDependentValues = useRef(false);
  const [validationMessageForDelete, setValidationMessageForDelete] = useState<
    string[]
  >([]);

  const [isInputValid, setIsInputValid] = useState<boolean>(false);
  const [
    isDeleteConfirmationDialogVisible,
    setIsDeleteConfirmationDialogVisible,
  ] = useState<boolean>(false);

  const handleConnectorValueChanged = (fieldName: string, value: any) => {
    if (selectedConnector) {
      if (fieldName === "executionComponent") {
        resetConnectorAuthenticationMode();
      }

      setSelectedConnector((previousConnector) => ({
        ...previousConnector!,
        [fieldName]: value,
      }));

      setIsModified(true);
    }
  };

  const handleDeleteLabel = (label: LabelResponse) => {
    setSelectedConnector((prev) => {
      let filteredLabels = prev!.labels.filter(
        (l) => l.id !== label.id || l._key_ !== label._key_
      );

      return { ...prev!, labels: filteredLabels };
    });

    setIsModified(true);
  };

  const handleAddNewLabel = (label: LabelResponse) => {
    setSelectedConnector((prev) => {
      return { ...prev!, labels: [...prev!.labels, label] };
    });

    setIsModified(true);
  };

  const handleHostSystemValueChange = (value: number) => {
    setSelectedConnector((previousConnector) => ({
      ...previousConnector!,
      hostSystem: value,
      executionComponent: -1 as ExecutionComponent,
    }));

    setIsModified(true);
    shouldResetHostSystemDependentValues.current = true;
  };

  const handleConnectorConfigurationValueChanged = (
    fieldName: string,
    value: any
  ) => {
    setConnectorConfiguration((previousConfiguration) => ({
      ...previousConfiguration!,
      [fieldName]: value,
    }));

    setIsModified(true);
  };

  const handleAuthenticationModeValueChanged = (value: any) => {
    setConnectorConfiguration((previousConfiguration) => ({
      ...previousConfiguration!,
      authenticationMode: value,
    }));

    setIsModified(true);
    shouldResetAuthenticationDependentValues.current = true;
  };

  useEffect(() => {
    let connectorToLoadId = 0;
    if (params.id && !isNaN(Number(params.id))) {
      connectorToLoadId = Number(params.id);
    }

    if (connectorToLoadId !== 0) {
      displayLoadingPanel();
      dispatch(loadConnectorAsync(connectorToLoadId))
        .then(unwrapResult)
        .then((connectorResponse: ConnectorResponse) => {
          setSelectedConnector(connectorResponse);
          setConnectorConfiguration(connectorResponse.configuration);
        })
        .catch(() => {
          toastError(
            `Failed to load connector information for id ${connectorToLoadId}.`
          );
        })
        .finally(hideLoadingPanel);
    }

    return () => {
      dispatch(resetSelectedDetailedConnector());
      dispatch(resetConnectorHostSchedules());
      dispatch(resetConnectorHostFields());
    };
  }, [dispatch, params.id]);

  useEffect(() => {
    if (shouldResetAuthenticationDependentValues.current) {
      // When authentication mode is changed, we are resetting all authentication dependened values.
      if (selectedConnector?.hostSystem === HostSystem.Safran) {
        resetSafranAuthenticationRelatedValues();
      } else if (selectedConnector?.hostSystem === HostSystem.IlapAnalytics) {
        resetAnalyticsAuthenticationRelatedValues();
      } else if (selectedConnector?.hostSystem === HostSystem.SAP) {
        resetSAPAuthenticationRelatedValues();
      } else if (selectedConnector?.hostSystem === HostSystem.PrimaveraP6) {
        resetPrimaveraAuthenticationRelatedValues();
      }
    }
  }, [connectorConfiguration?.authenticationMode]);

  useEffect(() => {
    if (shouldResetHostSystemDependentValues.current) {
      // When host system is changed, we are resetting all host system dependened values.
      resetHostSystemRelatedValues();
    }
  }, [selectedConnector?.hostSystem]);

  useEffect(() => {
    let isValid = false;

    if (selectedConnector && connectorConfiguration) {
      isValid =
        isConnectorInputValid(selectedConnector, connectorConfiguration) &&
        isModified;
    }

    setIsInputValid(isValid);
  }, [selectedConnector, connectorConfiguration]);

  const resetSafranAuthenticationRelatedValues = () => {
    const currentConfiguration = {
      ...connectorConfiguration!,
    } as SafranApiConnectorConfigurationResponse;

    currentConfiguration.username = "";
    currentConfiguration.password = "";
    currentConfiguration.adTenantId = "";
    currentConfiguration.clientApplicationId = "";
    currentConfiguration.redirectUri = "";
    currentConfiguration.clientSecret = "";
    currentConfiguration.scopes = "";

    setConnectorConfiguration(currentConfiguration);
  };

  const resetAnalyticsAuthenticationRelatedValues = () => {
    const currentConfiguration = {
      ...connectorConfiguration,
    } as AnalyticsConnectorConfiguration;

    currentConfiguration.authenticationModeDisplayText = "";
    currentConfiguration.azureAdAuthority = "";
    currentConfiguration.azureAdClientId = "";
    currentConfiguration.azureAdScopes = "";
    currentConfiguration.ilapAnalyticsToken = "";

    setConnectorConfiguration(currentConfiguration);
  };

  const resetSAPAuthenticationRelatedValues = () => {
    const currentConfiguration = {
      ...getSAPConnectorDefaultConfiguration(),
      baseUrl: connectorConfiguration?.baseUrl,
      authenticationMode: connectorConfiguration?.authenticationMode,
    } as SAPConnectorConfiguration;

    setConnectorConfiguration(currentConfiguration);
  };

  const resetPrimaveraAuthenticationRelatedValues = () => {
    const currentConfiguration = {
      ...connectorConfiguration,
    } as PrimaveraConnectorConfiguration;

    currentConfiguration.username = "";
    currentConfiguration.password = "";

    setConnectorConfiguration(currentConfiguration);
  };

  const resetConnectorAuthenticationMode = () => {
    if (selectedConnector) {
      shouldResetAuthenticationDependentValues.current = true;
      let authenticationMode = -1;
      if (selectedConnector.hostSystem === HostSystem.Safran) {
        authenticationMode =
          authenticationMode as SafranApiConnectorAuthenticationMode;
      } else if (selectedConnector.hostSystem === HostSystem.IlapAnalytics) {
        authenticationMode =
          authenticationMode as AnalyticsConnectorAuthenticationMode;
      } else if (selectedConnector.hostSystem === HostSystem.PrimaveraP6) {
        authenticationMode =
          authenticationMode as PrimaveraConnectorAuthenticationMode;
      } else if (selectedConnector.hostSystem === HostSystem.SAP) {
        authenticationMode =
          authenticationMode as SapConnectorAuthenticationMode;
      } else if (selectedConnector.hostSystem === HostSystem.FileStorage) {
        authenticationMode =
          authenticationMode as FileStorageConnectorAuthenticationMode;
      }

      setConnectorConfiguration((prev) => {
        if (prev) {
          return { ...prev, authenticationMode: authenticationMode };
        }
      });
    }
  };

  const resetHostSystemRelatedValues = () => {
    var defaultConfiguration = undefined;

    if (selectedConnector) {
      if (selectedConnector.hostSystem === HostSystem.IlapAnalytics) {
        defaultConfiguration = getAnalyticsConnectorDefaultConfiguration();
      } else if (selectedConnector.hostSystem === HostSystem.Safran) {
        defaultConfiguration = getSafranConnectorDefaultConfiguration();
      } else if (selectedConnector.hostSystem === HostSystem.SAP) {
        defaultConfiguration = getSAPConnectorDefaultConfiguration();
      } else if (selectedConnector.hostSystem === HostSystem.PrimaveraP6) {
        defaultConfiguration = getPrimaveraConnectorDefaultConfiguration();
      } else if (selectedConnector.hostSystem === HostSystem.FileStorage) {
        defaultConfiguration = getFileStorageConnectorDefaultConfiguration();
      }
    }

    if (defaultConfiguration) {
      setConnectorConfiguration(defaultConfiguration);
    }
  };

  const getConnectorModelFromInput = (): ConnectorModifyRequest | undefined => {
    if (connectorConfiguration && selectedConnector) {
      const configuration = getConnectorConfigurationBasedOnHostSystem(
        connectorConfiguration,
        selectedConnector.hostSystem
      );

      const labelIdentifiers = selectedConnector.labels
        ? selectedConnector.labels.map((l) => l.id)
        : [];

      const connectorRequest: ConnectorModifyRequest = {
        name: selectedConnector.name,
        hostSystem: selectedConnector.hostSystem,
        executionComponent: selectedConnector.executionComponent,
        definition: JSON.stringify(configuration),
        labelIdentifiers: labelIdentifiers,
      };

      return connectorRequest;
    }
  };

  const navigateForCancellation = () => {
    navigate(-1);
  };

  const handleCancelClick = () => {
    if (isModified) {
      setIsUnsavedChangeConfirmationVisible(true);
    } else {
      navigateForCancellation();
    }
  };

  const handleDeleteClick = () => {
    if (selectedConnector) {
      setIsDeleteConfirmationDialogVisible(true);
    }
  };

  const handleConnectorDeleteErrorResponse = (error: any) => {
    if (
      error.statusCode !== 400 ||
      !error.errorsWithType ||
      error.errorsWithType.length === 0
    ) {
      toastError(error.message);
    } else {
      var businessRuleValidationErrors: string[] = [];
      var otherErrors: string[] = [];
      error.errorsWithType.forEach(
        (e: { type: ValidationErrorType; message: string }) => {
          if (e.type === ValidationErrorType.BusinessRuleValidationError) {
            businessRuleValidationErrors.push(e.message);
          } else {
            otherErrors.push(e.message);
          }
        }
      );
      if (otherErrors.length !== 0) {
        toastError(otherErrors.join(","));
      }
      if (businessRuleValidationErrors.length !== 0) {
        setValidationMessageForDelete(businessRuleValidationErrors);
      }
    }
  };

  const handleDeleteConfirm = () => {
    if (selectedConnector) {
      displayLoadingPanel();
      dispatch(removeConnectorAsync(selectedConnector.id))
        .unwrap()
        .then(() => {
          navigate(CONNECTORS);
        })
        .catch((error: any) => {
          handleConnectorDeleteErrorResponse(error);
        })
        .finally(hideLoadingPanel);
    }
    setIsDeleteConfirmationDialogVisible(false);
  };

  const handleSaveChangesClick = () => {
    setIsConfirmationDialogVisible(true);
  };

  const handleConfirmationDialogClose = () => {
    setIsConfirmationDialogVisible(false);
  };

  const handleTitleChanged = (data: any) => {
    handleConnectorValueChanged("name", data.value);
    setIsModified(true);
  };

  const handleConfirmSaveChangesClicked = () => {
    const connectorRequest = getConnectorModelFromInput();

    if (connectorRequest && selectedConnector) {
      displayLoadingPanel();
      dispatch(
        modifyConnectorAsync({
          connectorId: selectedConnector.id,
          connectorRequest: connectorRequest,
        })
      )
        .then(unwrapResult)
        .then((updatedConnector: ConnectorResponse) => {
          navigate(`${CONNECTORS}/${updatedConnector.id}`);
        })
        .catch(() => {
          toastError("Failed to update connector.");
        })
        .finally(() => {
          hideLoadingPanel();
          setIsConfirmationDialogVisible(false);
        });
    }
  };

  const handleConfirmCancel = () => {
    setIsUnsavedChangeConfirmationVisible(false);
    navigateForCancellation();
  };

  const handleCancelConfirmationDialog = () => {
    setIsUnsavedChangeConfirmationVisible(false);
  };

  const footerDivId: string = "connector-editor-footer";
  const divIdsToExcludeForGrid: string[] = [footerDivId];

  return (
    <div>
      <div>
        {selectedConnector && connectorConfiguration && (
          <ConnectorViewerOrEditor
            connector={selectedConnector}
            connectorConfiguration={connectorConfiguration}
            mode={PromineoModalMode.Modify}
            handleConnectorValueChanged={handleConnectorValueChanged}
            handleHostSystemValueChange={handleHostSystemValueChange}
            handleConfigurationValueChanged={
              handleConnectorConfigurationValueChanged
            }
            handleAuthenticationModeValueChanged={
              handleAuthenticationModeValueChanged
            }
            handleTitleChanged={handleTitleChanged}
            onAddNewLabel={handleAddNewLabel}
            onDeleteLabel={handleDeleteLabel}
            divIdsToExcludeForGrid={divIdsToExcludeForGrid}
          />
        )}
      </div>
      <div className="flex justify-between mt-6" id={footerDivId}>
        <div className="flex gap-6">
          <PromineoButton
            variant={PromineoButtonType.Secondary}
            text="Cancel"
            onClick={handleCancelClick}
          />
          <PromineoButton
            variant={PromineoButtonType.Danger}
            text="Delete"
            onClick={handleDeleteClick}
          />
        </div>

        <PromineoButton
          variant={PromineoButtonType.Primary}
          text="Save Changes"
          onClick={handleSaveChangesClick}
          disabled={!isInputValid}
        />
      </div>
      {isConfirmationDialogVisible && (
        <PromineoConfirmationDialog
          onConfirm={handleConfirmSaveChangesClicked}
          onCancel={handleConfirmationDialogClose}
          content={`Are you sure you save changes and overwrite current values?`}
          cancelButtonText="Go back"
          confirmButtonText="Save Changes"
        />
      )}

      {validationMessageForDelete.length !== 0 && selectedConnector && (
        <ConnectorDeleteValidationDialog
          validationMessagForDelete={validationMessageForDelete}
          onClose={() => {
            setValidationMessageForDelete([]);
          }}
          connectorId={selectedConnector.id}
          connectorName={selectedConnector.name}
        />
      )}

      {isDeleteConfirmationDialogVisible && (
        <PromineoConfirmationDialog
          onConfirm={handleDeleteConfirm}
          onCancel={() => {
            setIsDeleteConfirmationDialogVisible(false);
          }}
          content={`Are you sure you want to delete this connector?`}
          cancelButtonText="No"
          confirmButtonText="Yes"
        />
      )}

      {isUnsavedChangeConfirmationVisible ? (
        <PromineoCancelEditingConfirmationDialog
          onConfirm={handleConfirmCancel}
          onCancel={handleCancelConfirmationDialog}
        ></PromineoCancelEditingConfirmationDialog>
      ) : null}
    </div>
  );
}
