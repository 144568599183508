import { forwardRef, useCallback } from "react";
import HostParameterAccordion from "./HostParameterAccordion";
import { SupportedHostSystemConfigurationType } from "shared/types/HostSystemConfigurationTypes";
import SenderHostParameterOverrides from "./SenderHostParameterOverrides";
import { HostSystem } from "shared/enums/feature/HostSystem";
import { EditorMode } from "shared/enums/EditorMode";
import ReceiverHostParameterOverrides from "./ReceiverHostParameterOverrides";

export interface HostParameterOverrideProps {
  exportConfig: SupportedHostSystemConfigurationType | undefined;
  importConfig: SupportedHostSystemConfigurationType | undefined;
  senderCodeSet?: number | null;
  receiverCodeSet?: number | null;
  senderConnectorId?: number;
  receiverConnectorId?: number;
  senderHostSystem?: HostSystem;
  receiverHostSystem?: HostSystem;
  senderHostSystemName?: string | null;
  receiverHostSystemName?: string | null;
  onSenderConfigurationValueChanged?: (fieldName: string, value: any) => void;
  onReceiverConfigurationValueChanged?: (fieldName: string, value: any) => void;
  isCurrentUserSender?: boolean;
  isCurrentUserReceiver?: boolean;
  gridHeight: number;
  displayIfConfigIsAvailable?: boolean;
  isEditable: boolean;
  onStructureGridModified?: () => void;
  isNew?: boolean;
  ref: any;
}

const HostParametersOverride = forwardRef(
  (props: HostParameterOverrideProps, forwardedRef) => {
    const hostSystemNameClass = `shrink-0 font-semibold ${
      props.isNew ? "my-4 w-96 text-[18px]" : "w-40"
    }`;

    const SenderParameters = useCallback(() => {
      const isSendingParametersEditable =
        props.isCurrentUserSender && props.isEditable;
      const isVisible = !!props.senderConnectorId;
      const senderHostSystemName = `${props.senderHostSystemName} ${
        props.isNew ? "sending parameters" : ""
      }`;
      return isVisible ? (
        <div className={`${!props.isNew ? "flex" : ""}`}>
          {props.senderHostSystemName && (
            <div className={hostSystemNameClass}>{senderHostSystemName}</div>
          )}
          <SenderHostParameterOverrides
            hostSystem={props.senderHostSystem}
            mode={
              isSendingParametersEditable ? EditorMode.Edit : EditorMode.View
            }
            selectedCodeSet={props.senderCodeSet ?? null}
            connectorId={props.senderConnectorId!}
            hostSystemConfiguration={props.exportConfig}
            handleConfigurationValueChanged={
              props.onSenderConfigurationValueChanged
            }
            height={props.gridHeight}
          />
        </div>
      ) : (
        <></>
      );
    }, [props]);

    const ReceiverParameters = useCallback(() => {
      const isReceivingParameterEditable =
        props.isCurrentUserReceiver && props.isEditable;
      const receiverHostSystemName = `${props.receiverHostSystemName} ${
        props.isNew ? "receiving parameters" : ""
      }`;
      return (
        <div className={`${!props.isNew ? "flex" : ""}`}>
          {props.receiverHostSystemName && (
            <div className={hostSystemNameClass}>{receiverHostSystemName}</div>
          )}
          <ReceiverHostParameterOverrides
            hostSystem={props.receiverHostSystem}
            mode={
              isReceivingParameterEditable ? EditorMode.Edit : EditorMode.View
            }
            handleConfigurationValueChanged={
              props.onReceiverConfigurationValueChanged
            }
            selectedCodeSet={props.receiverCodeSet}
            hostParameters={props.importConfig}
            ref={forwardedRef}
            height={props.gridHeight}
            receiverConnectorId={props.receiverConnectorId}
            onStructureGridModified={props.onStructureGridModified}
          />
        </div>
      );
    }, [
      props.isCurrentUserReceiver,
      props.isEditable,
      props.receiverHostSystem,
      props.receiverCodeSet,
      props.importConfig,
      props.gridHeight,
      props.receiverConnectorId,
    ]);

    const shouldDisplaySendingParameters =
      props.displayIfConfigIsAvailable === undefined
        ? true
        : props.displayIfConfigIsAvailable && !!props.exportConfig;

    const shouldDisplayReceivingParameters =
      props.displayIfConfigIsAvailable === undefined
        ? true
        : props.displayIfConfigIsAvailable && !!props.importConfig;

    return (
      <div>
        {shouldDisplaySendingParameters ? (
          !props.isNew ? (
            <HostParameterAccordion
              title="Sender"
              itemRender={SenderParameters}
              isExpanded={props.isCurrentUserSender ?? false}
            />
          ) : (
            SenderParameters()
          )
        ) : null}
        {shouldDisplaySendingParameters && shouldDisplayReceivingParameters && (
          <div className="border m-4 border-lightGray"></div>
        )}

        {shouldDisplayReceivingParameters ? (
          !props.isNew ? (
            <HostParameterAccordion
              title="Receiver"
              itemRender={ReceiverParameters}
              isExpanded={props.isCurrentUserReceiver ?? false}
            />
          ) : (
            ReceiverParameters()
          )
        ) : null}
      </div>
    );
  }
);

export default HostParametersOverride;
