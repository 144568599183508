import { EditorMode } from "shared/enums/EditorMode";
import AnalyticsImportConfig from "interfaces/host-system-config/analytics/AnalyticsImportConfig";
import RevisionTypeField from "features/common/host-system-parameter-fields/analytics/RevisionTypeField";
import { useEffect, useState } from "react";
import AdjustCutoffField from "features/common/host-system-parameter-fields/analytics/AdjustCutoffField";
import {
  revisionTypePropertyName,
  adjustCutoffFromTransferDatePropertyName,
  cutoffAdjustmentTypePropertyName,
} from "shared/constants/AnalyticsHostParameterPropertyName";
import CutoffAdjustmentTypeField from "features/common/host-system-parameter-fields/analytics/CutoffAdjustmentTypeField";
import { CutoffAdjustmentType } from "shared/enums/feature/analytics-host-system/CutoffAdjustmentType";

interface Props {
  mode: EditorMode;
  config: AnalyticsImportConfig;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  isOverride?: boolean;
}

export default function AnalyticsReceivingFields(props: Props) {
  const { mode, config, handleConfigurationValueChanged } = props;

  const [displayCutoffAdjustmentDropdown, setDisplayCutoffAdjustmentDropdown] =
    useState<boolean>(false);

  useEffect(() => {
    setDisplayCutoffAdjustmentDropdown(config.adjustCutoffFromTransferDate);
  }, [config.adjustCutoffFromTransferDate]);

  const classNames = props.isOverride
    ? "grid grid-cols-3 gap-x-16 gap-y-8"
    : "space-y-6";

  return (
    <div className={classNames}>
      <RevisionTypeField
        selectedRevisionType={config.revisionType}
        mode={mode}
        fieldName={revisionTypePropertyName}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
      <AdjustCutoffField
        switchButtonValue={config.adjustCutoffFromTransferDate}
        fieldName={adjustCutoffFromTransferDatePropertyName}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
        mode={mode}
      />

      {displayCutoffAdjustmentDropdown && (
        <CutoffAdjustmentTypeField
          fieldName={cutoffAdjustmentTypePropertyName}
          mode={mode}
          selectedCutoffAdjustmentType={config.cutoffAdjustmentType}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
        />
      )}
    </div>
  );
}
