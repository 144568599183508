import { getFormattedDateTime } from "shared/utilities/DateTimeUtility";

interface Props {
  userName: string;
  dateTime: string;
}

export default function SignatureDetails(props: Props) {
  const { userName, dateTime } = props;
  const text = `Signed off by ${userName} at ${getFormattedDateTime(dateTime)}`;

  return (
    <div className="text-ilapBlue font-poppins font-semibold text-15px leading-22px">
      {text}
    </div>
  );
}