import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import StepProgressBar from "components/common/step-progressbar/StepProgressBar";
import PromineoModal from "components/modal/PromineoModal";
import { ReactElement, useState } from "react";

interface Props {
  title: string;
  totalStep: number;
  currentStep: number;
  isDataValidToProceed: boolean;
  content: ReactElement;
  cancelConfirmationMessage: string;
  isModified: boolean;
  onBack: () => void;
  onNextOrSubmit: () => void;
  onCancel: () => void;
}

export default function WizardModalWithStepper(props: Props) {
  const {
    title,
    totalStep,
    currentStep,
    isDataValidToProceed,
    content,
    cancelConfirmationMessage,
    isModified,
    onBack,
    onNextOrSubmit,
    onCancel,
  } = props;
  const [
    isCancelConfirmationDialogVisible,
    setIsCancelConfirmationDialogVisible,
  ] = useState(false);

  const handleCancelOrBackClick = () => {
    if (currentStep === 1) {
      if (isModified) {
        setIsCancelConfirmationDialogVisible(true);
      } else {
        onCancel();
      }
    } else {
      onBack();
    }
  };

  return (
    <>
      <PromineoModal isVisible={true} height={670} width={"90vw"}>
        <div className="flex flex-col justify-between h-[620px] gap-y-4">
          <div className="flex flex-col justify-between h-[580px] gap-y-4">
            <div>
              <div className="flex justify-between">
                <div>
                  <span className="font-poppins text-base font-semibold">
                    {title}
                  </span>
                </div>
                <StepProgressBar
                  totalSteps={totalStep}
                  currentStep={currentStep}
                />
              </div>
            </div>
            <div className="font-normal font-inter flex-grow border-b border-t pb-4 border-lightGray">
              {content}
            </div>
          </div>
          <div>
            <div className="flex justify-between">
              <PromineoButton
                text={currentStep === 1 ? "Cancel" : "Back"}
                variant={PromineoButtonType.Secondary}
                onClick={handleCancelOrBackClick}
              />
              <PromineoButton
                text={currentStep === totalStep ? "Submit" : "Next Step"}
                onClick={onNextOrSubmit}
                disabled={!isDataValidToProceed}
              />
            </div>
          </div>
        </div>
      </PromineoModal>
      {isCancelConfirmationDialogVisible && (
        <PromineoConfirmationDialog
          onConfirm={() => {
            onCancel();
            setIsCancelConfirmationDialogVisible(false);
          }}
          onCancel={() => {
            setIsCancelConfirmationDialogVisible(false);
          }}
          content={cancelConfirmationMessage}
          cancelButtonText="No"
          confirmButtonText="Yes"
        />
      )}
    </>
  );
}
