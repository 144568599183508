import OwnTenantResponse from "interfaces/response/tenant/OwnTenantResponse";
import TenantInfoLabelTextContent from "./TenantInfoLabelTextContent";
import PromineoTextBox from "components/common/controls/PromineoTextBox";

interface Props {
  tenantDetail: OwnTenantResponse;
  onChange: (tenantInfo: OwnTenantResponse) => void;
  isEdit: boolean;
}

export default function TenantNameFieldViewOrEdit(props: Props) {
  const handleValueChange = (evt: any) => {
    props.onChange({ ...props.tenantDetail, name: evt.value });
  };
  
  if (props.isEdit) {
    return (
      <PromineoTextBox
        width={188}
        defaultValue={props.tenantDetail.name}
        onValueChanged={handleValueChange}
      />
    );
  }
  return <TenantInfoLabelTextContent text={props.tenantDetail.name} />;
}
