import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import EditTemplateGridData from "interfaces/component-data/EditTemplateGridData";
import FieldResponse from "interfaces/response/FieldResponse";
import { useCallback, useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { loadFieldsAsync } from "store/actions/FieldActions";
import { useAppDispatch } from "store/hooks";
import { RootState } from "store/store";
import FieldSelector from "./FieldSelector";
import "components/common/grid/styles/PromineoUIGrid.css";
import { useNavigate } from "react-router";
import { TEMPLATE_FIELDS } from "shared/constants/RoutePathConstants";
import TemplateFieldWithValueCountResponse from "interfaces/response/TemplateFieldWithValueCountResponse";
interface Props {
  data: EditTemplateGridData;
  onDeleteRow: (data: any) => void;
  onAddNewField: (data: FieldResponse) => void;
  height: number;
}

export default function EditTemplateGrid(props: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const [alreadyAddedFields, setAlreadyAddedFields] = useState<number[]>([]);

  useEffect(() => {
    dispatch(loadFieldsAsync());
  }, []);

  const availableFields = useSelector((store: RootState) => store.fieldData.fields);

  useEffect(() => {
    var fieldIds = props.data.fields?.map((f) => f.fieldId) || [];
    setAlreadyAddedFields(fieldIds);
  }, [props.data.fields]);

  const navigateToTemplateFieldViewer = useCallback(
    (evt: { data: TemplateFieldWithValueCountResponse }) => {
      if (evt.data.id !== 0) {
        navigate(`${TEMPLATE_FIELDS}/${evt.data.id}`);
      }
    },
    []
  );

  const toolbarConfig = useRef({
    dislplaySearchPanel: true,
  });

  const rowOperationConfig = useMemo(() => {
    return {
      displayDeleteRowOption: {
        visible: true,
        onDelete: props.onDeleteRow,
      },
      visible: false,
    };
  }, []);

  return (
    <div>
      <PromineoViewGrid
        dataSource={props.data.fields}
        className="promineo-ui-grid"
        onRowClick={navigateToTemplateFieldViewer}
        toolbarConfig={toolbarConfig.current}
        rowOperationConfig={rowOperationConfig}
        height={props.height}
        additionalWidget={
          <FieldSelector
            dataSource={availableFields}
            onAddField={props.onAddNewField}
            alreadyAddedFieldIds={alreadyAddedFields}
          />
        }
        ref={gridRef}
      >
        <Scrolling rowRenderingMode={"virtual"} mode={"virtual"} />
        <Column
          dataField={"planningObjectTypeText"}
          caption="Planning Object Type"
          alignment={"left"}
        />
        <Column dataField={"name"} caption="ILAP Term" />
        <Column dataField={"description"} caption="Description" />
        <Column dataField={"dataTypeText"} caption="Data Type" />
        <Column dataField={"contentControlLevelText"} caption="Content Control"/>
        <Column dataField={"valueCount"} caption="# of valid values" alignment={"left"} />
      </PromineoViewGrid>
    </div>
  );
}
