import ScrollView from "devextreme-react/scroll-view";
import AnalyticsExportConfig from "interfaces/host-system-config/analytics/AnalyticsExportConfig";
import { EditorMode } from "shared/enums/EditorMode";
import AnalyticsSendingFields from "./AnalyticsSendingFields";

interface Props {
  mode: EditorMode;
  height: number;
  config: AnalyticsExportConfig;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  isOverride?: boolean;
}

export default function AnalyticsSendingParameters(props: Props) {
  const { mode, isOverride, config, height, handleConfigurationValueChanged } =
    props;

  return !isOverride ? (
    <ScrollView showScrollbar="always" height={height}>
      <div className="flex space-x-6">
        <div className="w-60 font-semibold text-base text-textGray">
          Analytics Sending Parameter
        </div>
        <AnalyticsSendingFields
          config={config}
          mode={mode}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
        />
      </div>
    </ScrollView>
  ) : (
    <AnalyticsSendingFields
      config={config}
      mode={mode}
      handleConfigurationValueChanged={handleConfigurationValueChanged}
    />
  );
}
