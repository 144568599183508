import LabelWithContent, {
  TextContent,
} from "components/common/LabelWithContent";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";

interface Props {
  executionComponentName: string;
  configDirection: ConfigDirection;
}

export default function ExecutionComponentDisplayField(props: Props) {
  return (
    <LabelWithContent
      label="Execute component"
      content={<TextContent text={props.executionComponentName} />}
      tooltipText={`The local component where ${
        props.configDirection === ConfigDirection.Sending
          ? "uploads"
          : "downloads"
      } are executed. The execute component is defined by the connector.`}
    />
  );
}
