interface Props {
  fillColor?: string;
}

export default function SearchIcon(props: Props) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.64583 7.33333H8.11917L7.9325 7.15333C8.58583 6.39333 8.97917 5.40667 8.97917 4.33333C8.97917 1.94 7.03917 0 4.64583 0C2.2525 0 0.3125 1.94 0.3125 4.33333C0.3125 6.72667 2.2525 8.66667 4.64583 8.66667C5.71917 8.66667 6.70583 8.27333 7.46583 7.62L7.64583 7.80667V8.33333L10.9792 11.66L11.9725 10.6667L8.64583 7.33333ZM4.64583 7.33333C2.98583 7.33333 1.64583 5.99333 1.64583 4.33333C1.64583 2.67333 2.98583 1.33333 4.64583 1.33333C6.30583 1.33333 7.64583 2.67333 7.64583 4.33333C7.64583 5.99333 6.30583 7.33333 4.64583 7.33333Z"
        fill="#434343"
      />
    </svg>
  );
}
