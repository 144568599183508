import LabelWithContent from "components/common/LabelWithContent";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { loadAnalyticsRevisionTypeValuesAsync } from "store/actions/DropdownValueActions";
import HostSystemConfigurationDropdown from "features/setup/configs/host-system/HostSystemConfigurationDropdown";
import { RevisionType } from "shared/enums/feature/analytics-host-system/RevisionType";
import BaseHostSystemComponentProps from "../BaseHostSystemComponentProps";

interface Props extends BaseHostSystemComponentProps {
  selectedRevisionType: RevisionType;
}

export default function RevisionTypeField(props: Props) {
  const {
    mode,
    selectedRevisionType,
    fieldName,
    handleConfigurationValueChanged,
  } = props;
  const dispatch = useAppDispatch();

  const revisionTypeOptions = useAppSelector(
    (store) => store.dropdownData.analyticsRevisionTypes
  );

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadAnalyticsRevisionTypeValuesAsync()).finally(hideLoadingPanel);
  }, []);

  return (
    <div className="space-y-6">
      <LabelWithContent
        label="Revision Type"
        content={
          <HostSystemConfigurationDropdown
            mode={mode}
            dropdownOptions={revisionTypeOptions}
            fieldName={fieldName}
            selectedValue={selectedRevisionType}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
          />
        }
      />
    </div>
  );
}
