import InfoIcon from "components/icons/InfoIcon";
import { Position } from "devextreme-react/tooltip";
import uniqueId from "lodash/uniqueId";
import { useRef, useState } from "react";
import PromineoTooltip from "./controls/PromineoTooltip";

interface Props {
  tooltipText: string;
}

export default function InfoIconWithTooltip(props: Props) {
  const idRef = useRef(uniqueId("tooltip-target-"));
  const [isVisible, setIsVisible] = useState(false);

  const toggleTooltip = () => {
    setIsVisible((value) => !value);
  };

  return (
    <>
      <div
        id={idRef.current}
        className="cursor-pointer mt-[1px]"
        onClick={toggleTooltip}
      >
        <InfoIcon />
      </div>
      {/* 
          Setting visible property makes this controlled control. It will not trigger onVisibleChange. 
          onVisibleChange will only be triggered when the tooltip is hidden by clicking outside.
          So, we are toggling isVisible on the I icon click. And when the tooltip is hidden by clicking outside,
          we are toggling isVisible(to false) again by handling onVisibleChange event.
      */}
      <PromineoTooltip
        target={`#${idRef.current}`}
        text={props.tooltipText}
        visible={isVisible}
        hideOnOutsideClick={true}
        onVisibleChange={toggleTooltip}
      >
        <Position
          at="top"
          my="left bottom"
          offset={{
            x: -21,
            y: 2,
          }}
        />
      </PromineoTooltip>
    </>
  );
}
