import { IPopoverOptions, Popover } from "devextreme-react/popover";
import { ReactNode } from "react";
import "./styles/PromineoPopover.css";

interface Props {
  children?: ReactNode;
}

export default function PromineoPopover(props: Props & IPopoverOptions) {
  const { className, ...rest } = props;

  return (
    <Popover
      {...rest}
      className={className}
      wrapperAttr={{ class: 'promineo-popover' }}
    ></Popover>
  );
}
