import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { loadConnectorAsync } from "store/actions/ConnectorActions";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import ConnectorViewerOrEditor from "./ConnectorViewerOrEditor";
import { unwrapResult } from "@reduxjs/toolkit";
import { toastError } from "shared/utilities/ToastUtility";
import SafranApiConnectorConfigurationResponse from "interfaces/response/connector/SafranApiConnectorConfigurationResponse";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import AnalyticsConnectorConfiguration from "interfaces/response/connector/AnalyticsConnectorConfiguration";
import SAPConnectorConfiguration from "interfaces/response/connector/SAPConnectorConfiguration";
import PrimaveraConnectorConfiguration from "interfaces/response/connector/PrimaveraConnectorConfiguration";
import MicrosoftProjectConnectorConfiguration from "interfaces/response/connector/MicrosoftProjectConnectorConfiguration";
import FileStorageConnectorConfiguration from "interfaces/response/connector/FileStorageConnectorConfiguration";
import {
  resetConnectorHostFields,
  resetConnectorHostSchedules,
  resetSelectedDetailedConnector,
} from "store/slices/ConnectorSlice";
import ConnectorResponse from "interfaces/response/connector/ConnectorResponse";

export default function ConnectorViewer() {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [selectedConnector, setSelectedConnector] =
    useState<ConnectorResponse>();
  const [connectorConfiguration, setConnectorConfiguration] = useState<
    | SafranApiConnectorConfigurationResponse
    | AnalyticsConnectorConfiguration
    | SAPConnectorConfiguration
    | PrimaveraConnectorConfiguration
    | MicrosoftProjectConnectorConfiguration
    | FileStorageConnectorConfiguration
  >();

  useEffect(() => {
    let connectorToLoad = 0;
    if (params.id && !isNaN(Number(params.id))) {
      connectorToLoad = Number(params.id);
    }
    if (connectorToLoad !== 0) {
      displayLoadingPanel();
      dispatch(loadConnectorAsync(connectorToLoad))
        .then(unwrapResult)
        .then((connectorResponse: ConnectorResponse) => {
          setSelectedConnector(connectorResponse);
          setConnectorConfiguration(connectorResponse.configuration);
        })
        .catch(() => {
          toastError(
            `Failed to load connector information for id ${connectorToLoad}.`
          );
        })
        .finally(hideLoadingPanel);
    }

    return () => {
      dispatch(resetSelectedDetailedConnector());
      dispatch(resetConnectorHostSchedules());
      dispatch(resetConnectorHostFields());
    };
  }, [dispatch, params.id]);

  return (
    <div>
      {selectedConnector && connectorConfiguration && (
        <ConnectorViewerOrEditor
          connector={selectedConnector}
          connectorConfiguration={connectorConfiguration}
          mode={PromineoModalMode.View}
          divIdsToExcludeForGrid={[]}
        />
      )}
    </div>
  );
}
