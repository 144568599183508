import TenantRequestDialog from "./TenantRequestDialog";
import {signOutAsync} from "../../../authentication/AuthenticationManager";
import { useLocation } from 'react-router-dom';

interface Props {
  onClose: () => void;  
}

export default function TenantUserDisabledDialog(props: Props) {
  
  const location = useLocation();
  var isHomePage = location.pathname === "/";
  const handleClose = async () => {
    try {
      props.onClose(); 
      if(!isHomePage)
      {
        await signOutAsync();
      }            
    } catch (error) {
      console.error("Error during onClose:", error);
    }
  };

  return (
    <TenantRequestDialog
      title={"Your user has been disabled by a tenant admin."}
      contentText={"Contact one of your tenant admins."}
      confirmButtonText={"Close"}
      onConfirm={handleClose}
      hideCancelButton={true}
    />
  );
}
