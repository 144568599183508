import DisabledIcon from "components/icons/DisabledIcon";
import PendingIcon from "components/icons/PendingIcon";
import TickIcon from "components/icons/TickIcon";
import TenantUserResponse from "interfaces/response/tenant/TenantUserResponse";
import { UserStatus } from "shared/enums/UserStatus";

export default function UserStatusLabel(props: { user: TenantUserResponse }) {
  const tenantUser = props.user;

  const commonClasses =
    "flex justify-center items-center rounded-[10px] py-1 gap-x-1 text-10px leading-[15px] font-medium";

  const ActiveStatus = () => {
    return (
      <div className="w-[64px]">
        <div className={`bg-lightGreen ${commonClasses}`}>
          <TickIcon />
          {tenantUser.statusDisplay}
        </div>
      </div>
    );
  };

  const DisabledStatus = () => {
    return (
      <div className="w-[77px]">
        <div className={`bg-lighterRed ${commonClasses}`}>
          <DisabledIcon />
          {tenantUser.statusDisplay}
        </div>
      </div>
    );
  };

  const InvitedStatus = () => {
    return (
      <div className="w-[67px]">
        <div className={`bg-lighterBlue ${commonClasses}`}>
          <PendingIcon />
          {tenantUser.statusDisplay}
        </div>
      </div>
    );
  };

  return tenantUser.status === UserStatus.Active ? (
    <ActiveStatus />
  ) : tenantUser.status === UserStatus.Invited ? (
    <InvitedStatus />
  ) : (
    <DisabledStatus />
  );
}
