import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { useState } from "react";
import SetParterConfigurationWizard from "./SetParterConfigurationWizard";

interface Props {
  isPartnerRoleSender: boolean;
  exchangeAgreement: ExchangeAgreementDetailedResponse;
}

export default function ConfigurePartnerSettingsPlaceholder(props: Props) {
  const [
    isPartnerConfigurationWizardVisible,
    setIsPartnerConfigurationWizardVisible,
  ] = useState(false);

  const handleSetConfigurationClick = () => {
    setIsPartnerConfigurationWizardVisible(true);
  };

  const handleCloseConfigurationWizard = () => {
    setIsPartnerConfigurationWizardVisible(false);
  };

  return (
    <>
      <div className="flex justify-center">
        <div className="w-[240px] flex flex-col items-center">
          <div className="text-sm leading-[21px] font-semibold font-poppins">
            Configure your settings
          </div>
          <div className="text-center mt-2 mb-6 text-sm leading-[18px] font-inter">
            You need to configure your settings to submit the IEA.
          </div>
          <div>
            <PromineoButton
              variant={PromineoButtonType.Primary}
              text="Set configuration"
              onClick={handleSetConfigurationClick}
            />
          </div>
        </div>
      </div>
      {isPartnerConfigurationWizardVisible && (
        <SetParterConfigurationWizard
          isPartnerRoleSender={props.isPartnerRoleSender}
          exchangeAgreement={props.exchangeAgreement}
          onClose={handleCloseConfigurationWizard}
        />
      )}
    </>
  );
}
