import moment from "moment";

const norwegianDateFormat = "DD.MM.YY";
const norwegianDateTimeFormat = "HH:mm DD.MM.YY";

export const getFormattedDate = (date: string) => {
  const localDate = getLocalDateFromUtcDate(date);

  return localDate.format(norwegianDateFormat);
};

export const getLocalDateFromUtcDate = (date: string) => {
  const utcDate = moment.utc(date);
  const localDate = utcDate.local();

  return localDate;
};

export const getLocalDateFromUtc = (date?: Date) => {
  if (!date) {
    return date;
  }

  const utcDate = moment.utc(date);
  const localDate = utcDate.local();
  return localDate;
};

export const getFormattedDateTimeFromUtcDateObject = (date?: Date) => {
  const localDate = getLocalDateFromUtc(date);
  if(!localDate) return localDate;

  return localDate.format(norwegianDateTimeFormat);
}

export const getFormattedDateTime = (date: string) => {
  const localDate = getLocalDateFromUtcDate(date);

  return localDate.format(norwegianDateTimeFormat);
};

export const checkIfDateIsAfter = (date1: string, date2: string) => {
  return moment(date1).isAfter(date2);
};

export const getDefaultLocaleFormattedDate = (date: string) => {
  return new Date(date).toLocaleDateString();
};

export const getFormattedTimestamp = (date: string): string => {
  const localDate = getLocalDateFromUtcDate(date);
  return localDate.format(norwegianDateTimeFormat);
};

export const checkIfDateIsInLastDays = (date: string, days: number) => {
  var diff = moment().diff(moment(date), "days");

  return diff <= days;
};

export const getFormattedTimeFromMilliseconds = (duration: number) => {
  let seconds = Math.floor((duration / 1000) % 60);
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  let hoursString = hours < 10 ? "0" + hours : hours;
  let minutesString = minutes < 10 ? "0" + minutes : minutes;
  let secondsString = seconds < 10 ? "0" + seconds : seconds;

  return hoursString + "h " + minutesString + "m " + secondsString + "s";
};

export const getFormattedTime = (milliseconds: number) => {
  if (milliseconds) {
    // Convert milliseconds to seconds
    const totalSeconds = milliseconds / 1000;
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    // Format the result based on the presence of hours and minutes
    if (hours > 0) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else if (minutes > 0) {
      return `${minutes}m ${seconds}s`;
    } else {
      return `${seconds}s`;
    }
  }
  return "0h 0m 0s";
};
