import PromineoCheckbox from "components/common/controls/PromineoCheckbox";
import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import PromineoTextBox from "components/common/controls/PromineoTextBox";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import SafranApiConnectorConfigurationResponse from "interfaces/response/connector/SafranApiConnectorConfigurationResponse";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExecutionComponent } from "shared/enums/feature/ExecutionComponent";
import { SafranApiConnectorAuthenticationMode } from "shared/enums/feature/safran-host-system/SafranApiConnectorAuthenticationMode";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import { checkIfHasFlag } from "shared/utilities/CommonUtility";
import { loadSafranApiConnectorAuthenticationModeValuesAsync } from "store/actions/DropdownValueActions";
import { AppDispatch, RootState } from "store/store";
import ConnectorLabelWithContent from "../../ConnectorLabelWithContent";
import SafranConnectorAzureAdAuthenticationContent from "./SafranConnectorAzureAdAuthenticationContent";
import SafranConnectorAzureAuthenticationWithClientSecretContent from "./SafranConnectorAzureAuthenticationWithClientSecretContent";
import SafranConnectorBasicAuthenticationContent from "./SafranConnectorBasicAuthenticationContent";

interface Props {
  configuration: SafranApiConnectorConfigurationResponse;
  mode: PromineoModalMode;
  classNames?: string;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  handleSafranAuthenticationModeValueChanged?: (value: number) => void;
  validateOnFocusOut?: (event: any) => void;
  executionComponent: ExecutionComponent;
}

export default function SafranConnectorInformation(props: Props) {
  const {
    configuration,
    mode,
    classNames,
    handleConfigurationValueChanged,
    handleSafranAuthenticationModeValueChanged,
    validateOnFocusOut,
  } = props;

  const dispatch = useDispatch<AppDispatch>();

  const safranAuthenticationModes = useSelector(
    (state: RootState) =>
      state.dropdownData.safranApiConnectorAuthenticationMode
  );

  useEffect(() => {
    if (
      mode === PromineoModalMode.Create ||
      mode === PromineoModalMode.Modify
    ) {
      displayLoadingPanel();
      dispatch(loadSafranApiConnectorAuthenticationModeValuesAsync()).finally(
        hideLoadingPanel
      );
    }
  }, [dispatch, mode]);

  const RenderAuthenticationModeComponent = useCallback(() => {
    return (
      <>
        {(mode === PromineoModalMode.Create ||
          mode === PromineoModalMode.Modify) && (
          <PromineoSelectBox
            placeholder="Select"
            width={mode === PromineoModalMode.Create ? 330 : 188}
            dropdownWidth="280px"
            fieldName="Authentication Mode"
            required={true}
            items={safranAuthenticationModes.filter((m) =>
              checkIfHasFlag(
                m.allowedExecutionComponents,
                props.executionComponent
              )).sort((a, b) => a.name.localeCompare(b.name))
            }
            value={configuration.authenticationMode}
            valueExpr={"value"}
            displayExpr={"name"}
            onValueChange={(value: number) => {
              if (handleSafranAuthenticationModeValueChanged) {
                handleSafranAuthenticationModeValueChanged(value);
              }
            }}
            onFocusOut={(event: any) => {
              if (validateOnFocusOut) {
                validateOnFocusOut(event);
              }
            }}
          />
        )}

        {mode === PromineoModalMode.View &&
          configuration.authenticationModeDisplayText}
      </>
    );
  }, [
    mode,
    safranAuthenticationModes,
    configuration.authenticationMode,
    configuration.authenticationModeDisplayText,
    props.executionComponent,
  ]);

  const RenderUrlComponent = useCallback(() => {
    return (
      <>
        {(mode === PromineoModalMode.Create ||
          mode === PromineoModalMode.Modify) && (
          <PromineoTextBox
            width={mode === PromineoModalMode.Create ? 330 : 188}
            name="baseUrl"
            fieldName="Url"
            required={true}
            defaultValue={configuration.baseUrl}
            placeholder="Type..."
            onChange={({ event }: any) => {
              if (handleConfigurationValueChanged) {
                handleConfigurationValueChanged(
                  event.currentTarget.name,
                  event.currentTarget.value
                );
              }
            }}
            onFocusOut={(event: any) => {
              if (validateOnFocusOut) {
                validateOnFocusOut(event);
              }
            }}
          />
        )}

        {mode === PromineoModalMode.View && configuration.baseUrl}
      </>
    );
  }, [mode, configuration.baseUrl]);

  const RenderOwnerComponent = useCallback(() => {
    return (
      <>
        {(mode === PromineoModalMode.Create ||
          mode === PromineoModalMode.Modify) && (
          <PromineoTextBox
            width={mode === PromineoModalMode.Create ? 330 : 188}
            name="owner"
            fieldName="Owner"
            required={configuration.useOwnerVerification}
            defaultValue={configuration.owner}
            placeholder="Type..."
            onChange={({ event }: any) => {
              if (handleConfigurationValueChanged) {
                handleConfigurationValueChanged(
                  event.currentTarget.name,
                  event.currentTarget.value
                );
              }
            }}
            onFocusOut={(event: any) => {
              if (validateOnFocusOut) {
                validateOnFocusOut(event);
              }
            }}
          />
        )}

        {mode === PromineoModalMode.View &&
          (configuration.owner ? configuration.owner : "-")}
      </>
    );
  }, [mode, configuration.owner, configuration.useOwnerVerification]);

  return (
    <div>
      <div className={classNames}>
        <ConnectorLabelWithContent
          mode={mode}
          addAsterisksymbol={true}
          label="Authentication type"
          content={<RenderAuthenticationModeComponent />}
        />
        <ConnectorLabelWithContent
          mode={mode}
          addAsterisksymbol={true}
          label="URL"
          content={<RenderUrlComponent />}
        />
      </div>

      {configuration.authenticationMode ===
        SafranApiConnectorAuthenticationMode.Basic && (
        <SafranConnectorBasicAuthenticationContent
          mode={mode}
          classNames={classNames}
          userName={configuration.username}
          password={configuration.password}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
          validateOnFocusOut={validateOnFocusOut}
        />
      )}

      {configuration.authenticationMode ===
        SafranApiConnectorAuthenticationMode.AzureAdAuthentication && (
        <SafranConnectorAzureAdAuthenticationContent
          configuration={configuration}
          mode={mode}
          classNames={classNames}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
          validateOnFocusOut={validateOnFocusOut}
        />
      )}
      {configuration.authenticationMode ===
        SafranApiConnectorAuthenticationMode.AzureAuthenticationWithClientSecret && (
        <SafranConnectorAzureAuthenticationWithClientSecretContent
          configuration={configuration}
          mode={mode}
          classNames={classNames}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
          validateOnFocusOut={validateOnFocusOut}
        />
      )}

      <div className={classNames}>
        <ConnectorLabelWithContent
          mode={mode}
          addAsterisksymbol={configuration.useOwnerVerification}
          label="Owner"
          content={<RenderOwnerComponent />}
        />

        <PromineoCheckbox
          disabled={mode === PromineoModalMode.View ? true : false}
          value={configuration.useOwnerVerification}
          onValueChange={(value: boolean) => {
            if (handleConfigurationValueChanged) {
              handleConfigurationValueChanged("useOwnerVerification", value);
            }
          }}
          text="Use Owner Verification"
          className="mt-3 ml-1"
        />
      </div>
    </div>
  );
}
