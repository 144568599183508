import InactiveExchangeAgreementListingResponse from "interfaces/response/NewExchangeAgreementListingResponse";
import { getFormattedDateTimeFromUtcDateObject } from "shared/utilities/DateTimeUtility";

export default function IeaStartDateCellComponent(e: {
  data: { data: InactiveExchangeAgreementListingResponse };
}) {
  return e.data.data.startDate ? (
    <>{getFormattedDateTimeFromUtcDateObject(e.data.data.startDate)}</>
  ) : (
    <>{"-"}</>
  );
}
