import { HostSystem } from "shared/enums/feature/HostSystem";
import { SupportedHostSystemConfigurationType } from "shared/types/HostSystemConfigurationTypes";
import { EditorMode } from "shared/enums/EditorMode";
import SafranExportConfig from "interfaces/host-system-config/safran/SafranExportConfig";
import AnalyticsSendingParameters from "features/setup/configs/host-system/analytics/AnalyticsSendingParameters";
import SapSendingParameters from "features/setup/configs/host-system/sap/SapSendingParameters";
import PrimaveraSendingParameterFields from "features/setup/configs/host-system/primavera/PrimaveraSendingParameterFields";
import MicrosoftProjectSendingParameters from "features/setup/configs/host-system/microsoftProject/MicrosoftProjectSendingParameters";
import MicrosoftProjectExportConfig from "interfaces/host-system-config/microsoftProject/MicrosoftProjectExportConfig";
import SafranSendingParameters from "features/setup/configs/host-system/safran/SafranSendingParameters";
import PrimaveraExportConfig from "interfaces/host-system-config/primavera/PrimaveraExportConfig";
import FileStorageSendingParameters from "features/setup/configs/host-system/fileStorage/FileStorageSendingParameters";
import AnalyticsExportConfig from "interfaces/host-system-config/analytics/AnalyticsExportConfig";

interface Props {
  mode: EditorMode;
  hostSystem?: HostSystem;
  hostSystemConfiguration?: SupportedHostSystemConfigurationType;
  connectorId: number;
  selectedCodeSet: number | null;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  height: number;
}

export default function SenderHostParameterOverrides(props: Props) {
  const {
    mode,
    height,
    hostSystem,
    hostSystemConfiguration,
    connectorId,
    selectedCodeSet,
    handleConfigurationValueChanged,
  } = props;

  if (!hostSystem) {
    return <></>;
  }

  return (
    <>
      {hostSystem === HostSystem.Safran && hostSystemConfiguration && (
        <SafranSendingParameters
          mode={mode}
          selectedConnectorId={connectorId}
          selectedCodeSet={selectedCodeSet}
          exportConfig={hostSystemConfiguration as SafranExportConfig}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
          height={height}
          isOverride={true}
        />
      )}

      {hostSystem === HostSystem.IlapAnalytics && hostSystemConfiguration && (
        <AnalyticsSendingParameters
          mode={mode}
          height={height}
          config={hostSystemConfiguration as AnalyticsExportConfig}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
          isOverride={true}
        />
      )}

      {hostSystem === HostSystem.SAP && (
        <SapSendingParameters height={height} isOverride={true} />
      )}

      {hostSystem === HostSystem.PrimaveraP6 && (
        <PrimaveraSendingParameterFields
          height={height}
          isOverride={true}
          mode={mode}
          selectedConnectorId={connectorId}
          exportConfig={hostSystemConfiguration as PrimaveraExportConfig}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
        />
      )}

      {hostSystem === HostSystem.MicrosoftProject && (
        <MicrosoftProjectSendingParameters
          height={height}
          mode={mode}
          selectedCodeSet={selectedCodeSet}
          selectedConnectorId={connectorId}
          exportConfig={hostSystemConfiguration as MicrosoftProjectExportConfig}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
          isOverride={true}
        />
      )}

      {hostSystem === HostSystem.FileStorage && (
        <FileStorageSendingParameters height={height} isOverride={true} />
      )}
    </>
  );
}
