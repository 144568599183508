import { EditorMode } from "shared/enums/EditorMode";
import AnalyticsImportConfig from "interfaces/host-system-config/analytics/AnalyticsImportConfig";
import AnalyticsReceivingFields from "./AnalyticsReceivingFields";
import ScrollView from "devextreme-react/scroll-view";

interface Props {
  mode: EditorMode;
  height: number;
  config: AnalyticsImportConfig;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  isOverride?: boolean;
}

export default function AnalyticsReceivingParameters(props: Props) {
  const { mode, isOverride, config, height, handleConfigurationValueChanged } =
    props;

  return !isOverride ? (
    <ScrollView showScrollbar="always" height={height}>
      <div className="flex space-x-6">
        <div className="w-60 font-semibold text-base text-textGray">
          Analytics Receiving Parameter
        </div>
        <AnalyticsReceivingFields
          config={config}
          mode={mode}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
        />
      </div>
    </ScrollView>
  ) : (
    <AnalyticsReceivingFields
      config={config}
      mode={mode}
      handleConfigurationValueChanged={handleConfigurationValueChanged}
    />
  );
}
