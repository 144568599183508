import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { PartnerPrimaryInformationProps } from "features/ieas/partner-information/PartnerConfigurationStepOne";
import ConfigForExchangeAgreementResponse from "interfaces/response/ConfigForExchangeAgreementResponse";
import ConfigResponse from "interfaces/response/ConfigResponse";
import ConnectorBaseResponse from "interfaces/response/connector/ConnectorBaseResponse";
import ConnectorScheduleSimplifiedResponse from "interfaces/response/ConnectorScheduleSimplifiedResponse";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { DirectionEnum } from "shared/enums/feature/DirectionEnum";
import { HostSystem } from "shared/enums/feature/HostSystem";
import {
  loadConfigForExchangeAgreementAsync,
  loadPartnerConfigForExchangeAgreementAsync,
} from "store/actions/ConfigActions";
import { useAppDispatch, useAppSelector } from "store/hooks";

interface PartnerPrimaryInformationRequest {
  connectorId: number;
  partnerRepresentativeIds: number[];
  selectedScheduleId: number;
}

type ReturnType = {
  primaryInformationProps: PartnerPrimaryInformationProps;
  primaryInformationRequest: PartnerPrimaryInformationRequest;
  isPrimaryInfoValid: boolean;
  dependentPropsForOtherHook: {
    hostSystem: HostSystem;
    codeSet?: number;
    hostParameters: string;
  };
  isModified: boolean;
};

const defaultPrimaryInfoRequest: PartnerPrimaryInformationRequest = {
  connectorId: 0,
  selectedScheduleId: 0,
  partnerRepresentativeIds: [],
};

export interface PartnerPrimaryInformationDataHookProps {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
}

export default function usePartnerPrimaryInformationProps(
  props: PartnerPrimaryInformationDataHookProps
): ReturnType {
  const { exchangeAgreement } = props;
  const dispatch = useAppDispatch();

  const [isModified, setIsModified] = useState(false);
  const [isPrimaryInfoValid, setIsPrimaryInfoValid] = useState(false);
  const [
    selectedPartnerRepresentativeIds,
    setSelectedPartnerRepresentativeIds,
  ] = useState<number[]>(
    (props.exchangeAgreement.partnerRepresentatives || []).map((r) => r.id)
  );
  const [availableSchedulesForPartner, setAvailableSchedulesForPartner] =
    useState<ConnectorScheduleSimplifiedResponse[]>([]);
  const [selectedPartnerConnectorId, setSelectedPartnerConnectorId] =
    useState<number>(0);
  const [selectedSchedule, setSelectedSchedule] =
    useState<ConnectorScheduleSimplifiedResponse>();
  const [availableConnectorsForPartner, setAvailableConnectorsForPartner] =
    useState<ConnectorBaseResponse[]>([]);
  const [primaryInfoRequest, setPrimaryInfoRequest] =
    useState<PartnerPrimaryInformationRequest>(defaultPrimaryInfoRequest);
  const [
    selectedConfigBasedOnPartnerConnectorAndSchedule,
    setSelectedConfigBasedOnPartnerConnectorAndSchedule,
  ] = useState<ConfigForExchangeAgreementResponse>();
  const [partnerConfig, setPartnerConfig] = useState<ConfigResponse>();

  const availableConfigsForPartner = useAppSelector(
    (state) => state.configData.partnerConfigForExchangeAgreement
  );

  useEffect(() => {
    if (exchangeAgreement.template) {
      // If Direction is OwnerToPartner,then owner is "Sender" and partner is "Receiver".
      // If Direction is PartnerToOwner,then partner is "Sender" and owner is "Receiver".

      let partnerDirection = ConfigDirection.Receiving;
      if (exchangeAgreement.direction === DirectionEnum.PartnerToOwner) {
        partnerDirection = ConfigDirection.Sending;
      }

      displayLoadingPanel();
      dispatch(
        loadPartnerConfigForExchangeAgreementAsync({
          templateId: exchangeAgreement.template.id,
          direction: partnerDirection,
        })
      ).finally(hideLoadingPanel);
    }

    const userIdentifiers = exchangeAgreement.partnerRepresentatives.map(
      (user) => user.id
    );

    setSelectedPartnerRepresentativeIds(userIdentifiers);
    setPrimaryInfoRequest((prev) => {
      return {
        ...prev,
        partnerRepresentativeIds: (userIdentifiers || []).filter((v) => v > 0),
      };
    });
  }, [exchangeAgreement]);

  useEffect(() => {
    if (
      primaryInfoRequest &&
      primaryInfoRequest.connectorId &&
      primaryInfoRequest.partnerRepresentativeIds.length &&
      primaryInfoRequest.selectedScheduleId
    ) {
      setIsPrimaryInfoValid(true);
    } else {
      setIsPrimaryInfoValid(false);
    }
  }, [primaryInfoRequest]);

  useEffect(() => {
    const createConnectorDataSourceFromPartnerConfigs = () => {
      const temporaryConnectorList: ConnectorBaseResponse[] = [];

      for (let index = 0; index < availableConfigsForPartner.length; index++) {
        temporaryConnectorList.push(
          availableConfigsForPartner[index].connector
        );
      }

      // Filtering for Unique values.
      let filteredConnectors = temporaryConnectorList.filter(
        (data, ind) =>
          ind === temporaryConnectorList.findIndex((val) => val.id === data.id)
      );

      setAvailableConnectorsForPartner(filteredConnectors);
    };

    createConnectorDataSourceFromPartnerConfigs();
  }, [availableConfigsForPartner]);

  useEffect(() => {
    const setScheduleDataSourceFromPartnerConfigs = () => {
      if (selectedPartnerConnectorId) {
        const temporaryScheduleList: ConnectorScheduleSimplifiedResponse[] = [];

        for (
          let index = 0;
          index < availableConfigsForPartner.length;
          index++
        ) {
          if (
            availableConfigsForPartner[index].connector?.id ===
            selectedPartnerConnectorId
          ) {
            temporaryScheduleList.push(
              ...availableConfigsForPartner[index].connectorSchedules
            );
          }
        }

        // Filtering for Unique values.
        let filteredSchedules = temporaryScheduleList.filter(
          (data, ind) =>
            ind === temporaryScheduleList.findIndex((val) => val.id === data.id)
        );

        setAvailableSchedulesForPartner(filteredSchedules);
      }
    };

    setScheduleDataSourceFromPartnerConfigs();
  }, [selectedPartnerConnectorId]);

  useEffect(() => {
    if (
      selectedPartnerConnectorId &&
      selectedSchedule &&
      availableConfigsForPartner
    ) {
      availableConfigsForPartner.forEach((config) => {
        if (config.connector.id === selectedPartnerConnectorId) {
          config.connectorSchedules.forEach((schedule) => {
            if (schedule.id === selectedSchedule.id) {
              setSelectedConfigBasedOnPartnerConnectorAndSchedule(config);
              return;
            }
          });
        }
      });
    } else {
      setSelectedConfigBasedOnPartnerConnectorAndSchedule(undefined);
    }
  }, [selectedPartnerConnectorId, selectedSchedule]);

  useEffect(() => {
    if (
      primaryInfoRequest.connectorId &&
      primaryInfoRequest.selectedScheduleId &&
      props.exchangeAgreement.template
    ) {
      displayLoadingPanel();
      dispatch(
        loadConfigForExchangeAgreementAsync({
          templateId: props.exchangeAgreement.template.id,
          connectorId: primaryInfoRequest.connectorId,
          scheduleId: primaryInfoRequest.selectedScheduleId,
          direction:
            props.exchangeAgreement.direction === DirectionEnum.OwnerToPartner
              ? ConfigDirection.Receiving
              : ConfigDirection.Sending,
        })
      )
        .unwrap()
        .then((config) => {
          setPartnerConfig(config);
        })
        .finally(hideLoadingPanel);
    }
  }, [primaryInfoRequest.connectorId, primaryInfoRequest.selectedScheduleId]);

  const handleConnectorSelectionChange = useCallback((connectorId: number) => {
    setIsModified(true);
    setSelectedPartnerConnectorId(connectorId);
    setSelectedSchedule(undefined);
    setPrimaryInfoRequest((prev) => {
      return { ...prev, connectorId, selectedScheduleId: 0 };
    });
  }, []);

  const handleScheduleSelectionChange = useCallback(
    (value: ConnectorScheduleSimplifiedResponse) => {
      setIsModified(true);
      setSelectedSchedule(value);
      setPrimaryInfoRequest((prev) => {
        return { ...prev, selectedScheduleId: value.id };
      });
    },
    []
  );

  const handlePartnerRepresentativeChange = useCallback((value: []) => {
    setSelectedPartnerRepresentativeIds(value);
    setPrimaryInfoRequest((prev) => {
      return {
        ...prev,
        partnerRepresentativeIds: (value || []).filter((v) => v > 0),
      };
    });
    if (value && value.length > 1) {
      setIsModified(true);
    }
  }, []);

  const response = useMemo<ReturnType>(() => {
    const getConnectorHostSystemName = () => {
      var hostSystemName =
        availableConnectorsForPartner.find(
          (con) => con.id === selectedPartnerConnectorId
        )?.hostSystemName ?? "-";
      return hostSystemName;
    };

    const getConnectorHostSystem = () => {
      var hostSystemName =
        availableConnectorsForPartner.find(
          (con) => con.id === selectedPartnerConnectorId
        )?.hostSystem ?? HostSystem.None;
      return hostSystemName;
    };

    const getConnectorExecutionComponentName = () => {
      var executionComponentName =
        availableConnectorsForPartner.find(
          (con) => con.id === selectedPartnerConnectorId
        )?.executionComponentText ?? "-";
      return executionComponentName;
    };

    return {
      isPrimaryInfoValid,
      isModified,
      primaryInformationProps: {
        configDirection:
          exchangeAgreement.direction === DirectionEnum.OwnerToPartner
            ? ConfigDirection.Receiving
            : ConfigDirection.Sending,
        connectorDataSource: availableConnectorsForPartner,
        scheduleDataSource: availableSchedulesForPartner,
        partnerTenantId: exchangeAgreement.partnerTenant?.id,
        partnerRepresentativeIds: exchangeAgreement.partnerRepresentatives.map(
          (pr) => pr.id
        ),
        isScheduleSelectionDisabled: !selectedPartnerConnectorId,
        selectedConnectorId: selectedPartnerConnectorId,
        partnerConfigId:
          selectedConfigBasedOnPartnerConnectorAndSchedule?.id ?? 0,
        partnerConfigTitle:
          selectedConfigBasedOnPartnerConnectorAndSchedule?.title ?? "",
        connectorHostSystemName: getConnectorHostSystemName(),
        executionComponentName: getConnectorExecutionComponentName(),
        onConnectorSelectionChange: handleConnectorSelectionChange,
        onScheduleSelectionChange: handleScheduleSelectionChange,
        onPartnerRepresentativeChange: handlePartnerRepresentativeChange,
      },
      primaryInformationRequest: primaryInfoRequest,
      dependentPropsForOtherHook: {
        hostSystem: getConnectorHostSystem(),
        codeSet: selectedSchedule?.userFieldSetId,
        hostParameters: partnerConfig?.hostSystemParameters ?? "",
      },
    };
  }, [
    isPrimaryInfoValid,
    exchangeAgreement,
    primaryInfoRequest,
    selectedSchedule,
    selectedPartnerRepresentativeIds,
    selectedPartnerConnectorId,
    availableConnectorsForPartner,
    availableSchedulesForPartner,
    selectedConfigBasedOnPartnerConnectorAndSchedule,
    partnerConfig,
    handleConnectorSelectionChange,
  ]);
  return response;
}
