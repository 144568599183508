import EditIcon from "components/icons/EditIcon";
import PromineoModalHeader from "components/modal/PromineoModalHeader";
import useLoadTenantStatusesHook from "hooks/LoadTenantStatusesHook";
import TenantDetailResponse from "interfaces/common/TenantDetailResponse";
import TenantResponse from "interfaces/response/tenant/TenantResponse";
import { TenantStatusCodes } from "shared/enums/TenantStatus";
import TenantStatusLabel from "../TenantStatusLabel";

interface Props {
  onEditIconClick: () => void;
  status: TenantStatusCodes;
  isEdit: boolean;
  tenant: TenantResponse | TenantDetailResponse;
  onClose: () => void;
}

export default function TenantDetailHeader(props: Props) {
  const { onEditIconClick, tenant, status, isEdit } = props;
  const tenantStatuses = useLoadTenantStatusesHook();

  return (
    <PromineoModalHeader
      onCancel={props.onClose}
      text={isEdit ? "Edit tenant" : "Tenant information"}
      showCloseIcon={!isEdit}
    >
      <div className="mt-2">
        <div className="flex justify-between">
          <div className="flex  items-center gap-4">
            <div className="text-15px leading-22px">{tenant.name}</div>
            {!isEdit && (
              <div className="cursor-pointer" onClick={onEditIconClick}>
                <EditIcon />
              </div>
            )}
          </div>
          <TenantStatusLabel
            tenantStatuses={tenantStatuses}
            tenantStatus={status}
            readonly={true}
            tenantId={tenant.id}
          />
        </div>
      </div>
    </PromineoModalHeader>
  );
}
