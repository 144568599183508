import {
  deleteDataAsync,
  getDataAsync,
  postDataAsync,
  putDataAsync,
} from "./API";
import ConnectorResponse from "interfaces/response/connector/ConnectorResponse";
import ConnectorWriteBaseRequest from "interfaces/request/ConnectorWriteBaseRequest";
import ConnectorDetailsResponse from "interfaces/response/connector/ConnectorDetailsResponse";
import ConnectorBaseResponse from "interfaces/response/connector/ConnectorBaseResponse";
import ConnectorModifyRequest from "interfaces/request/ConnectorModifyRequest";
import ConnectorEventCreateRequest from "interfaces/request/ConnectorEventCreateRequest";
import ConnectorEventStatusResponse from "interfaces/response/connector/ConnectorEventStatusResponse";
import HostFieldResponse from "interfaces/response/HostFieldResponse";
import ConnectorScheduleResponse from "interfaces/response/ConnectorScheduleResponse";

const connectorRouteUrl = "Connectors";

export const getConnectorsForCurrentUserAsync = async () => {
  const url = `Users/${connectorRouteUrl}`;

  return await getDataAsync<ConnectorResponse[]>(url);
};

export const getConnectorAsync = async (connectorId: number) => {
  const url = `${connectorRouteUrl}/${connectorId}`;

  return await getDataAsync<ConnectorResponse>(url);
};

export const getConnectorDetailsAsync = async (connectorId: number) => {
  const url = `${connectorRouteUrl}/Detail/${connectorId}`;

  return await getDataAsync<ConnectorDetailsResponse>(url);
};

export const createConnectorAsync = async (
  connectorRequest: ConnectorWriteBaseRequest
) => {
  return await postDataAsync<
    ConnectorWriteBaseRequest,
    ConnectorResponse
  >(connectorRouteUrl, connectorRequest);
};

export const updateConnectorAsync = async (
  connectorId: number,
  connectorRequest: ConnectorModifyRequest
) => {
  const url = `${connectorRouteUrl}/${connectorId}`;

  return await putDataAsync<ConnectorModifyRequest, ConnectorResponse>(
    url,
    connectorRequest
  );
};

export const deleteConnectorAsync = async (connectorId: number) => {
  const url = `${connectorRouteUrl}/${connectorId}`;
  await deleteDataAsync(url, false);

  return connectorId;
};

export const getSimplifiedConnectorsAsync = async () => {
  const url = `${connectorRouteUrl}/Simplified?connectorsOnlyHavingSchedules=true`;
  return await getDataAsync<ConnectorBaseResponse[]>(url);
};

export const generateConnectorEventAsync = async (
  connectorEventCreateRequest: ConnectorEventCreateRequest
) => {
  const url = `${connectorRouteUrl}/${connectorEventCreateRequest.connectorId}/Event`;

  return await postDataAsync<any, any>(url, connectorEventCreateRequest);
};

export const getConnectorEventStatusAsync = async (connectorId: number) => {
  const url = `${connectorRouteUrl}/${connectorId}/Event/Status`;

  return await getDataAsync<ConnectorEventStatusResponse>(url);
};

export const getConnectorHostFieldsAsync = async (
  connectorId: number,
  includeAll: boolean = true,
  userFieldSetId: number = 0,
  includeAllWithNullUserFieldId: boolean = false
) => {
  const url = `${connectorRouteUrl}/${connectorId}/HostFields?includeAll=${includeAll ? "true" : "false"}&includeAllWithNullUserFieldId=${includeAllWithNullUserFieldId ? "true" : "false"}${userFieldSetId ? `&userFieldId=${userFieldSetId}` : ""}`;
  return await getDataAsync<HostFieldResponse[]>(url);
};

export const getConnectorHostSchdulesAsync = async (connectorId: number) => {
  const url = `${connectorRouteUrl}/${connectorId}/Schedules`;

  return await getDataAsync<ConnectorScheduleResponse[]>(url);
};
