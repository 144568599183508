import IEAWizardInformation, {
  WizardInformationTextStyles,
} from "features/ieas/common/IEAWizardInformation";
import OwnerConfigurationForNewIEA, {
  OwnerConfigurationProps,
} from "../OwnerConfigurationForNewIEA";

export interface StepTwoNewIeaProps {
  ownerConfigurationProps: OwnerConfigurationProps;
}

export default function StepTwoNewIEA(props: StepTwoNewIeaProps) {
  return (
    <div>
      <IEAWizardInformation
        textComponent={
          <div className={WizardInformationTextStyles}>
            Complete your configuration by selecting the connector and the
            schedule identifier. These fields are required (
            <span className="text-red font-bold">*</span>). Optionally, you can
            also add more owner representatives.
          </div>
        }
      ></IEAWizardInformation>
      <div className="mt-3">
        <OwnerConfigurationForNewIEA {...props.ownerConfigurationProps} />
      </div>
    </div>
  );
}
