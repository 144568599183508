import TextBox, { ITextBoxOptions } from "devextreme-react/text-box";
import Validator, { PatternRule, RequiredRule } from "devextreme-react/validator";
import { useEffect, useState } from "react";
import { GetNewId } from "shared/utilities/CommonUtility";
import "./styles/PromineoTextBox.css";

export interface TextPatternRule {
  pattern: string;
  message?: string;
}

export interface PromineoTextValidationOptions {
  patternRules?: TextPatternRule[];
}

interface Props {
  fieldName?: string;
  size?: "normal" | "large";
  required?: boolean;
  validationOptions?: PromineoTextValidationOptions;
}

export default function PromineoTextBox(props: Props & ITextBoxOptions) {
  const { fieldName, required, validationOptions, size, className } = props;
  const [validatorRules, setValidatorRules] = useState<any[]>([]);

  useEffect(() => {
    const setValidationRules = () => {
      const rules = [];
      if (required) {
        const rule = (
          <RequiredRule
            key={`${GetNewId()}-${fieldName}`}
            message={`${fieldName} is required.`}
          />
        );
        rules.push(rule);
      }

      if (validationOptions){
        if (validationOptions.patternRules) {
          validationOptions.patternRules.forEach((rule) => {
            const errorMessage = rule.message ?? `Input does not match the required pattern '${rule.pattern}'.`;
            const patternRule = (
              <PatternRule key={`${GetNewId()}-${fieldName}`} pattern={rule.pattern} message={errorMessage}/>
            );
            rules.push(patternRule);
          });
        }
      }

      setValidatorRules(rules);
    };

    setValidationRules();
  }, [fieldName, required, validationOptions]);

  return (
    <TextBox
      {...props}
      className={`promineo-textbox size-${size ?? "normal"} ${className}`}
    >
      <Validator>{validatorRules}</Validator>
    </TextBox>
  );
}
