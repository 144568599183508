import CopyIcon from "components/icons/CopyIcon";
import DataTransferEventLogResponse from "interfaces/response/DataTransferEventLogResponse";
import { copyToClipboard } from "shared/utilities/CommonUtility";
import { getFormattedDateTime } from "shared/utilities/DateTimeUtility";

interface Props {
  logDetails: DataTransferEventLogResponse[];
}

export default function CopyLogButton(props: Props) {
  const handleCopyClick = () => {
    let detailsLog = "";

    for (let index = 0; index < props.logDetails.length; index++) {
      detailsLog = `${detailsLog}${getFormattedDateTime(
        props.logDetails[index].time
      )} ${props.logDetails[index].message}${"\n"}`;
    }
    
    copyToClipboard(detailsLog);
  };

  return (
    <button onClick={handleCopyClick}>
      <div>
        <CopyIcon />
      </div>
    </button>
  );
}
