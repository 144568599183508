import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import FileStorageConnectorConfiguration from "interfaces/response/connector/FileStorageConnectorConfiguration";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import { loadFileStorageConnectorAuthenticationModeValuesAsync } from "store/actions/DropdownValueActions";
import { AppDispatch, RootState } from "store/store";
import ConnectorLabelWithContent from "../../ConnectorLabelWithContent";
import { FileStorageConnectorAuthenticationMode } from "shared/enums/feature/file-storge-host-system/FileStorageConnectorAuthenticationMode";
import FileStorageBasicAuthenticationComponent from "./FileStorageBasicAuthenticationComponent";
import FileStorageManagedIdentityAuthenticationComponent from "./FileStorageManagedIdentityAuthenticationComponent";
import FileStorageFolderNameComponent from "./FileStorageFolderNameComponent";

interface Props {
  configuration: FileStorageConnectorConfiguration;
  mode: PromineoModalMode;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  handleAuthenticationModeValueChanged?: (value: number) => void;
  validateOnFocusOut?: (event: any) => void;
}

export default function FileStorageConnectorInformation(props: Props) {
  const {
    configuration,
    mode,
    handleConfigurationValueChanged,
    handleAuthenticationModeValueChanged,
    validateOnFocusOut,
  } = props;

  const dispatch = useDispatch<AppDispatch>();

  const fileStorageAuthenticationModes = useSelector(
    (state: RootState) =>
      state.dropdownData.fileStorageConnectorAuthenticationMode
  );

  useEffect(() => {
    if (
      mode === PromineoModalMode.Create ||
      mode === PromineoModalMode.Modify
    ) {
      displayLoadingPanel();
      dispatch(loadFileStorageConnectorAuthenticationModeValuesAsync()).finally(
        hideLoadingPanel
      );
    }
  }, [dispatch, mode]);

  const RenderAuthenticationModeComponent = useCallback(() => {
    return (
      <>
        {(mode === PromineoModalMode.Create ||
          mode === PromineoModalMode.Modify) && (
          <PromineoSelectBox
            placeholder="Select"
            width={mode === PromineoModalMode.Create ? 330 : 188}
            fieldName="Authentication Mode"
            required={true}
            items={fileStorageAuthenticationModes}
            value={configuration.authenticationMode}
            valueExpr={"value"}
            displayExpr={"name"}
            onValueChange={(value: number) => {
              if (handleAuthenticationModeValueChanged) {
                handleAuthenticationModeValueChanged(value);
              }
            }}
            onFocusOut={(event: any) => {
              if (validateOnFocusOut) {
                validateOnFocusOut(event);
              }
            }}
          />
        )}

        {mode === PromineoModalMode.View &&
          configuration.authenticationModeDisplayText}
      </>
    );
  }, [
    mode,
    fileStorageAuthenticationModes,
    configuration.authenticationMode,
    configuration.authenticationModeDisplayText,
  ]);

  return (
    <div>
      <div
        className={
          props.mode === PromineoModalMode.View ||
          props.mode === PromineoModalMode.Modify
            ? "grid grid-cols-2 gap-x-60"
            : ""
        }
      >
        <ConnectorLabelWithContent
          mode={mode}
          addAsterisksymbol={true}
          label="Authentication type"
          content={<RenderAuthenticationModeComponent />}
        />
      </div>

      {configuration.authenticationMode ===
        FileStorageConnectorAuthenticationMode.Basic && (
        <FileStorageBasicAuthenticationComponent
          mode={mode}
          blobConnectionString={configuration.blobConnectionString}
          blobContainerName={configuration.blobContainerName}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
          validateOnFocusOut={validateOnFocusOut}
        />
      )}

      {configuration.authenticationMode ===
        FileStorageConnectorAuthenticationMode.ManagedIdentity && (
        <FileStorageManagedIdentityAuthenticationComponent
          mode={mode}
          containerEndpoint={configuration.containerEndpoint}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
          validateOnFocusOut={validateOnFocusOut}
        />
      )}

      <FileStorageFolderNameComponent 
        mode={mode}
        folderName={configuration.folderName}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
    </div>
  );
}
