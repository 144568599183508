import LabelWithCircle from "components/common/LabelWithCircle";
import ClientResponse from "interfaces/response/autonomous-components/ClientResponse";
import { PromineoColorVariant } from "shared/enums/PromineoColorVariantEnum";
import { AutonomousComponentLastRequestReceiveStatus } from "shared/enums/feature/AutonomousComponentLastRequestReceiveStatus";
import { getFormattedDateTime } from "shared/utilities/DateTimeUtility";

export default function AutonomousComponentLastRequestReceiveStatusComponent(
  props: any
) {
  const autonomousComponent = props.data as ClientResponse;
  let circleColor = PromineoColorVariant.LightGray;

  if (
    autonomousComponent.requestReceiveStatus ===
    AutonomousComponentLastRequestReceiveStatus.ReceivedIn6Minutes
  ) {
    circleColor = PromineoColorVariant.IlapGreen;
  } else if (
    autonomousComponent.requestReceiveStatus ===
    AutonomousComponentLastRequestReceiveStatus.ReceivedIn60Minutes
  ) {
    circleColor = PromineoColorVariant.Orange;
  } else if (
    autonomousComponent.requestReceiveStatus ===
    AutonomousComponentLastRequestReceiveStatus.NotReceivedIn60Minutes
  ) {
    circleColor = PromineoColorVariant.Red;
  }

  let label = autonomousComponent.lastRequestReceived
    ? getFormattedDateTime(autonomousComponent.lastRequestReceived)
    : "";

  return (
    <LabelWithCircle
      color={circleColor}
      text={label}
      tooltip={autonomousComponent.requestReceiveStatusText}
    />
  );
}
