import LabelWithContent from "components/common/LabelWithContent";
import { NewExchangeAgreementActionRequired } from "shared/enums/feature/NewExchangeAgreementActionRequired";

interface Props {
  isPartner: boolean;
  requiredAction: NewExchangeAgreementActionRequired | undefined;
  selectedPartnerConnector: { executionComponentText: string } | null;
  exchangeAgreement: {
    partnerConnector: { executionComponentText: string } | null;
  };
  labelClassName: string;
}

export default function ExecutionComponentField({
  isPartner,
  requiredAction,
  selectedPartnerConnector,
  exchangeAgreement,
  labelClassName,
}: Props) {
  return (
    <>
      {isPartner &&
      requiredAction === NewExchangeAgreementActionRequired.Complete ? (
        <LabelWithContent
          label="Execute component"
          content={
            <div className={labelClassName}>
              {selectedPartnerConnector
                ? selectedPartnerConnector.executionComponentText
                : "-"}
            </div>
          }
        />
      ) : (
        <LabelWithContent
          label="Execute component"
          content={
            <div className={labelClassName}>
              {exchangeAgreement.partnerConnector
                ? exchangeAgreement.partnerConnector.executionComponentText
                : "-"}
            </div>
          }
        />
      )}
    </>
  );
}
