import PromineoTextBox from "components/common/controls/PromineoTextBox";
import SafranApiConnectorConfigurationResponse from "interfaces/response/connector/SafranApiConnectorConfigurationResponse";
import { useCallback } from "react";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import { getHiddenPasswordPlaceHolder } from "shared/utilities/CommonUtility";
import ConnectorLabelWithContent from "../../ConnectorLabelWithContent";

interface Props {
  configuration: SafranApiConnectorConfigurationResponse;
  mode: PromineoModalMode;
  classNames?: string;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  validateOnFocusOut?: (event: any) => void;
}

export default function SafranConnectorAzureAuthenticationWithClientSecretContent(props: Props) {
  const {
    configuration,
    mode,
    classNames,
    handleConfigurationValueChanged,
    validateOnFocusOut
  } = props;

  const hiddenClientSecret = getHiddenPasswordPlaceHolder(configuration.clientSecret);

  const RenderTenantIdComponent = useCallback(() => {
    return (
      <>
        {(mode === PromineoModalMode.Create || mode === PromineoModalMode.Modify) && (
          <PromineoTextBox
            width={mode === PromineoModalMode.Create ? 330 : 188}
            name="adTenantId"
            fieldName="Ad Tenant Id"
            required={true}
            defaultValue={configuration.adTenantId}
            placeholder="Type..."
            onChange={({ event }: any) => {
              if (handleConfigurationValueChanged) {
                handleConfigurationValueChanged(
                  event.currentTarget.name,
                  event.currentTarget.value
                );
              }
            }}
            onFocusOut={(event: any) => {
              if (validateOnFocusOut) {
                validateOnFocusOut(event);
              }
            }}
          />
        )}

        {mode === PromineoModalMode.View && (
          configuration.adTenantId
        )}
      </>
    );
  }, [mode, configuration.adTenantId]);

  const RenderApplicationIdComponent = useCallback(() => {
    return (
      <>
        {(mode === PromineoModalMode.Create || mode === PromineoModalMode.Modify) && (
          <PromineoTextBox
            width={mode === PromineoModalMode.Create ? 330 : 188}
            name="clientApplicationId"
            fieldName="Client Application Id"
            required={true}
            defaultValue={configuration.clientApplicationId}
            placeholder="Type..."
            onChange={({ event }: any) => {
              if (handleConfigurationValueChanged) {
                handleConfigurationValueChanged(
                  event.currentTarget.name,
                  event.currentTarget.value
                );
              }
            }}
            onFocusOut={(event: any) => {
              if (validateOnFocusOut) {
                validateOnFocusOut(event);
              }
            }}
          />
        )}

        {mode === PromineoModalMode.View && (
          configuration.clientApplicationId
        )}
      </>
    );
  }, [mode, configuration.clientApplicationId]);

  const RenderClientSecretComponent = useCallback(() => {
    return (
      <>
        {(mode === PromineoModalMode.Create || mode === PromineoModalMode.Modify) && (
          <PromineoTextBox
            width={mode === PromineoModalMode.Create ? 330 : 188}
            name="clientSecret"
            fieldName="Client Secret"
            required={true}
            mode="password"
            defaultValue={configuration.clientSecret}
            placeholder="Type..."
            onChange={({ event }: any) => {
              if (handleConfigurationValueChanged) {
                handleConfigurationValueChanged(
                  event.currentTarget.name,
                  event.currentTarget.value
                );
              }
            }}
            onFocusOut={(event: any) => {
              if (validateOnFocusOut) {
                validateOnFocusOut(event);
              }
            }}
          />
        )}

        {mode === PromineoModalMode.View && (
          hiddenClientSecret
        )}
      </>
    );
  }, [mode, configuration.clientSecret, hiddenClientSecret, handleConfigurationValueChanged, validateOnFocusOut]);

  const RenderScopesComponent = useCallback(() => {
    return (
      <>
        {(mode === PromineoModalMode.Create || mode === PromineoModalMode.Modify) && (
          <PromineoTextBox
            width={mode === PromineoModalMode.Create ? 330 : 188}
            name="scopes"
            fieldName="Scopes"
            required={true}
            defaultValue={configuration.scopes}
            placeholder="Type..."
            onChange={({ event }: any) => {
              if (handleConfigurationValueChanged) {
                handleConfigurationValueChanged(
                  event.currentTarget.name,
                  event.currentTarget.value
                );
              }
            }}
            onFocusOut={(event: any) => {
              if (validateOnFocusOut) {
                validateOnFocusOut(event);
              }
            }}
          />
        )}

        {mode === PromineoModalMode.View && (
          configuration.scopes
        )}
      </>
    );
  }, [mode, configuration.scopes, handleConfigurationValueChanged, validateOnFocusOut]);

  return (
    <div className={classNames}>
      <ConnectorLabelWithContent
        mode={mode}
        addAsterisksymbol={true}
        label="Directory (Tenant) Id"
        content={<RenderTenantIdComponent />}
      />
      <ConnectorLabelWithContent
        mode={mode}
        addAsterisksymbol={true}
        label="Application (Client) Id"
        content={<RenderApplicationIdComponent />}
      />
      <ConnectorLabelWithContent
        mode={mode}
        addAsterisksymbol={true}
        label="Scopes"
        content={<RenderScopesComponent />}
      />
      <ConnectorLabelWithContent
        mode={mode}
        addAsterisksymbol={true}
        label="Client Secret"
        content={<RenderClientSecretComponent />}
      />
    </div>
  );
}