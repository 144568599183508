import PromineoMultilineTextEdit from "components/common/controls/PromineoMultilineTextEdit";
import LabelHolder from "components/common/LabelHolder";
import LabelResponse from "interfaces/response/LabelResponse";
import { useCallback, useState } from "react";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";
import { FrequencyType } from "shared/enums/feature/FrequencyType";
import { SupportedLabelEntity } from "shared/enums/feature/SupportedLabelEntity";
import { getValidMinimumExpirationDate } from "shared/utilities/ExchangeAgreementUtility";
import LabelWithContent from "../../../components/common/LabelWithContent";
import DateEditor from "./DateEditor";
import FrequencyEditor from "./FrequencyEditor";

export interface IeaScheduleProps {
  ownerExchangeRole: ExchangeRoleEnum;
  frequencyType?: FrequencyType;
  startDate: string;
  expirationDate: string;
  executionCronExpression?: string;
  lagInMinutes?: number;
  labelIdentifiers?: number[];
  comments?: string;
  onValueChange: (fieldName: string, value: any) => void;
  onStartDateChange: (value: string) => void;
  onExpirationDateChange: (value: string) => void;
  onCommentChange: (value: string) => void;
  onLabelIndentifiersChange: (values: number[]) => void;
  onPredecessorAgreementIdChange?: (value: any) => void;
  onLagMinutesChange?: (value: any) => void;
  onFrequencyTypeChange?: (value: FrequencyType) => void;
  onCronExpressionChange: (value: string) => void;
}

export default function IEAScheduling(props: IeaScheduleProps) {
  const { onValueChange } = props;
  const [labels, setLabels] = useState<LabelResponse[]>([]);
  const [validMinDate, setValidMinDate] = useState<string | undefined>(
    undefined
  );

  const handleDeleteLabel = (label: LabelResponse) => {
    let filteredLabels = labels.filter(
      (l) => l.id !== label.id || l._key_ !== label._key_
    );

    onLabelUpdate(filteredLabels);
    setLabels(filteredLabels);
  };

  const preSelectLabels = (
    allLabels: LabelResponse[],
    preSelectionLabelIds: number[]
  ) => {
    let labelsToPreSelect: LabelResponse[] = [];
    allLabels.forEach((label) => {
      if (preSelectionLabelIds.find((labelId) => labelId === label.id)) {
        labelsToPreSelect.push(label);
      }
    });
    setLabels([...labels, ...labelsToPreSelect]);
  };

  const handleAddNewLabel = (label: LabelResponse) => {
    let newLabels = [...labels, label];
    onLabelUpdate(newLabels);
    setLabels(newLabels);
  };

  const onLabelUpdate = (labelList: LabelResponse[]) => {
    const labelIdentifiers = labelList.map((label) => label.id);
    onValueChange("labelIdentifiers", labelIdentifiers);
    props.onLabelIndentifiersChange(labelIdentifiers);
  };

  const handleStartDateChange = useCallback((value: string) => {
    props.onStartDateChange(value);
    if (value) {
      setValidMinDate(getValidMinimumExpirationDate(value));
    } else {
      setValidMinDate(undefined);
    }
  }, [setValidMinDate]);

  return (
    <div className="space-y-4">
      <div className="flex gap-x-8 border-b pb-4 border-lightGray">
        <div className="space-y-4">
          <DateEditor
            width={328}
            title="Start date"
            defaultValue={props.startDate}
            onValueChange={handleStartDateChange}
            tooltipText="The date from which transfer events will be generated."
          />
          <DateEditor
            width={328}
            value={props.expirationDate}
            title="Expiration date"
            onValueChange={props.onExpirationDateChange}
            min={validMinDate}
            disabled={!props.startDate}
            tooltipText="The date that transfer events will no longer be generated and the IEA moves to the IEA Archive."
          />
        </div>
        <FrequencyEditor
          ownerExchangeRole={props.ownerExchangeRole!}
          onValueChange={props.onValueChange!}
          defaultValue={props.executionCronExpression}
          lagInMinutes={props.lagInMinutes}
          onPredecessorAgreementIdChange={props.onPredecessorAgreementIdChange}
          onLagMinutesChange={props.onLagMinutesChange}
          onFrequencyTypeChange={props.onFrequencyTypeChange}
          onCronExpressionChange={props.onCronExpressionChange}
          frequencyType={props.frequencyType}
          tooltipText="The rate at which transfer events will be generated."
        />
      </div>

      <LabelWithContent
        label="IEA labels"
        content={
          <LabelHolder
            preSelectLabels={preSelectLabels}
            preSelectionLabelIds={props.labelIdentifiers}
            labels={labels}
            labelType={SupportedLabelEntity.ExchangeAgreement}
            allowAdd={true}
            allowDelete={true}
            onAddLabel={handleAddNewLabel}
            onDelete={handleDeleteLabel}
          />
        }
        tooltipText="Additional, short pieces of information that allow for finding and organising IEAs."
      />

      <LabelWithContent
        label="Comments"
        content={
          <PromineoMultilineTextEdit
            height={157}
            width={328}
            value={props.comments}
            placeholder="Type..."
            className="whitespace-pre-line text-10px font-Inter default-background"
            onValueChanged={(e: any) => {
              onValueChange("comments", e.event?.target.value);
              props.onCommentChange(e.event?.target.value);
            }}
          />
        }
        tooltipText="Additional information about the IEA."
      />
    </div>
  );
}
