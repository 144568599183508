import { ConfigDirection } from "shared/enums/feature/ConfigDirection";

export const getConnectorSelectionTooltipText = (
  configDirection: ConfigDirection
) => {
  return `${
    configDirection === ConfigDirection.Sending
      ? "The location where you want to send data from"
      : "The location from where you want to receive data"
  }. Not sure which one to select, or not able to find the right connector? Contact a Setup admin within your tenant.`;
};

export const getScheduleSelectionTooltipText = (
  configDirection: ConfigDirection
) => {
  return `${
    configDirection === ConfigDirection.Sending
      ? "The schedule from which you want to send data"
      : "The schedule from where you want to receive data"
  }. Not sure which one to select, or not able to find the right schedule? Contact a Setup admin within your tenant.`;
};

export const getRepresentativeSelectionTooltipText = () => {
  return "The people in your tenant that are able to see and perform actions related to this IEA.";
};

export const getConfigNameDisplayTooltipText = (
  configDirection: ConfigDirection
) => {
  return `The definitions for how the data is ${
    configDirection === ConfigDirection.Sending ? "sent from" : "received into"
  } the host system. Need help? Reach out to a Setup admin in your tenant.`;
};
