import ActiveExchangeAgreementListingResponse from "interfaces/response/ActiveExchangeAgreementListingResponse";
import { getFormattedDateTimeFromUtcDateObject } from "shared/utilities/DateTimeUtility";

export default function LastSucessfulTransferCellComponent(e: {
  data: { data: ActiveExchangeAgreementListingResponse };
}) {
  return e.data.data.lastSuccessfulTransfer ? (
    <>{getFormattedDateTimeFromUtcDateObject(e.data.data.lastSuccessfulTransfer)}</>
  ) : (
    <>{"-"}</>
  );
}
