import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import PromineoModal from "components/modal/PromineoModal";
import ScrollView from "devextreme-react/scroll-view";

interface Props {
  validationMessagForDelete: string[];
  connectorName: string;
  connectorId: number;
  onClose: () => void;
}

export default function ConnectorDeleteValidationDialog(props: Props) {
  const { validationMessagForDelete, onClose, connectorName, connectorId } = props;
  const connectorDeleteErrorTitle = "Connector cannot be deleted";
  return (
    <PromineoModal
      width={500}
      height={"auto"}
      isVisible={!!validationMessagForDelete}
    >
      <div className="text-center">
        <div className="text-xl mb-4">{connectorDeleteErrorTitle}</div>
        <div className="mb-4 text-xs">
              {`The connector that you are trying to delete ('${connectorName}' with id=${connectorId}) is in use by the following items (please delete and try again):`}
        </div>
        <ScrollView height={480}>
          <div>
            <ul className="text-xs">
              {validationMessagForDelete.map(
                (m, ind) => {
                  return <li key={ind}>{m}</li>;
                }
              )}
            </ul>
          </div>
        </ScrollView>
      </div>
      <div className="mt-4 py-4">
        <PromineoButton
          text="Back"
          variant={PromineoButtonType.Secondary}
          onClick={onClose}
        />
      </div>
    </PromineoModal>
  );
}
