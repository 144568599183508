import LabelWithContent from "components/common/LabelWithContent";
import EditButton from "components/common/controls/buttons/EditButton";
import SaveButton from "components/common/controls/buttons/SaveButton";
import { useLoadTenantUsers } from "hooks/LoadTenantUserHook";
import UserSimplifiedResponse from "interfaces/response/UserSimplifiedResponse";
import { useState } from "react";
import RepresentativeComponent from "../RepresentativeComponent";

interface RepresentativeEditConfig {
  isOwner?: boolean;
  allowEdit?: boolean;
  selectedRepresentatives: UserSimplifiedResponse[];
  onSaveOwnerRepresentative: () => void;
}

interface Props {
  editStatus: "create" | "edit";
  editConfig?: RepresentativeEditConfig;
  ownerTenantId: number;
  selectedRepresentativeIds: number[];
  onOwnerRepresentativeChange: (representativeIds: number[]) => void;
}

const AddRepresentativeField = (props: {
  tenantUsers: UserSimplifiedResponse[];
  selectedRepresentativeIds: number[];
  onOwnerRepresentativeChange: (representativeIds: number[]) => void;
}) => {
  return (
    <LabelWithContent
      label="Owner representatives"
      content={
        <div className="space-y-2">
          <RepresentativeComponent
            width={300}
            showAddButton={true}
            canRemoveChip={true}
            totalSupportedLines={2}
            componentId="owner-representatives-dropdown"
            selectedUserIds={props.selectedRepresentativeIds}
            representativeList={props.tenantUsers}
            onSelectedItemsChange={props.onOwnerRepresentativeChange}
          />
        </div>
      }
    />
  );
};

const EditRepresentativeField = (
  props: RepresentativeEditConfig & {
    tenantUsers: UserSimplifiedResponse[];
    selectedRepresentativeIds: number[];
    onOwnerRepresentativeChange: (representativeIds: number[]) => void;
  }
) => {
  const [isOwnerRepresentativeEditable, setIsOwnerRepresentativeEditable] =
    useState(false);

  const handleSaveOwnerRepresentativeClick = () => {
    setIsOwnerRepresentativeEditable(false);
    props.onSaveOwnerRepresentative();
  };

  const handleEditOwnerRepresentativeClick = () => {
    setIsOwnerRepresentativeEditable(true);
  };

  return (
    <LabelWithContent
      label={
        <div className="flex space-x-2">
          <div>Owner representatives</div>
          <div className="-mt-1">
            {props.allowEdit ? (
              isOwnerRepresentativeEditable ? (
                <SaveButton
                  classNames="w-6 h-6"
                  onSaveClick={handleSaveOwnerRepresentativeClick}
                />
              ) : (
                <EditButton
                  classNames="w-6 h-6"
                  onEditClick={handleEditOwnerRepresentativeClick}
                />
              )
            ) : (
              ""
            )}
          </div>
        </div>
      }
      content={
        <RepresentativeComponent
          width={300}
          showAddButton={
            props.allowEdit === true && isOwnerRepresentativeEditable
          }
          canRemoveChip={
            props.allowEdit === true && isOwnerRepresentativeEditable
          }
          totalSupportedLines={2}
          componentId="owner-representatives-dropdown"
          selectedUserIds={props.selectedRepresentativeIds}
          representativeList={props.tenantUsers}
          onSelectedItemsChange={props.onOwnerRepresentativeChange}
        />
      }
    />
  );
};

export default function OwnerRepresentativeField(props: Props) {
  const tnantUsers = useLoadTenantUsers(props.ownerTenantId);

  return props.editStatus === "create" && props.editConfig?.allowEdit ? (
    <AddRepresentativeField
      tenantUsers={tnantUsers}
      selectedRepresentativeIds={props.selectedRepresentativeIds}
      onOwnerRepresentativeChange={props.onOwnerRepresentativeChange}
    />
  ) : props.editConfig ? (
    <EditRepresentativeField
      {...props.editConfig}
      tenantUsers={tnantUsers}
      selectedRepresentativeIds={props.selectedRepresentativeIds}
      onOwnerRepresentativeChange={props.onOwnerRepresentativeChange}
    />
  ) : (
    <></>
  );
}
