import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import ConfigForExchangeAgreementResponse from "interfaces/response/ConfigForExchangeAgreementResponse";
import ConnectorScheduleSimplifiedResponse from "interfaces/response/ConnectorScheduleSimplifiedResponse";
import ConnectorBaseResponse from "interfaces/response/connector/ConnectorBaseResponse";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { DirectionEnum } from "shared/enums/feature/DirectionEnum";
import { NewExchangeAgreementActionRequired } from "shared/enums/feature/NewExchangeAgreementActionRequired";
import { loadPartnerConfigForExchangeAgreementAsync } from "store/actions/ConfigActions";
import { loadExportScopeValuesAsync } from "store/actions/DropdownValueActions";
import { AppDispatch, RootState } from "store/store";
import LabelWithContent from "../../components/common/LabelWithContent";
import ConfigNameDisplay from "./ConfigNameDisplay";
import ConnectorField from "./ConnectorField";
import ExecutionComponentField from "./ExecutionComponentField";
import HostSystemField from "./HostSystemField";
import { PartnerConfigurationDetailProps } from "./PartnerConfiguration";
import ScheduleIdentifierField from "./ScheduleIdentifierField";
import PartnerRepresentativeField from "./active-ieas/PartnerRepresentativeField";

export default function PartnerConfigurationDetail(
  props: PartnerConfigurationDetailProps
) {
  const {
    exchangeAgreement,
    isCurrentUserPartner,
    selectedPartnerConnectorId,
    handlePartnerRepresentativeChange,
    handlePartnerConnectorChange,
    handlePartnerScheduleChange,
    handleSavePartnerRepresentativeClick,
    selectedPartnerScheduleId,
  } = props;

  const dispatch = useDispatch<AppDispatch>();
  const labelClassName =
    "ml-2 w-full pr-6 font-inter font-medium text-[12px] leading-15px";

  const [requiredAction, setRequiredAction] = useState(
    exchangeAgreement.actionRequiredIfInactive
  );
  const [selectedPartnerConnector, setSelectedPartnerConnector] =
    useState<ConnectorBaseResponse>(exchangeAgreement.partnerConnector);
  const [connectorDataSource, setConnectorDataSource] = useState<
    ConnectorBaseResponse[]
  >([]);
  const [scheduleDataSource, setScheduleDataSource] = useState<
    ConnectorScheduleSimplifiedResponse[]
  >([]);
  const [
    selectedConfigBasedOnPartnerConnectorAndSchedule,
    setSelectedConfigBasedOnPartnerConnectorAndSchedule,
  ] = useState<ConfigForExchangeAgreementResponse>();

  const partnerConfiguration = useSelector(
    (state: RootState) => state.configData.partnerConfigForExchangeAgreement
  );

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadExportScopeValuesAsync()).finally(hideLoadingPanel);
  }, [dispatch]);

  useEffect(() => {
    setRequiredAction(exchangeAgreement.actionRequiredIfInactive);
  }, [exchangeAgreement]);

  useEffect(() => {
    if (isCurrentUserPartner) {
      if (requiredAction === NewExchangeAgreementActionRequired.Complete) {
        if (exchangeAgreement.template) {
          // If Direction is OwnerToPartner,then owner is "Sender" and partner is "Receiver".
          // If Direction is PartnerToOwner,then partner is "Sender" and owner is "Receiver".

          let partnerDirection = ConfigDirection.Receiving;
          if (exchangeAgreement.direction === DirectionEnum.PartnerToOwner) {
            partnerDirection = ConfigDirection.Sending;
          }

          displayLoadingPanel();
          dispatch(
            loadPartnerConfigForExchangeAgreementAsync({
              templateId: exchangeAgreement.template.id,
              direction: partnerDirection,
            })
          ).finally(hideLoadingPanel);
        }
      }
    }
  }, [isCurrentUserPartner, requiredAction, exchangeAgreement.template]);

  useEffect(() => {
    if (
      selectedPartnerConnectorId &&
      selectedPartnerScheduleId &&
      partnerConfiguration
    ) {
      partnerConfiguration.forEach((config) => {
        if (config.connector.id === selectedPartnerConnectorId) {
          config.connectorSchedules.forEach((schedule) => {
            if (schedule.id === selectedPartnerScheduleId) {
              setSelectedConfigBasedOnPartnerConnectorAndSchedule(config);
              return;
            }
          });
        }
      });
    } else {
      setSelectedConfigBasedOnPartnerConnectorAndSchedule(undefined);
    }
  }, [selectedPartnerConnectorId, selectedPartnerScheduleId]);

  useEffect(() => {
    if (
      isCurrentUserPartner &&
      requiredAction === NewExchangeAgreementActionRequired.Complete
    ) {
      const temporaryDataSource: ConnectorBaseResponse[] = [];

      for (let index = 0; index < partnerConfiguration.length; index++) {
        temporaryDataSource.push(partnerConfiguration[index].connector);
      }

      // Filtering for Unique values.
      let filteredDataSource = temporaryDataSource.filter(
        (data, ind) =>
          ind === temporaryDataSource.findIndex((val) => val.id === data.id)
      );

      setConnectorDataSource(filteredDataSource);
    }
  }, [isCurrentUserPartner, requiredAction, partnerConfiguration]);

  useEffect(() => {
    if (selectedPartnerConnectorId) {
      const temporaryDataSource: ConnectorScheduleSimplifiedResponse[] = [];

      for (let index = 0; index < partnerConfiguration.length; index++) {
        if (
          partnerConfiguration[index].connector?.id ===
          selectedPartnerConnectorId
        ) {
          temporaryDataSource.push(
            ...partnerConfiguration[index].connectorSchedules
          );
        }
      }

      // Filtering for Unique values.
      let filteredDataSource = temporaryDataSource.filter(
        (data, ind) =>
          ind === temporaryDataSource.findIndex((val) => val.id === data.id)
      );

      setScheduleDataSource(filteredDataSource);
    }
  }, [selectedPartnerConnectorId]);

  useEffect(() => {
    if (selectedPartnerConnectorId && connectorDataSource) {
      var connector = connectorDataSource.filter(
        (cn) => cn.id === selectedPartnerConnectorId
      );
      if (connector.length !== 0) {
        setSelectedPartnerConnector(connector[0]);
      }
    }
  }, [connectorDataSource, selectedPartnerConnectorId]);

  const handleConnectorSelectionChange = (connectorId: number) => {
    var connector = connectorDataSource.filter((cn) => cn.id === connectorId);
    if (connector.length !== 0) {
      handlePartnerConnectorChange?.(connector[0]);
    }
  };

  const isIeaViewWithCompleteActionRequired =
    !props.allowEdit &&
    exchangeAgreement.actionRequiredIfInactive ===
      NewExchangeAgreementActionRequired.Complete;

  return (
    <div className="grid grid-cols-4 gap-y-4">
      <LabelWithContent
        label="Partner tenant"
        content={
          <div className={labelClassName}>
            {exchangeAgreement.partnerTenant?.id}{" "}
            {exchangeAgreement.partnerTenant?.name}
          </div>
        }
      />

      {!isIeaViewWithCompleteActionRequired && (
        <ConnectorField
          isPartner={isCurrentUserPartner}
          requiredAction={requiredAction}
          selectedPartnerConnectorId={selectedPartnerConnectorId}
          connectorDataSource={connectorDataSource}
          labelClassName={labelClassName}
          exchangeAgreement={exchangeAgreement}
          handleConnectorSelectionChange={handleConnectorSelectionChange}
        />
      )}
      {!isIeaViewWithCompleteActionRequired && (
        <HostSystemField
          isPartner={isCurrentUserPartner}
          requiredAction={requiredAction}
          selectedPartnerConnector={selectedPartnerConnector}
          exchangeAgreement={exchangeAgreement}
          labelClassName={labelClassName}
        />
      )}
      {!isIeaViewWithCompleteActionRequired && (
        <LabelWithContent
          label="Config"
          content={
            <div className={labelClassName}>
              <ConfigNameDisplay
                tenantId={exchangeAgreement.partnerTenant?.id}
                configId={
                  exchangeAgreement.partnerConfigId &&
                  !!exchangeAgreement.partnerConfigTitle
                    ? exchangeAgreement.partnerConfigId
                    : selectedConfigBasedOnPartnerConnectorAndSchedule?.id
                }
                configTitle={
                  exchangeAgreement.partnerConfigId &&
                  !!exchangeAgreement.partnerConfigTitle
                    ? exchangeAgreement.partnerConfigTitle
                    : selectedConfigBasedOnPartnerConnectorAndSchedule?.title
                }
              />
            </div>
          }
        />
      )}
      <LabelWithContent
        label="Exchange role"
        content={
          <div className={labelClassName}>
            {exchangeAgreement.partnerRoleText}
          </div>
        }
      />

      {!isIeaViewWithCompleteActionRequired && (
        <div className="grid gap-4">
          <ScheduleIdentifierField
            isPartner={isCurrentUserPartner}
            requiredAction={requiredAction}
            selectedPartnerConnectorId={selectedPartnerConnectorId}
            scheduleDataSource={scheduleDataSource}
            labelClassName={labelClassName}
            exchangeAgreement={exchangeAgreement}
            handlePartnerScheduleChange={handlePartnerScheduleChange}
          />
        </div>
      )}

      {!isIeaViewWithCompleteActionRequired && (
        <ExecutionComponentField
          isPartner={isCurrentUserPartner}
          requiredAction={requiredAction}
          selectedPartnerConnector={selectedPartnerConnector}
          exchangeAgreement={exchangeAgreement}
          labelClassName={labelClassName}
        />
      )}

      <PartnerRepresentativeField
        partnerTenantId={exchangeAgreement?.partnerTenant?.id}
        partnerRepresentativeIds={(
          exchangeAgreement.partnerRepresentatives || []
        ).map((r) => r.id)}
        editConfig={{
          allowEdit: isCurrentUserPartner && props.allowEdit,
          onSavePartnerRepresentative: handleSavePartnerRepresentativeClick!,
        }}
        editStatus={
          props.isActiveIeaEdit ||
          exchangeAgreement.actionRequiredIfInactive ===
            NewExchangeAgreementActionRequired.Complete
            ? "create"
            : "edit"
        }
        onPartnerRepresentativeChange={handlePartnerRepresentativeChange}
      />
    </div>
  );
}
