import LabelWithContent from "components/common/LabelWithContent";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { getConfigNameDisplayTooltipText } from "shared/utilities/IEATooltipUtility";
import ConfigNameDisplay, {
  ConfigNameDisplayProps,
} from "../ConfigNameDisplay";

interface Props {
  configDirection: ConfigDirection;
}

export default function ConfigNameDisplayField(
  props: Props & ConfigNameDisplayProps
) {
  return (
    <LabelWithContent
      label="Config"
      content={
        <div className="ml-2">
          <ConfigNameDisplay
            configId={props.configId}
            configTitle={props.configTitle}
            tenantId={props.tenantId}
          />
        </div>
      }
      tooltipText={getConfigNameDisplayTooltipText(props.configDirection)}
    />
  );
}
