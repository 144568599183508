import PromineoTextBox from "components/common/controls/PromineoTextBox";
import { useCallback } from "react";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import ConnectorLabelWithContent from "../../ConnectorLabelWithContent";

interface Props {
  blobConnectionString?: string;
  blobContainerName?: string;
  mode: PromineoModalMode;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  validateOnFocusOut?: (event: any) => void;
}

export default function FileStorageBasicAuthenticationComponent(props: Props) {
  const {
    blobConnectionString,
    blobContainerName,
    mode,
    handleConfigurationValueChanged,
    validateOnFocusOut,
  } = props;

  const RenderBlobConnectionStringComponent = useCallback(() => {
    return (
      <>
        {(mode === PromineoModalMode.Create ||
          mode === PromineoModalMode.Modify) && (
          <PromineoTextBox
            width={mode === PromineoModalMode.Create ? 330 : 188}
            name="blobConnectionString"
            fieldName="Blob Connection String"
            required={true}
            defaultValue={blobConnectionString}
            placeholder="Type..."
            onChange={({ event }: any) => {
              if (handleConfigurationValueChanged) {
                handleConfigurationValueChanged(
                  event.currentTarget.name,
                  event.currentTarget.value
                );
              }
            }}
            onFocusOut={(event: any) => {
              if (validateOnFocusOut) {
                validateOnFocusOut(event);
              }
            }}
          />
        )}

        {mode === PromineoModalMode.View && blobConnectionString}
      </>
    );
  }, [mode, blobConnectionString]);

  const RenderBlobContainerNameComponent = useCallback(() => {
    return (
      <>
        {(mode === PromineoModalMode.Create ||
          mode === PromineoModalMode.Modify) && (
          <PromineoTextBox
            width={mode === PromineoModalMode.Create ? 330 : 188}
            name="blobContainerName"
            fieldName="Blob Container Name"
            required={true}
            defaultValue={blobContainerName}
            placeholder="Type..."
            onChange={({ event }: any) => {
              if (handleConfigurationValueChanged) {
                handleConfigurationValueChanged(
                  event.currentTarget.name,
                  event.currentTarget.value
                );
              }
            }}
            onFocusOut={(event: any) => {
              if (validateOnFocusOut) {
                validateOnFocusOut(event);
              }
            }}
          />
        )}

        {mode === PromineoModalMode.View && blobContainerName}
      </>
    );
  }, [mode, blobContainerName]);

  return (
    <div
      className={
        props.mode === PromineoModalMode.View ||
        props.mode === PromineoModalMode.Modify
          ? "grid grid-cols-2 gap-x-60"
          : ""
      }
    >
      <ConnectorLabelWithContent
        mode={mode}
        addAsterisksymbol={true}
        label="Blob Connection String"
        content={<RenderBlobConnectionStringComponent />}
      />
      <ConnectorLabelWithContent
        mode={mode}
        addAsterisksymbol={true}
        label="Blob Container Name"
        content={<RenderBlobContainerNameComponent />}
      />
    </div>
  );
}
