import { createSlice } from "@reduxjs/toolkit";
import MySelfResponse from "interfaces/response/MySelfResponse";
import UserResponse from "interfaces/response/UserResponse";
import UserSimplifiedResponse from "interfaces/response/UserSimplifiedResponse";
import {
  loadLoggedInUserInfo,
  loadUsersAsync,
  loadUsersByTenantAsync,
} from "store/actions/UserActions";

export interface UserListByTenant {
  tenantId: number;
  userList: UserSimplifiedResponse[];
}

export interface UserState {
  users: UserResponse[];
  usersByTenant: UserListByTenant[];
  mySelfResponse: MySelfResponse | null;
  loadingUsersByTenant: Record<number, 'loading' | 'loaded' | 'failed'>;
}

const initialState: UserState = {
  users: [],
  usersByTenant: [],
  mySelfResponse: null,
  loadingUsersByTenant: {},
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadUsersAsync.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(loadUsersByTenantAsync.pending, (state, action) => {
      state.loadingUsersByTenant[action.meta.arg.tenantId] =  'loading';
    });
    builder.addCase(loadUsersByTenantAsync.fulfilled, (state, action) => {
      const userList: UserListByTenant = {
        tenantId: action.meta.arg.tenantId,
        userList: action.payload,
      };
      state.usersByTenant.push(userList);
      state.loadingUsersByTenant[action.meta.arg.tenantId] = 'loaded';
    });

    builder.addCase(loadUsersByTenantAsync.rejected, (state, action) => {
      state.loadingUsersByTenant[action.meta.arg.tenantId] = 'failed';
    });

    builder.addCase(loadLoggedInUserInfo.pending, (state) => {
      state.mySelfResponse = null;
    });
    builder.addCase(loadLoggedInUserInfo.fulfilled, (state, action) => {
      state.mySelfResponse = action.payload;
    });
  },
});

export default userSlice.reducer;
