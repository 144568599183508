export const ExecutionComponentTableName = "ExecutionComponent";
export const HostSystemTableName = "HostSystem";
export const SafranApiConnectorAuthenticationModeTableName =
  "SafranApiConnectorAuthenticationMode";
export const AnalyticsApiConnectorAuthenticationModeTableName =
  "AnalyticsApiConnectorAuthenticationMode";
export const SAPConnectorAuthenticationModeTableName =
  "SAPConnectorAuthenticationMode";
export const PrimaveraConnectorAuthenticationModeTableName =
  "PrimaveraConnectorAuthenticationMode";
export const FileStorageConnectorAuthenticationModeTableName =
  "FileStorageConnectorAuthenticationMode";
export const ContentControlTableName = "ContentControl";
export const PlanningObjectTypesTableName = "PlanningObjectTypes";
export const FieldContentControlLevelTableName = "FieldContentControlLevel";
export const TenantContentControlLavelTableName = "TenantContentControlLavel";
export const ApplicationRoleTableName = "ApplicationRole";
export const StorageAtHomeOptionTableName = "StorageAtHomeOption";
export const ScopeEnumTableName = "ScopeEnum";
export const RFieldExportOptionsEnumTableName = "RFieldExportOptionsEnum";
export const ActivityLinkRebuildOptionEnumTableName =
  "ActivityLinkRebuildOptionEnum";
export const RevisionTypeEnumName = "RevisionType";
export const SapObjectTypeEnumName = "SapObjectType";
export const SapFunctionTypeEnumName = "SapFunctionType";
export const SapFunctionContextEnumName = "SapFunctionContext";
export const SapFunctionSequenceTypeEnumName = "SapFunctionSequenceType";
export const ConflictResolveOptionEnumName = "ConflictResolveOption";
export const TenantStatusOptionEnumName = "TenantStatusCodes";
export const CutoffAdjustmentTypeName = "CutoffAdjustmentType";
