import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import PromineoPopover from "../PromineoPopover";
import { Offset, Position } from "devextreme-react/popover";
import DataGrid, {
  Column,
  Scrolling,
  Selection,
} from "devextreme-react/data-grid";
import { useCallback, useRef } from "react";
import PromineoTextBox from "../PromineoTextBox";
import PromineoButtonWithIcon from "../buttons/PromineoButtonWithIcon";
import AddIcon from "components/icons/AddIcon";
import { PromineoButtonType } from "../buttons/PromineoButton";
import "./../styles/PromineoMultiSelectionPopover.css";
import SearchIcon from "components/icons/SearchIcon";

export interface PopoverMultiSelectionItem {
  id: number;
  text: string;
}

interface Props {
  items: PopoverMultiSelectionItem[];
  target: string;
  visible: boolean;
  onHiding: () => void;
  offsetX?: number;
  offsetY?: number;
  minWidth?: number;
  onSelectionChange?: (selectedItemIds: number[]) => void;
  searchPlaceholder?: string;
}

export default function PromineoMultiSelectionPopover(props: Props) {
  const gridRef = useRef<DataGrid>(null);

  const onSelectionChange = () => {
    let selectedRowKeys = gridRef.current?.instance.getSelectedRowKeys();
    if (selectedRowKeys && selectedRowKeys.length && props.onSelectionChange) {
      props.onSelectionChange(selectedRowKeys);
    }
    gridRef.current?.instance.clearSelection();
    props.onHiding();
  };

  const onSearchValueChange = (value: any) => {
    const dataGrid = gridRef?.current?.instance;
    dataGrid?.searchByText(value);
  };

  const onContentready = useCallback((evt: any)=> {
    evt.component.content().parentElement?.classList.add("promineo-multi-selection-popover");
  },[]);

  return (
    <div>
      <PromineoPopover
        width={"300"}
        minWidth={props.minWidth ? props.minWidth : 109}
        visible={props.visible}
        target={props.target}
        onHiding={props.onHiding}
        onContentReady={onContentready}
      >
        <Position my={{ x: "right", y: "top" }}>
          <Offset
            x={props.offsetX ? props.offsetX : 45}
            y={props.offsetY ? props.offsetY : -5}
          />
        </Position>
        <div className="relative items-center">
          <div className="z-10 absolute pl-3 pt-3">
            <SearchIcon />
          </div>
          <PromineoTextBox
            size="large"
            className="promineo-multi-selection-popover-textbox pl-5"
            placeholder={props.searchPlaceholder ? props.searchPlaceholder : "Search"}
            onInput={({ event }: any) => {
              onSearchValueChange(event?.currentTarget?.value);
            }}
          />
        </div>
        <PromineoViewGrid
          className="promineo-multi-select-popover-grid"
          dataSource={props.items}
          keyExpr={"id"}
          ref={gridRef}
          showColumnHeaders={false}
          noDataText="No data"
          height={"auto"}
        >
          <Selection
            mode="multiple"
            selectAllMode={"allPages"}
            showCheckBoxesMode={"always"}
          />
          <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
          <Column dataField={"text"} caption={"Structures"} />
        </PromineoViewGrid>
        <PromineoButtonWithIcon
          Icon={<AddIcon />}
          text={"Add selection"}
          variant={PromineoButtonType.BorderlessWithBlueText}
          onClick={onSelectionChange}
          className="pl-2 hover:bg-transparent font-semibold"
        />
      </PromineoPopover>
    </div>
  );
}
