import PromineoTextBox from "components/common/controls/PromineoTextBox";
import LabelWithContent from "../../../components/common/LabelWithContent";
import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import { useEffect, useState } from "react";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { loadSimplifiedTenantsAsync } from "store/actions/TenantActions";
import { loadUsersByTenantAsync } from "store/actions/UserActions";
import UserSimplifiedResponse from "interfaces/response/UserSimplifiedResponse";
import ConfigForExchangeAgreementResponse from "interfaces/response/ConfigForExchangeAgreementResponse";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import TemplateSimplifiedResponse from "interfaces/response/TemplateSimplifiedResponse";
import IEATemplateSelectorDropdown from "../IEATemplateSelectorDropdown";
import PromineoToggleButtonLarge from "components/common/controls/buttons/PromineoToggleButtonLarge";
import { useAppDispatch, useAppSelector } from "store/hooks";

export interface IEACoreInfoProps {
  ownerConfigList: ConfigForExchangeAgreementResponse[];
  title: string;
  direction: ConfigDirection;
  templateId: number;
  partnerTenantId: number;
  partnerRepresentativeId: number;
  isDuplicatingIEA: boolean;
  onTitleChange: (value: string) => void;
  onDirectionChange: (value: ConfigDirection) => void;
  onTemplateIdChange: (value: number) => void;
  onPartnerTenantIdChange: (value: number) => void;
  onPartnerRepresentativeIdChange: (value: number) => void;
}

export default function IEACoreInfo(props: IEACoreInfoProps) {
  const {
    ownerConfigList,
    title,
    direction,
    templateId,
    partnerTenantId,
    partnerRepresentativeId,
    isDuplicatingIEA,
  } = props;

  const dispatch = useAppDispatch();
  const [templateDropdownDataSource, setTemplateDropdownDataSource] = useState<
    TemplateSimplifiedResponse[]
  >([]);

  const [selectedTenantId, setSelectedTenantId] = useState<number>(0);
  const [usersByTenant, setUsersByTenant] = useState<UserSimplifiedResponse[]>(
    []
  );

  const tenants = useAppSelector((state) => state.tenantData.simplifiedTenants);

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadSimplifiedTenantsAsync(false)).finally(hideLoadingPanel);
  }, []);

  useEffect(() => {
    setSelectedTenantId(partnerTenantId);
  }, [partnerTenantId]);

  useEffect(() => {
    const temporaryDataSource: TemplateSimplifiedResponse[] = [];

    for (let index = 0; index < ownerConfigList.length; index++) {
      if (ownerConfigList[index].direction === direction) {
        temporaryDataSource.push(ownerConfigList[index].template);
      }
    }

    // Filtering for Unique values.
    let filteredDataSource = temporaryDataSource.filter(
      (data, ind) =>
        ind === temporaryDataSource.findIndex((val) => val.id === data.id)
    );

    setTemplateDropdownDataSource(filteredDataSource);
  }, [direction, ownerConfigList]);

  useEffect(() => {
    if (selectedTenantId > 0) {
      displayLoadingPanel();
      dispatch(loadUsersByTenantAsync({ tenantId: selectedTenantId }))
        .unwrap()
        .then((userList: UserSimplifiedResponse[]) => {
          if (partnerRepresentativeId) {
            let partnerRepresentativeFound = userList.find(
              (u) => u.id === partnerRepresentativeId
            );
            if (!partnerRepresentativeFound) {
              props.onPartnerRepresentativeIdChange(0);
            }
          }
          setUsersByTenant(userList);
        })
        .finally(hideLoadingPanel);
    }
  }, [selectedTenantId]);

  return (
    <div className="grid grid-col gap-y-4">
      <LabelWithContent
        label="IEA title"
        addAsterisksymbol={true}
        content={
          <PromineoTextBox
            width={328}
            name="title"
            value={title}
            placeholder="Type..."
            onChange={({ event }: any) => {
              props.onTitleChange(event?.currentTarget?.value);
            }}
          />
        }
        tooltipText="Give your IEA a descriptive name."
      />
      <LabelWithContent
        label="Exchange role"
        addAsterisksymbol={true}
        content={
          // Left value is "Sender" and right value is "Receiver".
          // Default value for this is "Receiving".
          // The value for this control and the value of "direction" should be same always.
          <PromineoToggleButtonLarge
            disabled={isDuplicatingIEA}
            width={328}
            value={direction === ConfigDirection.Sending ? "left" : "right"}
            toggleLeftText="Sender"
            toggleRightText="Receiver"
            onToggleLeft={() => {
              props.onDirectionChange(ConfigDirection.Sending);
              props.onTemplateIdChange(0);
            }}
            onToggleRight={() => {
              props.onDirectionChange(ConfigDirection.Receiving);
              props.onTemplateIdChange(0);
            }}
          />
        }
        tooltipText="Select whether you want to be the sender, or receiver of data."
      />

      <LabelWithContent
        label="Template"
        addAsterisksymbol={true}
        content={
          <IEATemplateSelectorDropdown
            disabled={isDuplicatingIEA}
            templates={templateDropdownDataSource}
            selectedTemplateId={templateId}
            onSelectionChange={(value: number) => {
              props.onTemplateIdChange(value);
            }}
          />
        }
        tooltipText="The data set you will exchange. Not sure which one to select, or not able to find the right template? Contact a Setup admin within your tenant."
      />

      <LabelWithContent
        label="Partner tenant"
        addAsterisksymbol={true}
        content={
          <PromineoSelectBox
            placeholder="Select"
            items={tenants}
            valueExpr={"id"}
            value={partnerTenantId}
            displayExpr={"name"}
            width={328}
            onValueChange={(value: number) => {
              setSelectedTenantId(value);
              props.onPartnerTenantIdChange(value);
              props.onPartnerRepresentativeIdChange(0);
            }}
          />
        }
        tooltipText="The company you want to exchange data with."
      />
      <LabelWithContent
        label="Partner representative ID"
        addAsterisksymbol={true}
        content={
          <PromineoSelectBox
            key={selectedTenantId}
            placeholder="Select"
            width={328}
            value={partnerRepresentativeId}
            items={usersByTenant}
            valueExpr={"id"}
            displayExpr={"fullName"}
            onValueChange={(value: number) => {
              props.onPartnerRepresentativeIdChange(value);
            }}
          />
        }
        tooltipText="The main responsible person you want to exchange data with."
      />
    </div>
  );
}
