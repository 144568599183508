import WizardModalWithStepper from "features/common/WizardModalWithStepper";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { useRef, useState } from "react";
import PartnerConfigurationSettingSteps, {
  PartnerConfigurationSettingStepsRef,
} from "./PartnerConfigurationSettingSteps";

interface Props {
  onClose: () => void;
  isPartnerRoleSender: boolean;
  exchangeAgreement: ExchangeAgreementDetailedResponse;
}

export default function SetParterConfigurationWizard(props: Props) {
  const [totalStep] = useState(props.isPartnerRoleSender ? 3 : 2);
  const [isModified, setIsModified] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isDataValidToProceed, setIsDataValidToProceed] = useState(false);
  const partnerInfoRef = useRef<PartnerConfigurationSettingStepsRef>(null);

  const goToNextStep = () => {
    setCurrentStep((prev) => (prev < totalStep ? prev + 1 : prev));
  };

  const goToPrevStep = () => {
    setCurrentStep((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleNextOrSubmitClick = () => {
    if (currentStep === totalStep) {
      handleSubmitPartnerSettings();
    } else {
      goToNextStep();
    }
  };

  const handleDataValidated = (isValid: boolean) => {
    setIsDataValidToProceed(isValid);
  };

  const handleSubmitPartnerSettings = () => {
    partnerInfoRef.current?.submitPartnerInformation();
  };

  const handleModified = () => {
    setIsModified(true);
  };

  return (
    <WizardModalWithStepper
      title="Partner configuration"
      totalStep={totalStep}
      currentStep={currentStep}
      isDataValidToProceed={isDataValidToProceed}
      content={
        <PartnerConfigurationSettingSteps
          isPartnerRoleSender={props.isPartnerRoleSender}
          currentStep={currentStep}
          ref={partnerInfoRef}
          exchangeAgreement={props.exchangeAgreement}
          onDataValidated={handleDataValidated}
          onSuccess={props.onClose}
          onModify={handleModified}
        />
      }
      cancelConfirmationMessage="Are you sure you want to cancel?"
      isModified={isModified}
      onBack={goToPrevStep}
      onNextOrSubmit={handleNextOrSubmitClick}
      onCancel={props.onClose}
    />
  );
}
