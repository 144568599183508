import PromineoTextBox from "components/common/controls/PromineoTextBox";
import { useCallback } from "react";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import ConnectorLabelWithContent from "../../ConnectorLabelWithContent";

interface Props {
  folderName?: string;
  mode: PromineoModalMode;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
}

export default function FileStorageFolderNameComponent(
  props: Props
) {
  const {
    folderName,
    mode,
    handleConfigurationValueChanged
  } = props;

  const RenderFolderNameComponent = useCallback(() => {
    return (
      <>
        {(mode === PromineoModalMode.Create ||
          mode === PromineoModalMode.Modify) && (
          <PromineoTextBox
            width={mode === PromineoModalMode.Create ? 330 : 188}
            name="folderName"
            fieldName="Folder Name"
            defaultValue={folderName}
            placeholder="Type..."
            onChange={({ event }: any) => {
              if (handleConfigurationValueChanged) {
                handleConfigurationValueChanged(
                  event.currentTarget.name,
                  event.currentTarget.value
                );
              }
            }}
          />
        )}

        {mode === PromineoModalMode.View && folderName}
      </>
    );
  }, [mode, folderName]);

  return (
    <div
      className={
        props.mode === PromineoModalMode.View ||
        props.mode === PromineoModalMode.Modify
          ? "grid grid-cols-2 gap-x-60"
          : ""
      }
    >
      <ConnectorLabelWithContent
        mode={mode}
        label="Folder Name"
        content={<RenderFolderNameComponent />}
      />
    </div>
  );
}
