import { TextContent } from "components/common/LabelWithContent";
import TenantUserResponse from "interfaces/response/tenant/TenantUserResponse";
import { UserStatus } from "shared/enums/UserStatus";
import { ApplicationRole } from "shared/enums/feature/ApplicationRole";
import TenantAdminApplicationRoleDropdown from "./ApplicationRoleDropdown";

interface Props {
  user: TenantUserResponse;
  onChange: (fieldName: string, value: string | number | undefined) => void;
}

export default function ApplicationRoleContent(props: Props) {
  const handleRoleChange = (value: any) => {
    props.onChange("applicationRole", value.value);
  };

  return props.user.status !== UserStatus.Active ? (
    <TextContent text={props.user.applicationRoleDisplay} />
  ) : (
    <TenantAdminApplicationRoleDropdown
      selectedRole={props.user.applicationRole}
      onValueChanged={handleRoleChange}
      allowedRoles={[
        ApplicationRole.ApplicationUser,
        ApplicationRole.TenantSetup,
        ApplicationRole.TenantAdmin,
      ]}
    />
  );
}
