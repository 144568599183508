import { createSlice } from "@reduxjs/toolkit";
import ConfigDetailResponse from "interfaces/response/ConfigDetailResponse";
import ConfigForExchangeAgreementResponse from "interfaces/response/ConfigForExchangeAgreementResponse";
import ConfigResponse from "interfaces/response/ConfigResponse";
import ConfigTemplateUpdatePreviewInfoResponse from "interfaces/response/ConfigTemplateUpdatePreviewInfoResponse";
import ResponseBase from "interfaces/response/ResponseBase";
import {
  copyConfigByIdAsync,
  createNewConfigAsync,
  loadConfigByIdAsync,
  loadConfigForExchangeAgreementAsync,
  loadConfigsAsync,
  loadOwnerConfigForExchangeAgreementAsync,
  loadPartnerConfigForExchangeAgreementAsync,
  loadTemplateChangesPreviewAsync,
  modifyConfigAsync,
  modifyConfigByAcceptingTemplateChangesAsync,
} from "store/actions/ConfigActions";

export interface ConfigState {
  currentConfig: ConfigDetailResponse | null;
  configs: ConfigResponse[];
  ownerConfigForExchangeAgreement: ConfigForExchangeAgreementResponse[];
  partnerConfigForExchangeAgreement: ConfigForExchangeAgreementResponse[];
  configForExchangeAgreement: ConfigResponse | null;
  copiedConfig: ResponseBase | null;
  templateChangesPreview: ConfigTemplateUpdatePreviewInfoResponse | null;
}

const initialState: ConfigState = {
  configs: [],
  currentConfig: null,
  ownerConfigForExchangeAgreement: [],
  partnerConfigForExchangeAgreement: [],
  configForExchangeAgreement: null,
  copiedConfig: null,
  templateChangesPreview: null,
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setCurrentConfig:(state, action) => {
      state.currentConfig = action.payload;
    },
    resetCurrentConfig: (state) => {
      state.currentConfig = null;
    },
    resetTemplateChangesPreview: (state) => {
      state.templateChangesPreview = null;
    },
    resetConfigForExchangeAgreement: (state) => {
      state.configForExchangeAgreement = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadConfigByIdAsync.fulfilled, (state, action) => {
      state.currentConfig = action.payload;
    });

    builder.addCase(loadConfigsAsync.fulfilled, (state, action) => {
      state.configs = action.payload;
    });

    builder.addCase(createNewConfigAsync.fulfilled, (state, action) => {
      state.currentConfig = action.payload;
    });

    builder.addCase(modifyConfigAsync.fulfilled, (state, action) => {
      state.currentConfig = action.payload;
    });

    builder.addCase(
      loadOwnerConfigForExchangeAgreementAsync.fulfilled,
      (state, action) => {
        state.ownerConfigForExchangeAgreement = action.payload;
      }
    );

    builder.addCase(
      loadPartnerConfigForExchangeAgreementAsync.fulfilled,
      (state, action) => {
        state.partnerConfigForExchangeAgreement = action.payload;
      }
    );

    builder.addCase(
      loadConfigForExchangeAgreementAsync.fulfilled,
      (state, action) => {
        state.configForExchangeAgreement = action.payload;
      }
    );

    builder.addCase(copyConfigByIdAsync.pending, (state) => {
      state.copiedConfig = null;
    });

    builder.addCase(copyConfigByIdAsync.fulfilled, (state, action) => {
      state.copiedConfig = action.payload;
    });

    builder.addCase(
      loadTemplateChangesPreviewAsync.fulfilled,
      (state, action) => {
        state.templateChangesPreview = action.payload;
      }
    );

    builder.addCase(
      modifyConfigByAcceptingTemplateChangesAsync.fulfilled,
      (state, action) => {
        state.currentConfig = action.payload;
      }
    );
  },
});

export const { resetCurrentConfig, resetTemplateChangesPreview, resetConfigForExchangeAgreement, setCurrentConfig } =
  configSlice.actions;

export default configSlice.reducer;
