import { ReactNode, useState } from "react";
import { PromineoButtonType } from "./PromineoButton";
import { IButtonOptions } from "devextreme-react/button";
import PromineoButtonWithIcon from "./PromineoButtonWithIcon";
import PromineoMultiSelectionPopover, {
  PopoverMultiSelectionItem,
} from "../popover-with-multi-selection/PromineoMultiSelectionPopover";

interface Props {
  id: string;
  text?: string;
  LeftIcon?: ReactNode;
  RightIcon?: ReactNode;
  variant?: PromineoButtonType;
  items?: PopoverMultiSelectionItem[];
  onSelectionChange?: (selectedItemIds: number[]) => void;
  searchPlaceholder?: string;
}

export default function PromineoButtonWithMultiSelectionPopover(
  props: Props & IButtonOptions
) {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const handleButtonClick = () => {
    setIsPopoverVisible((prevState) => !prevState);
  };

  const handlePopoverOnHiding = () => {
    setIsPopoverVisible(false);
  };

  return (
    <div>
      <PromineoButtonWithIcon
        {...props}
        variant={props.variant ? props.variant : PromineoButtonType.Primary}
        text={props.text || "Button with popover"}
        Icon={props.LeftIcon}
        RightIcon={props.RightIcon}
        id={props.id}
        onClick={handleButtonClick}
      />
      <PromineoMultiSelectionPopover
        offsetX={220}
        offsetY={-5}
        items={props.items ? props.items : []}
        visible={isPopoverVisible}
        onHiding={handlePopoverOnHiding}
        target={`#${props.id}`}
        onSelectionChange={props.onSelectionChange}
        searchPlaceholder={props.searchPlaceholder}
      />
    </div>
  );
}
