import PageHeader from "components/common/page-header/PageHeader";
import LinkIcon from "components/icons/LinkIcon";
import TemplateFieldDetailsResponse from "interfaces/response/TemplateFieldDetailsResponse";
import { ReactElement } from "react";
import { getContentControlText } from "shared/utilities/ContentControlUtility";

interface Props {
  template: TemplateFieldDetailsResponse;
  titleWidget?: ReactElement;
}

export default function FieldHeader(props: Props) {
  const contentControlDisplay = getContentControlText(
    props.template.allowContentControl,
    props.template.allowBlank
  );

  return (
    <PageHeader
      title={props.template.name}
      titleWidget={props.titleWidget}
      displayDescriptionField={true}
      description={props.template.description}
      headerWidgets={[
        <div className="flex space-x-1">
          <div>Template: </div>
          <div className="max-w-[235px] truncate">
            {props.template.templateName}
          </div>
        </div>,
        <>Planning object type: {props.template.planningObjectTypeText}</>,
        <>
          Content control: {contentControlDisplay ? contentControlDisplay : "-"}
        </>,
        <div className="flex items-center justify-center">
          <div className="mr-1">URL:</div>
          {/* Temporarily disabled clicking on URI. Only URI Icon displayed below.*/}
          {/* <div onClick={handleLinkClick} className="cursor-pointer">
            <LinkIcon />
          </div> */}
          <LinkIcon />
        </div>,
      ]}
    />
  );
}
