import Validator from "devextreme/ui/validator";
import AnalyticsConnectorConfiguration from "interfaces/response/connector/AnalyticsConnectorConfiguration";
import ConnectorResponse from "interfaces/response/connector/ConnectorResponse";
import MicrosoftProjectConnectorConfiguration from "interfaces/response/connector/MicrosoftProjectConnectorConfiguration";
import PrimaveraConnectorConfiguration from "interfaces/response/connector/PrimaveraConnectorConfiguration";
import SafranApiConnectorConfigurationResponse from "interfaces/response/connector/SafranApiConnectorConfigurationResponse";
import SAPConnectorConfiguration from "interfaces/response/connector/SAPConnectorConfiguration";
import { ExecutionComponent } from "shared/enums/feature/ExecutionComponent";
import { HostSystem } from "shared/enums/feature/HostSystem";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import ConnectorBasicInformation from "./ConnectorBasicInformation";
import ConnectorLabelWithContent from "./ConnectorLabelWithContent";
import AnalyticsConnectorInformation from "./host-system/analytics/AnalyticsConnectorInformation";
import MicrosoftProjectConnectorInformation from "./host-system/microsoftProject/MicrosoftProjectConnectorInformation";
import PrimaveraConnectorInformation from "./host-system/primavera/PrimaveraConnectorInformation";
import SafranConnectorInformation from "./host-system/safran/SafranConnectorInformation";
import SAPConnectorInformation from "./host-system/sap/SAPConnectorInformation";
import LastHealthCheckComponent from "./LastHealthCheckComponent";
import LastPersistComponent from "./LastPersistComponent";
import FileStorageConnectorConfiguration from "interfaces/response/connector/FileStorageConnectorConfiguration";
import FileStorageConnectorInformation from "./host-system/fileStorage/FileStorageConnectorInformation";

interface Props {
  connector: ConnectorResponse;
  connectorConfiguration:
    | SafranApiConnectorConfigurationResponse
    | AnalyticsConnectorConfiguration
    | SAPConnectorConfiguration
    | PrimaveraConnectorConfiguration
    | MicrosoftProjectConnectorConfiguration
    | FileStorageConnectorConfiguration;
  mode: PromineoModalMode;
  handleConnectorValueChanged?: (fieldName: string, value: any) => void;
  handleHostSystemValueChange?: (value: number) => void;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  handleAuthenticationModeValueChanged?: (value: number) => void;
}

export default function ConnectorInformation(props: Props) {
  const {
    connector,
    connectorConfiguration,
    mode,
    handleConnectorValueChanged,
    handleHostSystemValueChange,
    handleConfigurationValueChanged,
    handleAuthenticationModeValueChanged,
  } = props;

  const validateOnFocusOut = (event: any) => {
    let instance = Validator.getInstance(event.element) as Validator;
    instance.validate();
  };

  return (
    <div className="flex justify-center">
      <div className="w-784px bg-white text-textGray text-10px leading-4 rounded-lg border border-lightGray">
        <div className="m-6">
          <ConnectorBasicInformation
            connector={connector}
            mode={mode}
            classNames={"grid grid-cols-2 gap-x-60"}
            handleValueChanged={handleConnectorValueChanged}
            handleHostSystemValueChange={handleHostSystemValueChange}
            validateOnFocusOut={validateOnFocusOut}
          />
          {connector.hostSystem === HostSystem.Safran && (
            <SafranConnectorInformation
              executionComponent={connector.executionComponent}
              configuration={
                connectorConfiguration as SafranApiConnectorConfigurationResponse
              }
              mode={mode}
              classNames={"grid grid-cols-2 gap-x-60"}
              handleConfigurationValueChanged={handleConfigurationValueChanged}
              handleSafranAuthenticationModeValueChanged={
                handleAuthenticationModeValueChanged
              }
              validateOnFocusOut={validateOnFocusOut}
            />
          )}
          {connector.hostSystem === HostSystem.IlapAnalytics && (
            <AnalyticsConnectorInformation
              executionComponent={connector.executionComponent}
              configuration={
                connectorConfiguration as AnalyticsConnectorConfiguration
              }
              mode={mode}
              handleConfigurationValueChanged={handleConfigurationValueChanged}
              handleAuthenticationModeValueChanged={
                handleAuthenticationModeValueChanged
              }
              validateOnFocusOut={validateOnFocusOut}
            />
          )}
          {connector.hostSystem === HostSystem.SAP && (
            <SAPConnectorInformation
              configuration={
                connectorConfiguration as SAPConnectorConfiguration
              }
              executionComponent={connector.executionComponent}
              mode={mode}
              handleConfigurationValueChanged={handleConfigurationValueChanged}
              handleAuthenticationModeValueChanged={
                handleAuthenticationModeValueChanged
              }
              validateOnFocusOut={validateOnFocusOut}
            />
          )}
          {connector.hostSystem === HostSystem.PrimaveraP6 && (
            <PrimaveraConnectorInformation
              configuration={
                connectorConfiguration as PrimaveraConnectorConfiguration
              }
              mode={mode}
              handleConfigurationValueChanged={handleConfigurationValueChanged}
              handleAuthenticationModeValueChanged={
                handleAuthenticationModeValueChanged
              }
              validateOnFocusOut={validateOnFocusOut}
              executionComponent={connector.executionComponent}
            />
          )}
          {connector.hostSystem === HostSystem.MicrosoftProject && (
            <MicrosoftProjectConnectorInformation
              configuration={
                connectorConfiguration as MicrosoftProjectConnectorConfiguration
              }
              mode={mode}
              handleConfigurationValueChanged={handleConfigurationValueChanged}
            />
          )}
          {connector.hostSystem === HostSystem.FileStorage && (
            <FileStorageConnectorInformation
              configuration={
                connectorConfiguration as FileStorageConnectorConfiguration
              }
              mode={mode}
              handleConfigurationValueChanged={handleConfigurationValueChanged}
              handleAuthenticationModeValueChanged={
                handleAuthenticationModeValueChanged
              }
              validateOnFocusOut={validateOnFocusOut}
            />
          )}
        </div>
        {connector.executionComponent == ExecutionComponent.Service && (
          <div className="m-6">
            <ConnectorLabelWithContent
              mode={mode}
              maxWidth="w-auto"
              label="Last persist of Schedule list and Hostfield list"
              content={
                <LastPersistComponent
                  connectorId={connector.id}
                  showPersistButton={mode === PromineoModalMode.View}
                />
              }
            />
          </div>
        )}

        <div className="m-6">
          <ConnectorLabelWithContent
            mode={mode}
            label="Last health check"
            content={
              <LastHealthCheckComponent
                healthCheckStatus={connector.healthCheckStatus}
                isHealthCheckRequestAllowed={
                  connector.isHealthCheckRequestAllowed
                }
                healthCheckedAt={connector.healthCheckedAt}
              />
            }
          />
        </div>
      </div>
    </div>
  );
}
