import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import PromineoModal from "components/modal/PromineoModal";
import ScrollView from "devextreme-react/scroll-view";

interface Props {
  title: string;
  description?: string;
  onCancel: () => void;
  validationFails: string[];
}

export default function ConfigValidationFailDialog(props: Props) {
  const { title, description, onCancel, validationFails } = props;
  return (
    <PromineoModal width={500} height={"auto"} isVisible={!!validationFails}>
      <div className="text-center">
        <div className="text-xl mb-4">{title}</div>
        <div className="mb-4 text-xs">{description}</div>
        <ScrollView height={380}>
          <div>
            <ul className="text-xs">
              {validationFails.map((m, ind) => {
                return <li className="mb-4" key={ind}>{m}</li>;
              })}
            </ul>
          </div>
        </ScrollView>
      </div>
      <div className="mt-4 py-4">
        <PromineoButton
          text="Back"
          variant={PromineoButtonType.Secondary}
          onClick={onCancel}
        />
      </div>
    </PromineoModal>
  );
}
