import ConfigResponse from "interfaces/response/ConfigResponse";
import ConnectorBaseResponse from "interfaces/response/connector/ConnectorBaseResponse";
import ConnectorScheduleSimplifiedResponse from "interfaces/response/ConnectorScheduleSimplifiedResponse";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { FrequencyType } from "shared/enums/feature/FrequencyType";
import GeneralIEAInfo from "../GeneralIEAInfo";
import OwnerConfiguration from "../OwnerConfiguration";
import PartnerConfiguration from "../PartnerConfiguration";
import SignatureDetails from "./SignatureDetails";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  isOwner: boolean;
  isCurrentUserPartner: boolean;
  partnerConnectorId?: number;
  partnerScheduleId?: number;
  partnerConfigSettings: ConfigResponse | null;
  isActiveIeaEdit?: boolean;
  startDate: string;
  expirationDate: string;
  handlePartnerRepresentativeChange: (representativeIds: number[]) => void;
  handleOwnerRepresentativeChange: (representativeIds: number[]) => void;
  handlePartnerConnectorChange: (connector: ConnectorBaseResponse) => void;
  handlePartnerScheduleChange: (
    selection: ConnectorScheduleSimplifiedResponse
  ) => void;
  handleSavePartnerRepresentativeClick: () => void;
  handleSaveOwnerRepresentativeClick: () => void;
  onStartDateChange: (value: string) => void;
  onExpirationDateChange: (value: string) => void;
  onCommentChange: (value: string) => void;
  onCronExpressionChange: (value: string) => void;
  onPredecessorAgreementIdChange?: (value: any) => void;
  onLagMinutesChange?: (value: any) => void;
  onFrequencyTypeChange?: (value: FrequencyType) => void;
}

export default function InactiveIEAInfo(props: Props) {
  const {
    exchangeAgreement,
    isOwner,
    isCurrentUserPartner,
    partnerConnectorId,
    partnerConfigSettings,
    partnerScheduleId,
    isActiveIeaEdit,
    startDate,
    expirationDate,
    handlePartnerRepresentativeChange,
    handleOwnerRepresentativeChange,
    handlePartnerConnectorChange,
    handlePartnerScheduleChange,
    handleSavePartnerRepresentativeClick,
    handleSaveOwnerRepresentativeClick,
  } = props;
  
  return (
    <div className="space-y-4">
      <GeneralIEAInfo
        selectedExchangeAgreementId={exchangeAgreement.id}
        startDate={startDate}
        isExchangeAgreementActive={exchangeAgreement.isActive}
        isExchangeAgreementPending={exchangeAgreement.isPendingStart}
        isPaused={exchangeAgreement.isPaused}
        expirationDate={expirationDate}
        comments={exchangeAgreement.comments}
        executionCronExpressionText={exchangeAgreement.executionCronDisplay}
        executionCronExpression={exchangeAgreement.executionCronExpression}
        frequency={exchangeAgreement.frequencyType}
        lagInMinutes={exchangeAgreement.lagInMinutes}
        predecessorAgreementId={
          exchangeAgreement.predecessorAgreement
            ? exchangeAgreement.predecessorAgreement.id
            : 0
        }
        allowEdit={isActiveIeaEdit && isOwner}
        ownerRole={exchangeAgreement.ownerRole}
        onCommentChange={props.onCommentChange}
        onCronExpressionChange={props.onCronExpressionChange}
        onExpirationDateChange={props.onExpirationDateChange}
        onStartDateChange={props.onStartDateChange}
        onPredecessorAgreementIdChange={props.onPredecessorAgreementIdChange}
        onLagMinutesChange={props.onLagMinutesChange}
        onFrequencyTypeChange={props.onFrequencyTypeChange}
      />

      <div className="space-y-2">
        <OwnerConfiguration
          exchangeAgreement={exchangeAgreement}
          isOwner={isOwner}
          handleOwnerRepresentativeChange={handleOwnerRepresentativeChange}
          onSaveChangesClick={handleSaveOwnerRepresentativeClick}
          isActiveIeaEdit={isActiveIeaEdit}
          allowEdit={true}
        />

        {exchangeAgreement.ownerSignatureTime &&
          exchangeAgreement.ownerSigner && (
            <div className="flex justify-center">
              <div className="w-3/4">
                <SignatureDetails
                  userName={exchangeAgreement.ownerSigner.fullName}
                  dateTime={exchangeAgreement.ownerSignatureTime}
                />
              </div>
            </div>
          )}
      </div>
      <div className="space-y-2">
        <PartnerConfiguration
          selectedPartnerConnectorId={partnerConnectorId}
          selectedPartnerScheduleId={partnerScheduleId}
          exchangeAgreement={exchangeAgreement}
          isCurrentUserPartner={isCurrentUserPartner}
          partnerConfigSettings={partnerConfigSettings}
          handlePartnerRepresentativeChange={handlePartnerRepresentativeChange}
          handlePartnerConnectorChange={handlePartnerConnectorChange}
          handlePartnerScheduleChange={handlePartnerScheduleChange}
          handleSavePartnerRepresentativeClick={
            handleSavePartnerRepresentativeClick
          }
          isActiveIeaEdit={isActiveIeaEdit}
          allowEdit={true}
          isPartnerRoleSender={
            exchangeAgreement.ownerRole !== ExchangeRoleEnum.Sender
          }
        />

        {exchangeAgreement.partnerSignatureTime &&
          exchangeAgreement.partnerSigner && (
            <div className="flex justify-center">
              <div className="w-3/4">
                <SignatureDetails
                  userName={exchangeAgreement.partnerSigner.fullName}
                  dateTime={exchangeAgreement.partnerSignatureTime}
                />
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
