import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import LabelWithContent from "components/common/LabelWithContent";
import ConnectorBaseResponse from "interfaces/response/connector/ConnectorBaseResponse";
import ConnectorScheduleSimplifiedResponse from "interfaces/response/ConnectorScheduleSimplifiedResponse";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import {
  getConnectorSelectionTooltipText,
  getScheduleSelectionTooltipText,
} from "shared/utilities/IEATooltipUtility";
import { getDisplayExpressionForScheduleSelectorDropdown } from "shared/utilities/IEAUtility";
import PartnerRepresentativeField from "../active-ieas/PartnerRepresentativeField";
import ConfigNameDisplayField from "../common/ConfigNameDisplayField";
import ExecutionComponentDisplayField from "../common/ExecutionComponentDisplayField";
import HostSystemDisplayField from "../common/HostSystemDisplayField";
import IEAWizardInformation, {
  WizardInformationTextStyles,
} from "../common/IEAWizardInformation";
import ScheduleSelectionDropdownItemRenderComponent from "../ScheduleSelectionDropdownItemRenderComponent";

export interface PartnerPrimaryInformationProps {
  configDirection: ConfigDirection;
  connectorDataSource: ConnectorBaseResponse[];
  scheduleDataSource: ConnectorScheduleSimplifiedResponse[];
  connectorHostSystemName: string;
  executionComponentName: string;
  partnerRepresentativeIds: number[];
  isScheduleSelectionDisabled: boolean;
  selectedConnectorId: number;
  partnerConfigId: number;
  partnerConfigTitle: string;
  partnerTenantId: number;
  onConnectorSelectionChange: (value: any) => void;
  onScheduleSelectionChange: (value: any) => void;
  onPartnerRepresentativeChange: (value: any) => void;
}

export default function PartnerConfigurationStepOne(
  props: PartnerPrimaryInformationProps
) {
  return (
    <div>
      <IEAWizardInformation
        textComponent={
          <div className={WizardInformationTextStyles}>
            Complete your configuration by selecting the connector and the
            schedule identifier. These fields are required (
            <span className="text-red font-bold">*</span>). Optionally, you can
            also add more partner representatives.
          </div>
        }
      ></IEAWizardInformation>
      <div className="mt-3">
        <PartnerConfigurationPrimaryInformation {...props} />
      </div>
    </div>
  );
}

const PartnerConfigurationPrimaryInformation = (
  props: PartnerPrimaryInformationProps
) => {
  return (
    <div className="space-y-4">
      <div className="flex gap-x-8">
        <LabelWithContent
          label="Connector name"
          addAsterisksymbol={true}
          content={
            <PromineoSelectBox
              width={328}
              placeholder="Select"
              valueExpr="id"
              items={props.connectorDataSource}
              displayExpr="name"
              onValueChange={props.onConnectorSelectionChange}
            />
          }
          tooltipText={getConnectorSelectionTooltipText(props.configDirection)}
        />
        <HostSystemDisplayField
          connectorHostSystemName={props.connectorHostSystemName}
        />
      </div>
      <div className="flex gap-x-8">
        <LabelWithContent
          label="Schedule identifier"
          addAsterisksymbol={true}
          content={
            <PromineoSelectBox
              key={props.selectedConnectorId}
              width={328}
              placeholder="Select"
              disabled={props.isScheduleSelectionDisabled}
              items={props.scheduleDataSource}
              itemRender={ScheduleSelectionDropdownItemRenderComponent}
              valueExpr="id"
              displayExpr={getDisplayExpressionForScheduleSelectorDropdown}
              onSelectionChanged={(selection: any) =>
                props.onScheduleSelectionChange(selection.selectedItem)
              }
            />
          }
          tooltipText={getScheduleSelectionTooltipText(props.configDirection)}
        />
        <ExecutionComponentDisplayField
          configDirection={props.configDirection}
          executionComponentName={props.executionComponentName}
        />
      </div>
      <div className="flex gap-x-8">
        <div className="w-[328px]">
          <PartnerRepresentativeField
            partnerTenantId={props.partnerTenantId}
            partnerRepresentativeIds={props.partnerRepresentativeIds}
            createConfig={{
              linesForSelectedRepresentatives: 6,
            }}
            editStatus={"create"}
            width={328}
            editConfig={{
              allowEdit: true,
              onSavePartnerRepresentative: () => {},
            }}
            onPartnerRepresentativeChange={props.onPartnerRepresentativeChange}
          />
        </div>
        <ConfigNameDisplayField
          configId={props.partnerConfigId}
          configTitle={props.partnerConfigTitle}
          tenantId={props.partnerTenantId}
          configDirection={props.configDirection}
        />
      </div>
    </div>
  );
};
