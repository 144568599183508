import LabelWithContent from "components/common/LabelWithContent";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { loadCutoffAdjustmentTypeValuesAsync } from "store/actions/DropdownValueActions";
import HostSystemConfigurationDropdown from "features/setup/configs/host-system/HostSystemConfigurationDropdown";
import { CutoffAdjustmentType } from "shared/enums/feature/analytics-host-system/CutoffAdjustmentType";
import BaseHostSystemComponentProps from "../BaseHostSystemComponentProps";

interface Props extends BaseHostSystemComponentProps {
  selectedCutoffAdjustmentType: CutoffAdjustmentType;
}

export default function CutoffAdjustmentTypeField(props: Props) {
  const {
    mode,
    selectedCutoffAdjustmentType,
    fieldName,
    handleConfigurationValueChanged,
  } = props;
  const dispatch = useAppDispatch();

  const cutoffAdjustmentTypes = useAppSelector(
    (store) => store.dropdownData.cutoffAdjustmentType
  );

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadCutoffAdjustmentTypeValuesAsync()).finally(hideLoadingPanel);
  }, []);

  return (
    <div className="space-y-6">
      <LabelWithContent
        label="Cutoff Adjustment Types"
        content={
          <HostSystemConfigurationDropdown
            mode={mode}
            dropdownOptions={cutoffAdjustmentTypes}
            fieldName={fieldName}
            selectedValue={selectedCutoffAdjustmentType}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
          />
        }
      />
    </div>
  );
}
