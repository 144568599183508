import PromineoAccordion from "components/common/controls/PromineoAccordion";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import { displayLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import { ScrollView } from "devextreme-react";
import ExchangeAgreementIdTitleResponseDto from "interfaces/response/ExchangeAgreementIdTitleResponseDto";
import { truncate } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import { loadLinkedExchangeAgreementsAsync } from "store/actions/ConfigActions";
import { useAppDispatch } from "store/hooks";
import "./styles/ConfigDeleteConfirmationDialog.css";

interface Props {
  configId: number;
  configTitle: string;
  configDirection: ConfigDirection;
  onConfirm: () => void;
  onCancel: () => void;
}

interface LinkedExchangeAgreementAccordionDataSource {
  title: string;
  item: string;
}

export default function ConfigDeleteConfirmationDialog(props: Props) {
  const dispatch = useAppDispatch();

  const configTitle = useMemo(() => truncate(props.configTitle, { length: 40 }), [props.configTitle]);

  const content = useMemo(() => <div className="break-words">{`Are you sure you want to delete "${configTitle}" config?`}</div>, [configTitle]);

  const [linkedExchangeAgreements, setLinkedExchangeAgreements] = useState<ExchangeAgreementIdTitleResponseDto[]>([]);

  useEffect(() => {
    displayLoadingPanel();

    dispatch(loadLinkedExchangeAgreementsAsync(props.configId))
      .then((response) => {
        setLinkedExchangeAgreements(response.payload as ExchangeAgreementIdTitleResponseDto[]);
      })
      .then(hideLoadingPanel);
  }, [dispatch, props.configId]);

  const itemComponent = useCallback((prop: LinkedExchangeAgreementAccordionDataSource) => {
    return (
      <ScrollView>
        <div className="text-left pl-[10px] max-h-[90px]">
          {prop.item}
        </div>
      </ScrollView>
    );
  }, []);

  const subContent = useMemo(() => {
    if (linkedExchangeAgreements.length === 0) {
      return <div>This action is irreversible and all information will be lost.</div>;
    }

    const direction = ConfigDirection[props.configDirection].toLowerCase();
    const count = linkedExchangeAgreements.length === 1 ? "1 active IEA" : `${linkedExchangeAgreements.length} active IEAs`;

    const accordionDataSource: LinkedExchangeAgreementAccordionDataSource[] = [
      {
        title: `List of active IEAs affected`,
        item: linkedExchangeAgreements.map(e => `${e.id} ${e.title}`).join(", ")
      }
    ];

    return (
      <div>
        <div className="mb-2 break-words">{`By deleting config "${configTitle}", `}<span className="font-bold">{`${count}`}</span>{` will not have ${direction} config, hence no update from ${direction} config and template can be made in the linked IEA(s) in future.`}</div>
        <PromineoAccordion
          className="config-delete-modal-accordion promineo-accordion"
          dataSource={accordionDataSource}
          itemRender={itemComponent}
          defaultSelectedIndex={undefined}
          collapsible
        />
      </div>
    )

  }, [linkedExchangeAgreements, props.configDirection, configTitle, itemComponent]);

  return (
    <PromineoConfirmationDialog
      actionOptions={{
        mode: PromineoModalMode.Modify,
      }}
      content={content}
      subContent={subContent}
      isDeleteConfirm={true}
      confirmButtonText="Delete"
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
    ></PromineoConfirmationDialog>
  )
}