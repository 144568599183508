import CrossIcon from "components/icons/CrossIcon";

interface Props {
  text: string;
  onRemoveClick?: () => void;
  onClick?: () => void;
  widthClass?: string;
  isDisabled?: boolean;
  canRemoveChip?: boolean;
  shouldHighlight?: boolean;
}

export default function PromineoChipComponent(props: Props) {
  const onCrossIconClick = (e: any) => {
    if (props.onRemoveClick) {
      props.onRemoveClick();
    }
    e.stopPropagation();
  };

  const onTextClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <div
      title={props.text}
      className={`rounded ${
        props.widthClass ? props.widthClass : "w-fit"
      } h-6 border flex gap-x-1 p-1 text-12px leading-15px font-medium font-inter text-textGray justify-center items-center whitespace-nowrap ${
        props.isDisabled
          ? "bg-frameGrey border-frameGrey text-gray"
          : (props.shouldHighlight ?  "text-textGray border-ilapBlue" : "text-textGray border-blue200")
      }`}
    >
      <div
        className={`flex-grow overflow-hidden ${
          props.onClick && !props.isDisabled
            ? "cursor-pointer"
            : ""
        }`}
        onClick={onTextClick}
      >
        {props.text}
      </div>
      {props.canRemoveChip && props.onRemoveClick && !props.isDisabled && (
        <div
          className="cursor-pointer flex-shrink:0 hover:bg-lighterGray"
          onClick={(e) => {
            onCrossIconClick(e);
          }}
        >
          <CrossIcon />
        </div>
      )}
    </div>
  );
}
