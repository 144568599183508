import LabelWithContent, {
  TextContent,
} from "components/common/LabelWithContent";

interface Props {
  connectorHostSystemName: string;
}

export default function HostSystemDisplayField(props: Props) {
  return (
    <LabelWithContent
      label="Host system"
      content={<TextContent text={props.connectorHostSystemName} />}
      tooltipText="The software that hosts the scheduling data to be exchanged. The host system is defined by selecting the connector."
    />
  );
}
