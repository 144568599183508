import PromineoModal from "components/modal/PromineoModal";
import TenantUserResponse from "interfaces/response/tenant/TenantUserResponse";
import EditUserDialogFooter from "./EditUserDialogFooter";
import UserStatusLabel from "./UserStatusLabel";
import LabelWithContent, {
  TextContent,
} from "components/common/LabelWithContent";
import { useState } from "react";
import ApplicationRoleContent from "./ApplicationRoleContent";
import { ApplicationRole } from "shared/enums/feature/ApplicationRole";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { useAppDispatch } from "store/hooks";
import {
  updateUserAsync,
  updateUserStatusAsync,
} from "store/actions/TenantAdminActions";
import { UserStatus } from "shared/enums/UserStatus";
import { toastSuccess } from "shared/utilities/ToastUtility";
import { getFormattedDateTime } from "shared/utilities/DateTimeUtility";

interface Props {
  user: TenantUserResponse;
  onClose: () => void;
}

export default function EditUserDialog(props: Props) {
  const dispatch = useAppDispatch();

  const [userInfoToUpdate, setUserInfoToUpdate] = useState<{
    applicationRole: ApplicationRole;
  }>();

  const handleUserValueChange = (
    fieldName: string,
    value: string | number | undefined
  ) => {
    setUserInfoToUpdate((prev) => {
      return prev
        ? { ...prev, [fieldName]: value }
        : { applicationRole: Number(value) };
    });
  };

  const handleSaveUser = () => {
    if (userInfoToUpdate) {
      displayLoadingPanel();
      dispatch(
        updateUserAsync({
          userId: props.user.id,
          request: { applicationRole: userInfoToUpdate?.applicationRole },
        })
      )
        .unwrap()
        .then(() => {
          setUserInfoToUpdate(undefined);
          toastSuccess("User updated successfully.");
          props.onClose();
        })
        .finally(hideLoadingPanel);
    }
  };

  const handleUserStatusChange = () => {
    displayLoadingPanel();
    dispatch(
      updateUserStatusAsync({
        userId: props.user.id,
        request: {
          status:
            props.user.status === UserStatus.Active
              ? UserStatus.Disabled
              : UserStatus.Active,
        },
      })
    )
      .unwrap()
      .then(() => {
        toastSuccess("User status updated successfully.");
      })
      .finally(hideLoadingPanel);
  };

  return (
    <PromineoModal
      isVisible={true}
      title="Edit User"
      width={440}
      height={"auto"}
    >
      <div>
        <div>
          <div className="flex justify-between items-center border-b border-lightGray pb-2">
            <div className="text-15px font-semibold text-textGray leading-[22px]">
              {props.user.fullName}
            </div>
            <div>
              <UserStatusLabel user={props.user} />
            </div>
          </div>
          <div className="space-y-5 my-6">
            <LabelWithContent
              label={"User ID"}
              content={<TextContent text={props.user.id} />}
            />
            <LabelWithContent
              label={"Email address"}
              content={<TextContent text={props.user.email} />}
            />
            <LabelWithContent
              label={"Application role"}
              content={
                <ApplicationRoleContent
                  user={props.user}
                  onChange={handleUserValueChange}
                />
              }
            />
            <LabelWithContent
              label={"Last login"}
              content={
                <TextContent
                  text={
                    props.user.lastLogin
                      ? getFormattedDateTime(
                          props.user.lastLogin.toString()
                        )
                      : "-"
                  }
                />
              }
            />
            <LabelWithContent
              label={"# of IEAs"}
              content={<TextContent text={props.user.ieaCount} />}
            />
          </div>
        </div>
        <EditUserDialogFooter
          user={props.user}
          onClose={props.onClose}
          isDirty={!!userInfoToUpdate}
          onSaveUser={handleSaveUser}
          onUserStatusChange={handleUserStatusChange}
        />
      </div>
    </PromineoModal>
  );
}
