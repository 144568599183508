import IEAWizardInformation, {
  WizardInformationTextStyles,
} from "features/ieas/common/IEAWizardInformation";
import IEACoreInfo, { IEACoreInfoProps } from "../IEACoreInfo";

export interface StepOneNewIeaProps {
  coreInfoProps: IEACoreInfoProps;
}

export default function StepOneNewIEA(props: StepOneNewIeaProps) {
  return (
    <div>
      <IEAWizardInformation
        textComponent={
          <div className={WizardInformationTextStyles}>
            Give your IEA a descriptive title and define if you want to send or
            receive data, what data you want to exchange and with whom. All
            fields are required (<span className="text-red font-bold">*</span>).
            Click next step to continue.
          </div>
        }
      ></IEAWizardInformation>
      <div className="mt-3">
        <IEACoreInfo {...props.coreInfoProps} />
      </div>
    </div>
  );
}
