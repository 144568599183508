import IEAWizardInformation, {
  WizardInformationTextStyles,
} from "features/ieas/common/IEAWizardInformation";
import IEAScheduling, { IeaScheduleProps } from "../IEASchedulings";

export interface StepThreeNewIeaProps {
  scheduleProps: IeaScheduleProps;
}

export default function StepThreeNewIEA(props: StepThreeNewIeaProps) {
  return (
    <div>
      <IEAWizardInformation
        textComponent={
          <div className={WizardInformationTextStyles}>
            Define from when and until when you want the IEA to be active and
            with what frequency you want to exchange data. These fields are
            required (<span className="text-red font-bold">*</span>).
            Optionally, you can add labels and comments.
          </div>
        }
      ></IEAWizardInformation>
      <div className="mt-3">
        <IEAScheduling {...props.scheduleProps} />
      </div>
    </div>
  );
}
