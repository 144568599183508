import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { useEffect, useRef } from "react";
import { loadUsersByTenantAsync } from "store/actions/UserActions";
import { useAppDispatch, useAppSelector } from "../store/hooks";

export function useLoadTenantUsers(tenantId?: number) {
  const dispatch = useAppDispatch();
  const areUsersLoaded = useRef(false);

  const tenantUserList = useAppSelector(
    (state) => state.userData.usersByTenant
  );
  const tenantUsers = tenantUserList.find(
    (t) => t.tenantId === tenantId
  )?.userList;

  const loadingUsersByTenant = useAppSelector(
    (state) => state.userData.loadingUsersByTenant
  );

  // TODO: It calls the api for the same tenant multiple times if called multiple times.
  // Investivate why it is happening and fix it.
  useEffect(() => {
    if (
      tenantId &&
      !areUsersLoaded.current &&
      (loadingUsersByTenant[tenantId] === undefined ||
        loadingUsersByTenant[tenantId] === "failed")
    ) {
      areUsersLoaded.current = true;
      displayLoadingPanel();
      dispatch(loadUsersByTenantAsync({ tenantId })).finally(hideLoadingPanel);
    }
  }, [tenantId, dispatch, loadingUsersByTenant]);

  return tenantUsers || [];
}
