import config from "react-global-configuration";
import "config";
import FieldMappingRequest from "interfaces/request/FieldMappingRequest";
import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import {
  GetNewId,
  checkIfResponseIsEqual,
  deepCopyObject,
} from "./CommonUtility";
import FieldMappingForConfigUpdateBasedOnTemplateWriteRequest from "interfaces/request/FieldMappingForConfigUpdateBasedOnTemplateWriteRequest";
import FieldValueMapForConfigUpdateFromTemplateWriteRequest from "interfaces/request/FieldValueMapForConfigUpdateFromTemplateWriteRequest";
import FieldValueMapResponse from "interfaces/response/FieldValueMapResponse";
import FieldValueMapRequest from "interfaces/request/FieldValueMapRequest";
import ConfigDetailResponse from "interfaces/response/ConfigDetailResponse";
import { ConfigStatus } from "shared/enums/feature/ConfigStatus";
import { HostSystem } from "shared/enums/feature/HostSystem";
import CoreFieldResponse from "interfaces/response/CoreFieldResponse";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";

const getConfiguration = (settingName: string) => {
  return config.get(settingName);
};

export const getApiUrl = () => {
  return process.env.REACT_APP_API_URL || getConfiguration("apiUrl");
};

export const getSignalRHubBaseUrl = () => {
  return (
    process.env.REACT_APP_SIGNALR_BASE_URL ||
    getConfiguration("signalR.hubBaseUrl")
  );
};

export const getProcessedMappingForFieldMappingForConfigUpdateBasedOnTemplateChangeRequest =
  (
    directMappings: FieldMappingResponse[],
    valueTransformations: FieldMappingResponse[]
  ): FieldMappingForConfigUpdateBasedOnTemplateWriteRequest[] => {
    let mappingRequests: FieldMappingForConfigUpdateBasedOnTemplateWriteRequest[] =
      [];
    if (valueTransformations && directMappings) {
      const allMappings: FieldMappingResponse[] =
        deepCopyObject(directMappings);

      valueTransformations.forEach((mapping) => {
        if (mapping.isConfigTemplateFieldMapping) {
          let templateFieldMapping = allMappings.find((m) =>
            checkIfResponseIsEqual(m, mapping)
          );
          if (templateFieldMapping) {
            templateFieldMapping.formula = mapping.formula;
            templateFieldMapping.valueMaps = deepCopyObject(mapping.valueMaps);
            templateFieldMapping.mappedConnectorHostFieldId =
              mapping.mappedConnectorHostFieldId;
          }
        }
      });

      mappingRequests = allMappings.map(
        getMappingForConfigUpdateBasedOnTemplateWriteRequest
      );
    }

    return mappingRequests;
  };

export const getProcessedMappingForConfigUpdateRequest = (
  directMappings: FieldMappingResponse[],
  valueTransformations: FieldMappingResponse[],
  configDirection: ConfigDirection
): FieldMappingRequest[] => {
  let mappingRequests: FieldMappingRequest[] = [];
  if (valueTransformations && directMappings) {
    const allMappings: FieldMappingResponse[] = deepCopyObject(directMappings);

    valueTransformations.forEach((mapping) => {
      if (mapping.isCoreFieldMapping) {
        allMappings.push(deepCopyObject(mapping));
      } else {
        let templateFieldMapping = allMappings.find((m) =>
          checkIfResponseIsEqual(m, mapping)
        );
        if (templateFieldMapping) {
          templateFieldMapping.formula = mapping.formula;
          templateFieldMapping.valueMaps = deepCopyObject(mapping.valueMaps);
          templateFieldMapping.mappedConnectorHostFieldId =
            mapping.mappedConnectorHostFieldId;
        }
      }
    });

    allMappings.forEach(m => {
      if(m.allowContentControl && m.isConfigTemplateFieldMapping && !!m.mappedConnectorHostFieldId){
        if (m.hasDirectMapping) {
          m.formula = GetFormulaForContentControl(m.name, configDirection);
        } else if (isFormulaDefault(m.formula ?? "")) {
          m.formula = GetFormulaForContentControl(m.name, configDirection);
        }
      }
    });

    mappingRequests = allMappings.map(getMappingRequest);
  }

  return mappingRequests;
};

export const isFormulaDefault = (formula: string)=>{
  return formula === null || formula === "" || formula === "[VALUE]"; 
}

export const getMappingRequest = (
  value: FieldMappingResponse
): FieldMappingRequest => {
  return {
    id: value.id,
    formula: value.formula,
    hasDirectMapping: value.hasDirectMapping,
    mappedConnectorHostFieldId: value.mappedConnectorHostFieldId
      ? value.mappedConnectorHostFieldId
      : null,
    fieldId: value.fieldId ? value.fieldId : null,
    coreFieldId: value.coreFieldId ?? null,
    valueMaps: value.valueMaps.map(getFieldValueMapRequest),
  };
};

export const getFieldValueMapRequest = (
  value: FieldValueMapResponse
): FieldValueMapRequest => {
  return {
    id: value.id,
    valueId: value.fieldValueId,
    templateValue: value.templateValue,
    value: value.value,
    description: value.description,
  };
};

export const getMappingForConfigUpdateBasedOnTemplateWriteRequest = (
  value: FieldMappingResponse
): FieldMappingForConfigUpdateBasedOnTemplateWriteRequest => {
  return {
    id: value.id,
    fieldId: value.fieldId ?? 0,
    hasDirectMapping: value.hasDirectMapping,
    mappedConnectorHostFieldId: value.mappedConnectorHostFieldId
      ? value.mappedConnectorHostFieldId
      : null,
    formula: value.formula,
    valueMaps: value.valueMaps.map(
      getFieldValueMapForConfigUpdateFromTemplateWriteRequest
    ),
  };
};

export const getFieldValueMapForConfigUpdateFromTemplateWriteRequest = (
  valueMap: FieldValueMapResponse
): FieldValueMapForConfigUpdateFromTemplateWriteRequest => {
  return {
    id: valueMap.id,
    valueId: valueMap.fieldValueId,
    templateValue: valueMap.templateValue,
    value: valueMap.value,
    description: valueMap.description,
  };
};

export const getEmptyConfigDetail = (): ConfigDetailResponse => {
  return {
    id: 0,
    connectorId: 0,
    connectorName: "",
    direction: 0,
    directionText: "",
    labels: [],
    mappings: [],
    scheduleCount: 0,
    schedules: [],
    status: ConfigStatus.UnderConstruction,
    statusText: "",
    templateId: 0,
    templateTitle: "",
    tenantId: 0,
    title: "",
    hostSystemParameters: "",
    createdBy: "",
    connectorHostSystem: HostSystem.None,
  };
};

export const getCoreFieldMappingResponseFromCoreFieldResponse = (
  coreField: CoreFieldResponse
): FieldMappingResponse => {
  let fieldMappingResponse = {
    _key_: GetNewId(),
    id: 0,
    configId: 0,
    coreFieldId: coreField.id,
    fieldId: 0,
    hasDirectMapping: false,
    mappedConnectorHostFieldId: 0,
    mappedConnectorHostFieldName: "",
    formula: "",
    planningObjectType: coreField.planningObjectType,
    planningObjectTypeText: coreField.planningObjectTypeText,
    allowContentControl: false,
    allowBlank: false,
    valueMaps: [],
    name: coreField.name,
    description: coreField.description,
    dataType: coreField.dataType,
    dataTypeText: coreField.dataTypeText,
    isCoreFieldMapping: true,
    isConfigTemplateFieldMapping: false,
    contentControlLevelText: "",
    values: [],
  } as FieldMappingResponse;

  return fieldMappingResponse;
};

export const GetFormulaForContentControl = (ilapTerm: string, configDirection: ConfigDirection) => {
  const mapType = configDirection === ConfigDirection.Receiving ? "D" : "U";
  const newFormula = `VM.Map_${ilapTerm}_${mapType}.Map([VALUE], "<No Such Value>")`;
  return newFormula;
};

export const GetFormulaForNoContentControl = (ilapTerm: string, configDirection: ConfigDirection) => {
  const mapType = configDirection === ConfigDirection.Receiving ? "D" : "U";
  const newFormula = `VM.Map_${ilapTerm}_${mapType}.Map([VALUE], "<No Such Value>")`;
  return newFormula;
};
