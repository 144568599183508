import ScrollView from "devextreme-react/scroll-view";
import PrimaveraExportConfig from "interfaces/host-system-config/primavera/PrimaveraExportConfig";
import { EditorMode } from "shared/enums/EditorMode";
import PrimaveraSendingParameterFields from "./PrimaveraSendingParameterFields";

interface Props {
  mode: EditorMode;
  exportConfig: PrimaveraExportConfig;
  height: number;
  selectedConnectorId: number;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  isOverride?: boolean;
}

export default function PrimaveraSendingParameters(props: Props) {
  const {
    mode,
    exportConfig,
    height,
    selectedConnectorId,
    isOverride,
    handleConfigurationValueChanged,
  } = props;

  if (!exportConfig) {
    return <></>;
  }

  return !isOverride ? (
    <ScrollView showScrollbar="always" height={height}>
      <div className="flex space-x-6">
        <div className="w-60 font-semibold text-base text-textGray">
          Primavera Sending Parameter
        </div>
        <PrimaveraSendingParameterFields
          exportConfig={exportConfig}
          mode={mode}
          selectedConnectorId={selectedConnectorId}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
          isOverride={isOverride}
          height={height}
        />
      </div>
    </ScrollView>
  ) : (
    <PrimaveraSendingParameterFields
      exportConfig={exportConfig}
      mode={mode}
      selectedConnectorId={selectedConnectorId}
      handleConfigurationValueChanged={handleConfigurationValueChanged}
      isOverride={isOverride}
      height={height}
    />
  );
}
