import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import PromineoTextBox from "components/common/controls/PromineoTextBox";
import PrimaveraConnectorConfiguration from "interfaces/response/connector/PrimaveraConnectorConfiguration";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import { ExecutionComponent } from "shared/enums/feature/ExecutionComponent";
import { PrimaveraConnectorAuthenticationMode } from "shared/enums/feature/PrimaveraConnectorAuthenticationMode";
import { checkIfHasFlag } from "shared/utilities/CommonUtility";
import { loadPrimaveraConnectorAuthenticationModeValuesAsync } from "store/actions/DropdownValueActions";
import { AppDispatch, RootState } from "store/store";
import ConnectorLabelWithContent from "../../ConnectorLabelWithContent";
import PrimaveraConnectorUserNameTokenAuthenticationContent from "./PrimaveraConnectorUserNameTokenAuthenticationContent";

interface Props {
  configuration: PrimaveraConnectorConfiguration;
  mode: PromineoModalMode;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  handleAuthenticationModeValueChanged?: (value: number) => void;
  validateOnFocusOut?: (event: any) => void;
  executionComponent: ExecutionComponent;
}

export default function PrimaveraConnectorInformation(props: Props) {
  const {
    configuration,
    mode,
    handleConfigurationValueChanged,
    handleAuthenticationModeValueChanged,
    validateOnFocusOut,
  } = props;

  const dispatch = useDispatch<AppDispatch>();

  const primaveraAuthenticationModes = useSelector(
    (state: RootState) =>
      state.dropdownData.primaveraConnectorAuthenticationMode
  );

  useEffect(() => {
    if (
      mode === PromineoModalMode.Create ||
      mode === PromineoModalMode.Modify
    ) {
      displayLoadingPanel();
      dispatch(loadPrimaveraConnectorAuthenticationModeValuesAsync()).finally(
        hideLoadingPanel
      );
    }
  }, [dispatch, mode]);

  const RenderAuthenticationModeComponent = useCallback(() => {
    return (
      <>
        {(mode === PromineoModalMode.Create ||
          mode === PromineoModalMode.Modify) && (
          <PromineoSelectBox
            placeholder="Select"
            width={mode === PromineoModalMode.Create ? 330 : 188}
            fieldName="Authentication Mode"
            required={true}
            items={primaveraAuthenticationModes.filter((m) => checkIfHasFlag(m.allowedExecutionComponents, props.executionComponent))}
            value={configuration.authenticationMode}
            valueExpr={"value"}
            displayExpr={"name"}
            onValueChange={(value: number) => {
              if (handleAuthenticationModeValueChanged) {
                handleAuthenticationModeValueChanged(value);
              }
            }}
            onFocusOut={(event: any) => {
              if (validateOnFocusOut) {
                validateOnFocusOut(event);
              }
            }}
          />
        )}

        {mode === PromineoModalMode.View &&
          configuration.authenticationModeDisplayText}
      </>
    );
  }, [
    mode,
    primaveraAuthenticationModes,
    configuration.authenticationMode,
    configuration.authenticationModeDisplayText,
    props.executionComponent,
    handleAuthenticationModeValueChanged,
    validateOnFocusOut,
  ]);

  const RenderUrlComponent = useCallback(() => {
    return (
      <>
        {(mode === PromineoModalMode.Create ||
          mode === PromineoModalMode.Modify) && (
          <PromineoTextBox
            width={mode === PromineoModalMode.Create ? 330 : 188}
            name="baseUrl"
            fieldName="Url"
            required={true}
            defaultValue={configuration.baseUrl}
            placeholder="Type..."
            onChange={({ event }: any) => {
              if (handleConfigurationValueChanged) {
                handleConfigurationValueChanged(
                  event.currentTarget.name,
                  event.currentTarget.value
                );
              }
            }}
            onFocusOut={(event: any) => {
              if (validateOnFocusOut) {
                validateOnFocusOut(event);
              }
            }}
          />
        )}

        {mode === PromineoModalMode.View && configuration.baseUrl}
      </>
    );
  }, [mode, configuration.baseUrl]);

  return (
    <div>
      <div
        className={
          props.mode === PromineoModalMode.View ||
          props.mode === PromineoModalMode.Modify
            ? "grid grid-cols-2 gap-x-60"
            : ""
        }
      >
        <ConnectorLabelWithContent
          mode={mode}
          addAsterisksymbol={true}
          label="Authentication type"
          content={<RenderAuthenticationModeComponent />}
        />
        <ConnectorLabelWithContent
          mode={mode}
          addAsterisksymbol={true}
          label="URL"
          content={<RenderUrlComponent />}
        />
      </div>

      {configuration.authenticationMode ===
        PrimaveraConnectorAuthenticationMode.UserNameTokenProfile && (
        <PrimaveraConnectorUserNameTokenAuthenticationContent
          mode={mode}
          userName={configuration.username}
          password={configuration.password}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
          validateOnFocusOut={validateOnFocusOut}
        />
      )}
    </div>
  );
}
