import PromineoChipContainer from "components/common/controls/promineo-chip/PromineoChipContainer";
import UserSimplifiedResponse from "interfaces/response/UserSimplifiedResponse";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getUserToShowCount } from "shared/utilities/IEAUtility";
import { RootState } from "store/store";

export interface RepresentativeSelectionItem {
  id: number;
  text: string;
}

interface Props {
  isOwner?: boolean;
  width: number;
  showAddButton?: boolean;
  canRemoveChip?: boolean;
  totalSupportedLines: number;
  componentId: string;
  selectedUserIds: number[];
  representativeList: UserSimplifiedResponse[];
  onSelectedItemsChange?: (userIdList: number[]) => void;
}
export default function RepresentativeComponent(props: Props) {
  const [selctedUsersToShow, setSelectedUsersToShow] = useState<
    RepresentativeSelectionItem[]
  >([]);
  const [hiddenSelectedUsers, setHiddenSelectedUsers] = useState<
    RepresentativeSelectionItem[]
  >([]);
  const [userAddCandidateList, setUserAddCandidateList] = useState<
    RepresentativeSelectionItem[]
  >([]);
  const isInitialized = useRef(false);

  const loggedInUser = useSelector(
    (state: RootState) => state.userData.mySelfResponse
  );

  const mapUsersToItems = (
    users: UserSimplifiedResponse[],
    isDisabled: boolean
  ) => {
    return users.map((u) => ({
      id: u.id,
      text: u.fullName,
      isDisabled: u.id === loggedInUser?.id ? true : isDisabled,
    }));
  };

  const setUserSelectionData = (
    selectedUserIds: number[],
    representativeList: UserSimplifiedResponse[]
  ) => {
    if (loggedInUser && props.representativeList) {
      let selectedUsersSet = new Set(selectedUserIds);
      let selectedUsers = representativeList.filter((u) =>
        selectedUsersSet.has(u.id)
      );
      let usersToShow = getUserToShowCount(
        props.width,
        representativeList,
        selectedUsers.map((m) => m.id),
        props.totalSupportedLines
      );
      const unselectedUsers = representativeList.filter(
        (u) => !selectedUsersSet.has(u.id)
      );

      const selectedUsersToShow = selectedUsers
        .slice(0, usersToShow)
        .sort((a, b) => 
          // Moving the logged in user to the top of the list
          a.id === loggedInUser.id ? -1 : b.id === loggedInUser.id ? 1 : 0
        );

      const hiddenSelectedUsers = selectedUsers.slice(usersToShow);
      setSelectedUsersToShow(mapUsersToItems(selectedUsersToShow, false));
      setUserAddCandidateList(mapUsersToItems(unselectedUsers, false));
      setHiddenSelectedUsers(mapUsersToItems(hiddenSelectedUsers, false));
    }
  };

  useEffect(() => {
    if (
      loggedInUser &&
      props.selectedUserIds &&
      props.representativeList &&
      props.representativeList.length &&
      !isInitialized.current
    ) {
      setUserSelectionData(props.selectedUserIds, props.representativeList);
      isInitialized.current = true;
    }
  }, [props.selectedUserIds, props.representativeList, loggedInUser]);

  const onSelectionValueChange = (userIdList: number[]) => {
    setUserSelectionData(userIdList, props.representativeList);
    if (props.onSelectedItemsChange) {
      props.onSelectedItemsChange(userIdList);
    }
  };

  return (
    <div style={{ width: props.width ?? "auto" }}>
      <PromineoChipContainer
        selectedItemsToShow={selctedUsersToShow}
        hiddenSelectedItems={hiddenSelectedUsers}
        itemAddCandidateList={userAddCandidateList}
        componentId={props.componentId}
        showAddButton={props.showAddButton}
        canRemoveChip={props.canRemoveChip}
        onSelectedItemsChange={onSelectionValueChange}
        searchPlaceholder={"Search representatives"}
      />
    </div>
  );
}
