import LabelWithContent from "components/common/LabelWithContent";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";
import { FrequencyType } from "shared/enums/feature/FrequencyType";
import FrequencyComponent from "../FrequencyComponent";

interface Props {
  ownerExchangeRole: ExchangeRoleEnum;
  onValueChange: (fieldName: string, value: any) => void;
  defaultValue?: string;
  lagInMinutes?: number;
  onPredecessorAgreementIdChange?: (value: number) => void;
  onLagMinutesChange?: (value: number) => void;
  onCronExpressionChange: (value: string) => void;
  onFrequencyTypeChange?: (value: FrequencyType) => void;
  frequencyType?: FrequencyType;
  selectedExchangeAgreementId?: number;
  tooltipText?: string;
}

export default function FrequencyEditor(props: Props) {
  return (
    <LabelWithContent
      label="Frequency"
      addAsterisksymbol={true}
      content={
        <FrequencyComponent
          selectedExchangeAgreementId={props.selectedExchangeAgreementId}
          ownerExchangeRole={props.ownerExchangeRole}
          defaultValue={props.defaultValue}
          lagInMinutes={props.lagInMinutes}
          onPredecessorAgreementIdChange={props.onPredecessorAgreementIdChange}
          onLagMinutesChange={props.onLagMinutesChange}
          onFrequencyTypeChange={props.onFrequencyTypeChange}
          onCronExpressionChange={props.onCronExpressionChange}
          frequencyType={props.frequencyType}
        />
      }
      tooltipText={props.tooltipText}
    />
  );
}
