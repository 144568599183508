import { ReactNode, useLayoutEffect, useState } from "react";
import "./style/IEAScrollView.css";
import ScrollView from "devextreme-react/scroll-view";

interface Props {
  childElement: ReactNode;
  divIdsToExclude: string[];
}

export default function IEAScrollView(props: Props) {
  const { childElement, divIdsToExclude } = props;
  const [scrollViewHeight, setScrollViewHeight] = useState(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      let heightToExclude = 0;
      const currentWindowHeight = window.innerHeight;
      const marginHeight = 110;

      if (divIdsToExclude) {
        for (let index = 0; index < divIdsToExclude.length; index++) {
          let height = document.getElementById(divIdsToExclude[index])?.clientHeight ?? 0;
          heightToExclude += height;
        }
      }

      const scrollHeight = currentWindowHeight - heightToExclude - marginHeight;

      setScrollViewHeight(scrollHeight);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  return (
    <ScrollView
      className="iea-scrollview"
      showScrollbar="always"
      height={scrollViewHeight}
    >
      {childElement}
    </ScrollView>
  );
}