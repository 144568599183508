import LabelWithContent from "components/common/LabelWithContent";
import HostSystemConfigurationTextEditor from "../HostSystemConfigurationTextEditor";
import HostSystemConfigurationSwitchButton from "../HostSystemConfigurationSwitchButton";
import { EditorMode } from "shared/enums/EditorMode";
import PrimaveraImportConfig from "interfaces/host-system-config/primavera/PrimaveraImportConfig";
import HostSystemConfigurationDropdown from "../HostSystemConfigurationDropdown";
import DropdownValuesResponse from "interfaces/response/DropdownValuesResponse";

const epsIdPropertyName = "epsId";
const allowAppendingTasksPropertyName = "allowAppendingTasks";
const allowAppendResourcePropertyName = "allowAppendResource";
const allowAppendingCalendarsPropertyName = "allowAppendingCalendars";
const allowAppendingStructuresPropertyName = "allowAppendingStructures";
const doNotImportWbsPropertyName = "doNotImportWbs";
const activityLinkRebuildOptionPropertyName = "activityLinkRebuildOption";

interface Props {
  mode: EditorMode;
  importConfig: PrimaveraImportConfig;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  activityLinkRebuildOptions: DropdownValuesResponse[];
}

export default function PrimaveraReceivingFields(props: Props) {
  const {
    mode,
    importConfig,
    activityLinkRebuildOptions,
    handleConfigurationValueChanged,
  } = props;
  return (
    <>
      <LabelWithContent
        label="EPS"
        content={
          <HostSystemConfigurationTextEditor
            mode={mode}
            fieldName={epsIdPropertyName}
            textBoxValue={importConfig.epsId ?? ""}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
          />
        }
      />
      <LabelWithContent
        label="Allow appending tasks"
        content={
          <HostSystemConfigurationSwitchButton
            mode={mode}
            fieldName={allowAppendingTasksPropertyName}
            switchButtonValue={importConfig.allowAppendingTasks}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
          />
        }
      />
      <LabelWithContent
        label="Allow append resource"
        content={
          <HostSystemConfigurationSwitchButton
            mode={mode}
            fieldName={allowAppendResourcePropertyName}
            switchButtonValue={importConfig.allowAppendResource}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
          />
        }
      />
      <LabelWithContent
        label="Allow appending calendars"
        content={
          <HostSystemConfigurationSwitchButton
            mode={mode}
            fieldName={allowAppendingCalendarsPropertyName}
            switchButtonValue={importConfig.allowAppendingCalendars}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
          />
        }
      />
      <LabelWithContent
        label="Allow appending structures"
        content={
          <HostSystemConfigurationSwitchButton
            mode={mode}
            fieldName={allowAppendingStructuresPropertyName}
            switchButtonValue={importConfig.allowAppendingStructures}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
          />
        }
      />
      <LabelWithContent
        label="Do not import WBS"
        content={
          <HostSystemConfigurationSwitchButton
            mode={mode}
            fieldName={doNotImportWbsPropertyName}
            switchButtonValue={importConfig.doNotImportWbs}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
          />
        }
      />
      <LabelWithContent
        label="Link Options"
        content={
          <HostSystemConfigurationDropdown
            mode={mode}
            dropdownOptions={activityLinkRebuildOptions}
            fieldName={activityLinkRebuildOptionPropertyName}
            selectedValue={importConfig.activityLinkRebuildOption}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
          />
        }
      />
    </>
  );
}
