export enum HostSystem {
  None = 0,
  MicrosoftExcel = 1,
  Safran = 2,
  IlapAnalytics = 3,
  MicrosoftProject = 4,
  PrimaveraP6 = 5,
  SAP = 6,
  FileStorage = 7
}
