import ConnectorScheduleResponse from "interfaces/response/ConnectorScheduleResponse";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import {
  getFormattedDate,
  getFormattedDateTime,
} from "shared/utilities/DateTimeUtility";
import "components/common/grid/styles/PromineoUIGrid.css";
import { stringAndNumberCombinationSortComparator } from "shared/utilities/CommonUtility";
import { useAppDispatch } from "store/hooks";
import { useEffect, useState } from "react";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { loadConnectorHostSchdulesAsync } from "store/actions/ConnectorActions";
import { unwrapResult } from "@reduxjs/toolkit";
import { toastError } from "shared/utilities/ToastUtility";

interface Props {
  connectorId: number;
  height: number;
}

export default function ScheduleGrid(props: Props) {
  const { connectorId, height } = props;

  const dispatch = useAppDispatch();
  const [hostSchedules, setHostSchedules] = useState<
    ConnectorScheduleResponse[]
  >([]);

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadConnectorHostSchdulesAsync(connectorId))
      .then(unwrapResult)
      .then((hostFieldResponse: ConnectorScheduleResponse[]) => {
        setHostSchedules(hostFieldResponse);
      })
      .catch(() => {
        toastError(
          `Failed to load host schedules for connector with id ${connectorId}.`
        );
      })
      .finally(hideLoadingPanel);
  }, [connectorId, dispatch]);

  return (
    <>
      <PromineoViewGrid
        dataSource={hostSchedules}
        className="connectors-schedule-grid disable-hover promineo-ui-grid"
        toolbarConfig={{
          dislplaySearchPanel: true,
        }}
        height={height}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <Column
          caption={"Schedule ID"}
          dataField="scheduleId"
          alignment="left"
          width={200}
          sortingMethod={stringAndNumberCombinationSortComparator}
          sortOrder={"asc"}
        />
        <Column caption={"Title"} dataField="title" alignment="left" />
        <Column
          caption={"Start Date"}
          dataField="startDate"
          alignment="left"
          customizeText={(data: any) =>
            data?.value ? getFormattedDate(data.value) : "-"
          }
          width={200}
        />
        <Column
          caption={"Finish Date"}
          dataField="finishDate"
          alignment="left"
          customizeText={(data: any) =>
            data?.value ? getFormattedDate(data.value) : "-"
          }
          width={200}
        />
        <Column
          caption={"Last Updated"}
          dataField="lastUpdatedDate"
          alignment="left"
          customizeText={(data: any) =>
            data?.value ? getFormattedDateTime(data.value) : "-"
          }
          width={200}
        />
        <Column
          caption={"Code set"}
          cssClass="add-left-border"
          dataField="userFieldSetId"
          alignment="left"
          width={200}
        />
      </PromineoViewGrid>
    </>
  );
}
