import { createSlice } from "@reduxjs/toolkit";
import {
  loadConnectorsForCurrentUserAsync,
  loadConnectorDetailsAsync,
  createNewConnectorAsync,
  modifyConnectorAsync,
  loadSimplifiedConnectorsAsync,
  removeConnectorAsync,
  loadConnectorHostFieldAsync,
  loadConnectorAsync,
  loadConnectorHostSchdulesAsync,
} from "store/actions/ConnectorActions";
import ConnectorResponse from "interfaces/response/connector/ConnectorResponse";
import ConnectorDetailsResponse from "interfaces/response/connector/ConnectorDetailsResponse";
import { toastSuccess } from "shared/utilities/ToastUtility";
import ConnectorBaseResponse from "interfaces/response/connector/ConnectorBaseResponse";
import HostFieldResponse from "interfaces/response/HostFieldResponse";
import ConnectorScheduleResponse from "interfaces/response/ConnectorScheduleResponse";

export interface ConnectorState {
  connectors: ConnectorResponse[];
  selectedDetailedConnector: ConnectorDetailsResponse | null;
  simplifiedConnectors: ConnectorBaseResponse[];
  connectorHostFields: HostFieldResponse[];
  connectorHostSchedules: ConnectorScheduleResponse[];
}

const initialState: ConnectorState = {
  connectors: [],
  selectedDetailedConnector: null,
  simplifiedConnectors: [],
  connectorHostFields: [],
  connectorHostSchedules: [],
};

export const connectorSlice = createSlice({
  name: "connectors",
  initialState,
  reducers: {
    resetSelectedDetailedConnector: (state) => {
      state.selectedDetailedConnector = null;
    },
    resetConnectorHostFields: (state) => {
      state.connectorHostFields = [];
    },
    resetConnectorHostSchedules: (state) => {
      state.connectorHostSchedules = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadConnectorsForCurrentUserAsync.fulfilled,
      (state, action) => {
        state.connectors = action.payload;
      }
    );
    builder.addCase(loadConnectorDetailsAsync.fulfilled, (state, action) => {
      state.selectedDetailedConnector = action.payload;
    });
    builder.addCase(loadConnectorAsync.fulfilled, (state, action) => {
      state.selectedDetailedConnector = {
        ...action.payload,
        hostFields: [],
        hostSchedules: [],
      };
    });
    builder.addCase(createNewConnectorAsync.fulfilled, (state, action) => {
      toastSuccess("Created Connector successfully.");
    });
    builder.addCase(modifyConnectorAsync.fulfilled, (state, action) => {
      toastSuccess("Updated connector successfully.");
    });
    builder.addCase(loadSimplifiedConnectorsAsync.pending, (state, action) => {
      state.simplifiedConnectors = [];
    });
    builder.addCase(
      loadSimplifiedConnectorsAsync.fulfilled,
      (state, action) => {
        state.simplifiedConnectors = action.payload;
      }
    );
    builder.addCase(loadConnectorHostFieldAsync.fulfilled, (state, action) => {
      state.connectorHostFields = action.payload;
      // update selectedConnector details host fields array
      if (action.meta.arg.connectorId === state.selectedDetailedConnector?.id) {
        let updatedConnectorHostFieldList: HostFieldResponse[] =
          state.selectedDetailedConnector?.hostFields || [];

        let existingHostFieldsIdsSet = new Set(
          updatedConnectorHostFieldList.map((hf) => hf.id)
        );

        let newHostFieldsToInsert = action.payload.filter(
          (hf) => !existingHostFieldsIdsSet.has(hf.id)
        );
        if(newHostFieldsToInsert && newHostFieldsToInsert.length > 0){
          updatedConnectorHostFieldList.push(...newHostFieldsToInsert);

          updatedConnectorHostFieldList.sort((x,y)=> {
            if(x.userFieldSetId && y.userFieldSetId) return 0;
            if(!x.userFieldSetId && !y.userFieldSetId) return 0;
            if(x.userFieldSetId && !y.userFieldSetId) return 1;
            return -1;
          });
          state.selectedDetailedConnector.hostFields =
            updatedConnectorHostFieldList;
        }
      }
    });
    builder.addCase(
      loadConnectorHostSchdulesAsync.fulfilled,
      (state, action) => {
        state.connectorHostSchedules = action.payload;

        if (action.meta.arg === state.selectedDetailedConnector?.id) {
          state.selectedDetailedConnector.hostSchedules = action.payload;
        }
      }
    );
    builder.addCase(removeConnectorAsync.fulfilled, (state, action) => {
      toastSuccess("Connector deleted successfully.");
    });
  },
});

export const {
  resetSelectedDetailedConnector,
  resetConnectorHostSchedules,
  resetConnectorHostFields,
} = connectorSlice.actions;

export default connectorSlice.reducer;
