import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import { ISelectBoxOptions } from "devextreme-react/select-box";
import ExchangeAgreementSimplifiedResponse from "interfaces/response/ExchangeAgreementSimplifiedResponse";
import { useCallback } from "react";

interface Props {
  exchangeAgreements: ExchangeAgreementSimplifiedResponse[];
  defaultValue?: number;
  onValueChange: (value: number) => void;
}
export default function PredecessorIeaSelectionDropdown(
  props: Props & ISelectBoxOptions
) {
  const { exchangeAgreements, onValueChange, dataSource, ...rest } = props;
  const handleChange = (evt: any) => {
    if (evt.value !== null) {
      onValueChange(evt.value);
    }
  };

  const fieldTemplate = useCallback(
    (data: ExchangeAgreementSimplifiedResponse) => {
      return (
        <div className="text-10px font-inter font-medium pl-2 pt-1 w-6 h-4">
          {!data ? (
            "Select IEA ID"
          ) : (
            <div className="w-full h-full break-all overflow-hidden">{`${data?.id} ${data?.title}`}</div>
          )}
        </div>
      );
    },
    []
  );

  const itemTemplate = useCallback(
    (data: ExchangeAgreementSimplifiedResponse) => {
      return (
        <div className="text-10px font-inter font-medium overflow-hidden">
          {!data ? (
            "Select IEA ID"
          ) : (
            <div>{`${data?.id} ${data?.title}`}</div>
          )}
        </div>
      );
    },
    []
  );

  return (
    <PromineoSelectBox
      placeholder="Select IEA ID"
      items={exchangeAgreements}
      defaultValue={props.defaultValue}
      valueExpr="id"
      displayExpr="title"
      searchEnabled={true}
      searchMode="contains"
      onValueChanged={handleChange}
      fieldRender={fieldTemplate}
      itemRender={itemTemplate}
      {...rest}
    />
  );
}
