import CautionIcon from "components/icons/CautionIcon";
import { ReactNode } from "react";

interface Props {
  headerInformation: string | ReactNode;
}

export function ActivePageHeaderLabel() {
  return (
    <div className="h-6 w-14 px-2 pt-1 bg-lightGreen border border-darkGreen rounded text-xs font-inter font-medium text-darkGreen">
      Active
    </div>
  );
}

export function UnderConstructionPageHeaderLabel() {
  return (
    <div className="h-6 px-2 py-1 bg-purple border border-darkPurple rounded text-xs font-inter font-medium text-darkPurple">
      Under construction
    </div>
  );
}

export function PendingStartDatePageHeaderLabel() {
  return (
    <div className="h-6 px-2 py-1 bg-purple border border-darkPurple rounded text-xs font-inter font-medium text-darkPurple">
      Pending start date
    </div>
  );
}

export function ExpiredPageHeaderLabel() {
  return (
    <div className="h-6 px-2 py-1 bg-lightGray border border-textGray rounded text-xs font-inter font-medium text-textGray">
      Expired
    </div>
  );
}

export function PageHeaderInformationLabel(props: Props) {
  return (
    <div className="h-10 w-424px rounded bg-ilapLightBlue flex items-center">
      <div className="m-2">
        <CautionIcon />
      </div>
      <div className="mx-2 my-12.5px text-darkBlue font-poppins font-semibold text-10px leading-15px">
        {props.headerInformation}
      </div>
    </div>
  );
}
