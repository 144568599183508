import { EditorMode } from "shared/enums/EditorMode";
import AnalyticsBaseConfig from "interfaces/host-system-config/analytics/AnalyticsBaseConfig";
import RevisionTypeField from "features/common/host-system-parameter-fields/analytics/RevisionTypeField";
import { revisionTypePropertyName } from "shared/constants/AnalyticsHostParameterPropertyName";

interface Props {
  mode: EditorMode;
  config: AnalyticsBaseConfig;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
}

export default function AnalyticsSendingFields(props: Props) {
  const { mode, config, handleConfigurationValueChanged } = props;

  return (
    <RevisionTypeField
      fieldName={revisionTypePropertyName}
      selectedRevisionType={config.revisionType}
      mode={mode}
      handleConfigurationValueChanged={handleConfigurationValueChanged}
    />
  );
}
