import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import TickIcon from "components/icons/TickIcon";
import DropdownValuesResponse from "interfaces/response/DropdownValuesResponse";
import { useState } from "react";
import { TenantStatusCodes } from "shared/enums/TenantStatus";
import { toastError, toastSuccess } from "shared/utilities/ToastUtility";
import {
  acceptTenantRegistrationRequestAsync,
  rejectTenantRegistrationRequestAsync,
} from "store/actions/TenantActions";
import { useAppDispatch } from "store/hooks";

interface Props {
  tenantId: number;
  tenantStatuses: DropdownValuesResponse[];
  tenantStatus: TenantStatusCodes;
  readonly?: boolean;
}

export default function TenantStatusLabel(props: Props) {
  const tenantStatus = props.tenantStatus;
  const dispatch = useAppDispatch();
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [statusToChange, setStatusToChange] = useState<TenantStatusCodes>();

  const doesNotHaveSecretSetPermissionMsgPart =
    "does not have secrets set permission";

  const tenantStatusDisplay =
    (props.tenantStatuses || []).find((t) => t.value === props.tenantStatus)
      ?.name ?? "";

  const commonClasses =
    "flex justify-center items-center rounded-lg py-1 px-2 font-inter text-sm leading-[18px] font-normal";

  const handleStatusChange = (value: number) => {
    setStatusToChange(value);
    setDisplayConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setDisplayConfirmation(false);
  };

  const confirmSelectionChange = () => {
    if (statusToChange) {
      if (statusToChange === TenantStatusCodes.Active) {
        displayLoadingPanel();
        dispatch(acceptTenantRegistrationRequestAsync(props.tenantId))
          .unwrap()
          .then(() => {
            toastSuccess("Tenant approved successfully.");
          })
          .catch((error) => {
            if (error && error.message) {
              let message = error.messag;
              if (
                error.message.indexOf(doesNotHaveSecretSetPermissionMsgPart) >=
                0
              ) {
                message =
                  "Failed to generate encryption keys, preventing approval of the tenant request.";
              }
              toastError(message);
            }
          })
          .finally(hideLoadingPanel);
      } else if (statusToChange === TenantStatusCodes.Rejected) {
        displayLoadingPanel();
        dispatch(rejectTenantRegistrationRequestAsync(props.tenantId))
          .unwrap()
          .then(() => {
            toastSuccess("Tenant rejected successfully.");
          })
          .finally(hideLoadingPanel);
      }
      setDisplayConfirmation(false);
    }
  };

  if (tenantStatus === TenantStatusCodes.Active) {
    return (
      <div className="w-[74px]">
        <div className={`bg-lightGreen ${commonClasses} gap-x-[3px]`}>
          <TickIcon />
          Active
        </div>
      </div>
    );
  }

  if (tenantStatus === TenantStatusCodes.Rejected) {
    return (
      <div className="w-[165px]">
        <div className={`bg-lightGray text-textGray ${commonClasses}`}>
          Tenant request denied
        </div>
      </div>
    );
  }

  if (props.readonly) {
    return <>{tenantStatusDisplay}</>;
  }

  return (
    <>
      <PromineoSelectBox
        valueExpr={"value"}
        displayExpr={"name"}
        items={props.tenantStatuses}
        width={162}
        onValueChange={handleStatusChange}
        value={props.tenantStatus}
      />
      {displayConfirmation && (
        <PromineoConfirmationDialog
          content={`Are you sure, you want to ${
            statusToChange === TenantStatusCodes.Active ? "approve" : "reject"
          } the tenant request?`}
          visible={true}
          onConfirm={confirmSelectionChange}
          onCancel={handleCloseConfirmation}
          cancelButtonText="No"
          confirmButtonText="Yes"
        />
      )}
    </>
  );
}
