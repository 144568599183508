import LabelWithContent from "components/common/LabelWithContent";
import BaseHostSystemComponentProps from "../BaseHostSystemComponentProps";
import HostSystemConfigurationSwitchButton from "features/setup/configs/host-system/HostSystemConfigurationSwitchButton";

interface Props extends BaseHostSystemComponentProps {
  switchButtonValue: boolean;
}

export default function AdjustCutoffField(props: Props) {
  const { mode, switchButtonValue, fieldName, handleConfigurationValueChanged } = props;

  return (
    <LabelWithContent
      label="Adjust Cutoff"
      content={
        <HostSystemConfigurationSwitchButton
          mode={mode}
          fieldName={fieldName}
          switchButtonValue={switchButtonValue}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
        />
      }
    />
  );
}
