import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import PromineoModal from "components/modal/PromineoModal";
import { ScrollView } from "devextreme-react/scroll-view";

interface Props {
  validationMessagForDelete: string[];
  onClose: () => void;
}

export default function TemplateDeleteValidationDialog(props: Props) {
  const { validationMessagForDelete, onClose } = props;
  const templateDeleteErrorTitle = "Template cannot be deleted";
  return (
    <PromineoModal
      width={500}
      height={"auto"}
      isVisible={!!validationMessagForDelete}
    >
      <div className="text-center">
        <div className="text-xl mb-4">{templateDeleteErrorTitle}</div>
        <div className="mb-4 text-xs">{`It is used by these config(s):`}</div>
        <ScrollView height={480}>
          <div>
            <ul className="text-xs">
              {validationMessagForDelete.map(
                (m, ind) => {
                  return <li key={ind}>{m}</li>;
                }
              )}
            </ul>
          </div>
        </ScrollView>
      </div>
      <div className="mt-4 py-4">
        <PromineoButton
          text="Back"
          variant={PromineoButtonType.Secondary}
          onClick={onClose}
        />
      </div>
    </PromineoModal>
  );
}
