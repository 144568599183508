import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import { ISelectBoxOptions } from "devextreme-react/select-box";
import HostFieldResponse from "interfaces/response/HostFieldResponse";
import { useCallback } from "react";
import { PlanningObjectTypes } from "shared/enums/feature/PlanningObjectTypes";
import { useAppSelector } from "store/hooks";

interface Props {
  selectedCodeSet?: number | null;
  planningObjectType: PlanningObjectTypes;
  onHostFieldSelectionChange?: (data: HostFieldResponse) => void;
  showClearButton?: boolean;
}

export default function HostFieldSelectionDropdown(
  props: Props & ISelectBoxOptions
) {
  const { dataSource, ...rest } = props;

  const hostFields = useAppSelector(
    (root) => root.connectorData.selectedDetailedConnector?.hostFields
  );

  const handleChange = (evt: any) => {
    if (evt.value === null) {
      props.onHostFieldSelectionChange?.(evt.value);
    } else {
      const selectedHostField = hostFields?.find((hf) => hf.id === evt.value);
      if (selectedHostField) {
        props.onHostFieldSelectionChange?.(selectedHostField);
      }
    }
  };

  const hostFieldItemTemplate = useCallback((data: HostFieldResponse)=>{
    return(<div title={data.displayText}>{data.displayText}</div>);
  },[]);

  return (
    <PromineoSelectBox
      placeholder="Select"
      defaultValue={props.defaultValue}
      itemRender={hostFieldItemTemplate}
      items={hostFields?.filter(
        (f: HostFieldResponse) =>
          f.planningObjectType === props.planningObjectType &&
          (!f.userFieldSetId ||
            (props.selectedCodeSet &&
              props.selectedCodeSet === f.userFieldSetId))
      )}
      valueExpr="id"
      displayExpr="displayText"
      searchEnabled={true}
      searchMode="contains"
      onValueChanged={handleChange}
      {...rest}
    />
  );
}
