interface Props {
  text?: string;
  textComponent?: React.ReactElement;
}

export const WizardInformationTextStyles =
  "text-textGray text-sm font-poppins font-normal";

export default function IEAWizardInformation(props: Props) {
  return (
    <div className="border-b border-lightGray py-2 ">
      {props.textComponent ?? (
        <span className={WizardInformationTextStyles}>{props.text}</span>
      )}
    </div>
  );
}
