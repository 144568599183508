import { ActivityFilter } from "interfaces/common/ActivityFilter";

export const safranActivityFilterList: ActivityFilter[] = [
  { title: `R3 = "T"`, example: `(uf_ref3="T")` },
  {
    title: `Flag8=true and EarlyStart is not blank`,
    example: `(uf_flag8=true) && (General.IsNull(Activity.EarlyStart)=false)`,
  },
  {
    title: `Date1 field comes after a week from today`,
    example: `(uf_date1>DateTime.Today.AddDays(7))`,
  },
  { title: `Dec1 is greater than 10`, example: `(uf_dec1>10.0)` },
  {
    title: `Outline1 has more than 5 characters`,
    example: `(uf_out1.Length>5)`,
  },
  {
    title: `R30 field's description is longer than 20 characters`,
    example: `(uf_ref30_des.Length>20)`,
  },
  {
    title: `Unusual case: Only export activities having Text2 with numeric values`,
    example: `(Sys.ConvertToInt(uf_text2, true, 9999)<>9999)`,
  },
];
