import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import TenantUserResponse from "interfaces/response/tenant/TenantUserResponse";
import { useState } from "react";
import { UserStatus } from "shared/enums/UserStatus";

interface Props {
  user: TenantUserResponse;
  isDirty: boolean;
  onClose: () => void;
  onSaveUser: () => void;
  onUserStatusChange: () => void;
}

export default function EditUserDialogFooter(props: Props) {
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const handleActivateOrDeactivateUserClick = () => {
    setIsDialogVisible(true);
  };

  const confirmationText = `Are you sure you want to ${
    props.user.status === UserStatus.Active ? "disable" : "activate"
  } ${props.user.fullName}?`;

  const handleConfirmDialog = () => {
    props.onUserStatusChange();
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    setIsDialogVisible(false);
  };

  return (
    <>
      <div className="flex justify-between border-t border-lightGray pt-2 pb-4">
        <div>
          <PromineoButton
            variant={PromineoButtonType.Secondary}
            text="Close"
            onClick={props.onClose}
          />
        </div>
        <div className="flex justify-between">
          {props.user.status === UserStatus.Disabled ||
          props.user.status === UserStatus.Active ? (
            <PromineoButton
              text={
                props.user.status === UserStatus.Active ? "Disable" : "Activate"
              }
              variant={
                props.user.status === UserStatus.Active
                  ? PromineoButtonType.BorderlessDanger
                  : PromineoButtonType.Primary
              }
              onClick={handleActivateOrDeactivateUserClick}
            />
          ) : null}
          {props.user.status === UserStatus.Active ? (
            <PromineoButton
              disabled={!props.isDirty}
              text="Save"
              onClick={props.onSaveUser}
            />
          ) : null}
        </div>
      </div>
      <PromineoConfirmationDialog
        content={confirmationText}
        visible={isDialogVisible}
        onConfirm={handleConfirmDialog}
        onCancel={handleCloseDialog}
        confirmButtonText="Confirm"
      />
    </>
  );
}
