import Tooltip, { ITooltipOptions } from "devextreme-react/tooltip";
import "./styles/PromineoTooltip.css";

interface Props {
  text?: string;
}

export default function PromineoTooltip(props: Props & ITooltipOptions) {
  const { children, text, ...rest } = props;
  return (
    <Tooltip {...rest} height={"auto"}>
      {children}
      {props.text && (
        <p className="max-w-[410px] leading-[21px] line-clamp-3 text-sm">
          {text}
        </p>
      )}
    </Tooltip>
  );
}
