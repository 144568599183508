import {
  copyConfigAsync,
  createConfigAsync,
  deleteConfigAsync,
  getConfigByIdAsync,
  getConfigForExchangeAgreementAsync,
  getConfigsAsync,
  getConfigTemplateChangesPreviewAsync,
  getLinkedExchangeAgreementsForConfigAsync,
  getOwnerConfigForExchangeAgreementAsync,
  getPartnerConfigForExchangeAgreementAsync,
  publishConfigAsync,
  updateConfigAcceptingTemplateChangesAsync,
  updateConfigAsync,
} from "apis/ConfigApi";
import ConfigCreateRequest from "interfaces/request/ConfigCreateRequest";
import ConfigUpdateBasedOnTemplateWriteRequest from "interfaces/request/ConfigUpdateBasedOnTemplateWriteRequest";
import ConfigUpdateRequest from "interfaces/request/ConfigUpdateRequest";
import ConfigDetailResponse from "interfaces/response/ConfigDetailResponse";
import ConfigResponse from "interfaces/response/ConfigResponse";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { createAppAsyncThunk } from "store/ThunkUtility";

export const loadConfigByIdAsync = createAppAsyncThunk(
  "config/loadConfigById",
  async (configId: number) => {
    return await getConfigByIdAsync(configId);
  }
);

export const loadConfigsAsync = createAppAsyncThunk(
  "config/loadConfigs",
  async () => {
    return await getConfigsAsync();
  }
);

export const loadLinkedExchangeAgreementsAsync = createAppAsyncThunk(
  "config/loadLinkedExchangeAgreements",
  async (configId: number) => {
    return await getLinkedExchangeAgreementsForConfigAsync(configId);
  }
);

export const createNewConfigAsync = createAppAsyncThunk<ConfigDetailResponse, ConfigCreateRequest>(
  "config/createNewConfig",
  async (config: ConfigCreateRequest) => {
    return await createConfigAsync(config);
  }
);

export const modifyConfigAsync = createAppAsyncThunk<
  ConfigDetailResponse,
  { configId: number; config: ConfigUpdateRequest }
>(
  "config/modifyConfig",
  async (
    configRequest: { configId: number; config: ConfigUpdateRequest },
    { rejectWithValue }
  ) => {
    try {
      return await updateConfigAsync(
        configRequest.configId,
        configRequest.config
      );
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const makeConfigPublisedAsync = createAppAsyncThunk(
  "config/makeConfigPublised",
  async (configId: number, { rejectWithValue }) => {
    try {
      return await publishConfigAsync(configId);
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const removeConfigAsync = createAppAsyncThunk(
  "config/removeConfigAsync",
  async (configId: number) => {
    return await deleteConfigAsync(configId);
  }
);

export const loadOwnerConfigForExchangeAgreementAsync = createAppAsyncThunk(
  "config/loadOwnerConfigForExchangeAgreementAsync",
  async () => {
    return await getOwnerConfigForExchangeAgreementAsync();
  }
);

export const loadPartnerConfigForExchangeAgreementAsync = createAppAsyncThunk<
  any,
  { templateId: number; direction: ConfigDirection }
>("config/loadPartnerConfigForExchangeAgreementAsync", async (request) => {
  return await getPartnerConfigForExchangeAgreementAsync(
    request.templateId,
    request.direction
  );
});

export const loadConfigForExchangeAgreementAsync = createAppAsyncThunk<
  ConfigResponse,
  {
    templateId: number;
    connectorId: number;
    scheduleId: number;
    direction: ConfigDirection;
  }
>("config/loadConfigForExchangeAgreement", async (request) => {
  return await getConfigForExchangeAgreementAsync(
    request.templateId,
    request.connectorId,
    request.scheduleId,
    request.direction
  );
});

export const copyConfigByIdAsync = createAppAsyncThunk(
  "config/copyConfigById",
  async (configId: number) => {
    return await copyConfigAsync(configId);
  }
);

export const loadTemplateChangesPreviewAsync = createAppAsyncThunk(
  "config/loadTemplateChangesPreview",
  async (configId: number) => {
    return await getConfigTemplateChangesPreviewAsync(configId);
  }
);

export const modifyConfigByAcceptingTemplateChangesAsync = createAppAsyncThunk<
  ConfigDetailResponse,
  {
    configId: number;
    configUpdateRequest: ConfigUpdateBasedOnTemplateWriteRequest;
  }
>(
  "config/modifyConfigByAcceptingTemplateChanges",
  async (
    request: {
      configId: number;
      configUpdateRequest: ConfigUpdateBasedOnTemplateWriteRequest;
    },
    { rejectWithValue }
  ) => {
    try {
      return await updateConfigAcceptingTemplateChangesAsync(
        request.configId,
        request.configUpdateRequest
      );
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);
